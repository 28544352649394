<template>
  <Avatar :image="imageSrc" :label="label" :style="style" shape="circle" />
</template>

<script lang="ts">
import Avatar from "primevue/avatar";
import { computed, PropType } from "vue";
import { imageURLFromUUID } from "@/functions/imageURLFromUUID";

export default {
  name: "AvatarImage",
  components: {
    Avatar,
  },
  props: {
    pictureId: {
      type: String,
      default: "",
    },
    avatarSize: {
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const backgroundColors = [
      "#F44336",
      "#FF4081",
      "#9C27B0",
      "#673AB7",
      "#3F51B5",
      "#2196F3",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#CDDC39",
      "#FFC107",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#9E9E9E",
      "#607D8B",
    ];

    function randomBackgroundColor(seed: number) {
      return backgroundColors[seed % backgroundColors.length];
    }

    function getInitials(username: string) {
      const parts = username.split(/[ -]/);
      let initials = "";
      for (let i = 0; i < parts.length; i += 1) {
        initials += parts[i].charAt(0);
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, "");
      }
      initials = initials.substr(0, 3).toUpperCase();
      return initials;
    }

    const imageSrc = computed(() => {
      if (props.pictureId) {
        return imageURLFromUUID(props.pictureId);
      }
      return undefined;
    });

    const style = computed(() => {
      let styleValue: Record<string, string> = {
        "background-color": randomBackgroundColor(props.name.length),
        color: "white",
      };
      if (props.avatarSize != null) {
        styleValue = {
          ...styleValue,
          height: `${props.avatarSize}px`,
          width: `${props.avatarSize}px`,
        };
      }
      return styleValue;
    });

    const label = computed(() => {
      return props.pictureId ? undefined : getInitials(props.name);
    });

    return {
      label,
      imageSrc,
      style,
    };
  },
};
</script>

<style lang="scss">
.vue-avatar--wrapper {
  background-size: cover !important;
  background-position: 50% 50% !important;
}
</style>

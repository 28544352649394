<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import EventActionRelationFilter from "../../filterImplementations/EventActionRelationFilter";
import FilterBadge from "./FilterBadge.vue";

export default defineComponent({
  name: "EventActionRelationFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<EventActionRelationFilter>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const text = computed(() => {
      return props.filter.filters
        .map((filter) =>
          t(`components.event-action.filter.ui.badges.EventActionRelationFilterBadge.${filter}`),
        )
        .join(", ");
    });
    return {
      text,
    };
  },
});
</script>

<style lang="scss" scoped></style>

{
  "dynamicColor": "Dynamisch",
  "dynamicColorTitle": "Kein Wert",
  "dynamicColorTooltip": "Auswahl ob die Säule im Hintergrund eine statische Farbe bekommt oder dynamich eingefärbt wird in Abhängigkeit von der Säule im Vordergrund.",
  "emptyColorTitle": "Kein Wert",
  "higherColorTitle": "Höher",
  "lowerColorTitle": "Niedriger",
  "noBackgoundSelected": "Keine Datenreihe ausgewählt.",
  "staticColorTitle": "Farbe",
  "staticColorTitleTooltip": "Hier kann die statische Farbe ausgewählt werden oder bei dynamischen Farben wird hier die Farbe ausgewählt, die bei fehlenden Werten angezeigt werden soll.",
  "tolerance": "Toleranz"
}

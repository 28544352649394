{
  "createFlag": "Flag anlegen",
  "createNewFlag": "Neue Flag anlegen",
  "deleteBoard": "keinem Board | {boards} Board | {boards} Boards",
  "deleteBoardTemplate": "keiner Board-Vorlage |{templates} Board-Vorlage | {templates} Board-Vorlagen",
  "deleteFlag": "Flag löschen",
  "deleteIssues": "keinem Ereignis | {issues} Ereignis | {issues} Ereignisse",
  "deleteMsg": "Diese Flag wird bei ",
  "deleteMsgEnd": " verwendet.",
  "deleteTile": "keiner Kachel | {tiles} Kachel | {tiles} Kacheln",
  "header": "Flags",
  "newTitle": "Neue Flag",
  "notEditable": "Es können nur Benutzerflags bearbeitet werden",
  "submit": "Wollen Sie diese Flag wirklich löschen?",
  "updateTitle": "Flag ändern",
  "usage": "Die Flag wird verwendet bei"
}

import { computed } from "vue";
import injectAsserted from "@/functions/assert/injectAsserted";
import { EventsInjectionKey } from "@/views/event-action/EventActionBoardInjectionKeys";
import { EventFilterInjectionKey, ActionFilterInjectionKey, Filter } from "../FilterTypes";
import useFilteredEvents from "../useFilteredEvents";

export default function refilterEventsWithout(filterCallback: (filter: Filter) => boolean) {
  const eventFilterList = injectAsserted(EventFilterInjectionKey);
  const actionFilterList = injectAsserted(ActionFilterInjectionKey);
  const newEventFilterList = computed(() => {
    return {
      eventFilters: eventFilterList.value.eventFilters.filter((filter) => {
        return filterCallback(filter);
      }),
      updatedAt: eventFilterList.value.updatedAt,
    };
  });

  const newActionFilterList = computed(() => {
    return {
      actionFilters: actionFilterList.value.actionFilters.filter((filter) => {
        return filterCallback(filter);
      }),
      updatedAt: actionFilterList.value.updatedAt,
    };
  });

  const allEvents = injectAsserted(EventsInjectionKey);
  return useFilteredEvents(allEvents, newEventFilterList, newActionFilterList);
}

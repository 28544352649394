{
  "activeBoards": "Freigeben für einzelne Boards",
  "activeBoardsTooltip": "Hier können Boards freigeben werden ohne den ganzen Standort freizugeben.",
  "activeSites": "Freigeben für alle Boards der folgenden Standorte",
  "customAttr": {
    "text": "Text"
  },
  "deadline": {
    "future": "Zukunft in Tagen",
    "present": "Aktuelles Datum"
  },
  "noCustomAttributes": "Keine zusätzlichen Attribute angehangen.",
  "required": "Erforderlich",
  "responsible": {
    "STATIC": "Statisch",
    "custom_user": "Benutzerdefiniert",
    "dynamic": "Dynamisch",
    "eventOwner": "Ereignisersteller",
    "self": "Angemeldeter Benutzer"
  },
  "warningUnusable": "Diese vordefinierte Aktion wird auf keinem Board angezeigt!"
}

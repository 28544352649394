{
  "countPSC": "Formular | Formulare",
  "createdAt": "Erstellt am",
  "createdBy": "Erstellt bei",
  "dateFormat": "DD.MM.YYYY",
  "hashTags": "Schlagwörter",
  "id": "Id",
  "problem": "Problem",
  "problemSolving": "Problemlösung",
  "pscKnowledgeDB": "Wissensdatenbank",
  "pscOverView": "Problemlösung",
  "site": "Standort",
  "teamLeader": "Teamleiter",
  "type": "Typ"
}

import { EventListEventFieldsFragment, SQDCBoardIssueConnectionTypes } from "@/graphql";
import { EventFilter } from "../FilterTypes";
import BoardOriginFilterUI from "../ui/BoardOriginFilterUI.vue";
import BoardOriginFilterBadge from "../ui/badges/BoardOriginFilterBadge.vue";
import { getBoardWithConnection, isNotAuditResult } from "../../helper/EventBoardConnectionHelper";

export default class BoardOriginFilter implements EventFilter {
  constructor(
    public readonly boardOrigin: SQDCBoardIssueConnectionTypes,
    public readonly isNegated?: boolean,
  ) {}

  static readonly UI = BoardOriginFilterUI;

  static readonly Badge = BoardOriginFilterBadge;

  isEventActive(event: EventListEventFieldsFragment, boardId?: number) {
    if (boardId == null) {
      return true;
    }
    const isActive = (() => {
      if (isNotAuditResult(event)) {
        const board = event.boards?.[0];
        if (board != null) {
          const boardConnection = getBoardWithConnection(event, boardId)?.boardConnection;
          return this.boardOrigin === boardConnection?.status;
        }
      }
      if (this.boardOrigin === SQDCBoardIssueConnectionTypes.created) {
        // If the event is an audit result and we filter for original events we can assume that all event that are not audit results should be displayed
        return true;
      }
      return false;
    })();
    return this.isNegated ? !isActive : isActive;
  }
}

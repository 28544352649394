{
  "futureDay": "Tag liegt in der Zukunft oder die Soll-Zeit liegt bei null und es wurde nicht gestempelt.",
  "holiday": "Feiertag",
  "legend": "Legende",
  "negativeDay": "Tageszeitkonto ist negativ",
  "other": "Andere Fehltage",
  "positiveDay": "Tageszeitkonto ist null oder positiv",
  "sick": "Krankheitstag",
  "timeAccount": "Zeitkonto",
  "vacation": "Urlaubstag",
  "vacationPending": "Nicht genehmigt"
}

<template>
  <div class="w-100" style="display: inline-block">
    <div class="p-inputgroup">
      <span v-if="showCalendarIcon" class="p-inputgroup-addon">
        <slot name="prepend">
          <i class="fas" :class="calendarIcon" />
        </slot>
      </span>
      <DatePickerNew
        :modelValue="formattedValue"
        :timezone="timezone"
        :locale="locale"
        :format="formatter"
        :text-input-options="textInputOptions"
        six-weeks
        week-numbers="iso"
        :auto-apply="autoApply"
        hide-input-icon
        auto-position
        :input-class-name="`p-inputtext p-component w-100 CustomDatePickerClass ${
          state === true ? 'p-valid' : state == null ? '' : 'p-invalid'
        }`"
        :teleport="true"
        :clearable="clearable"
        v-bind="pickerMode"
        text-input
        :highlight-week-days="[0, 6]"
        :disabled="disabled"
        :disabled-dates="dateDisabledFn"
        :keep-action-row="true"
        @update:model-value="handleUpdate"
      >
        <template #clear-icon="{ clear }">
          <i class="fas fa-times mr-3" @click.stop="clear"></i>
        </template>
        <template #action-row="actions">
          <div class="p-buttonset w-100">
            <PrimeVueButton
              class="p-button-text w-50"
              @click.stop="resetDateToToday(actions.closePicker)"
            >
              <div class="text-center">
                {{ $t("components.bcalendar.today") }}
              </div>
            </PrimeVueButton>
            <PrimeVueButton class="w-50" @click="actions.selectDate">
              <i class="fas fa-save mr-1"></i>
              {{ $t("global.save") }}</PrimeVueButton
            >
          </div>
        </template>
      </DatePickerNew>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * For more information visit https://vue3datepicker.com/
 */
import { computed, inject, PropType } from "vue";
import DatePickerNew from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";
import { useI18n } from "vue-i18n";
import PrimeVueButton from "primevue/button";
import { locale } from "@/i18n";
import { DatePickerType } from "./DatePickerComponentHelper";
import { TimeZoneInjectionKey } from "@/functions/timezoneUtil";

const props = defineProps({
  modelValue: {
    type: [String, Date, Array] as PropType<
      (Date | string | null) | [Date | string | null, Date | string | null]
    >,
    default: null,
  },
  type: {
    type: String as () => DatePickerType,
    default: DatePickerType.MINUTE,
  },
  /**
   * @deprecated please use the TimeZoneInjectionKey unless you know what you are doing!
   */
  tz: {
    type: String,
    default: undefined,
  },
  showCalendarIcon: {
    type: Boolean,
    default: true,
  },
  range: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  state: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  dateDisabledFn: {
    type: Function as PropType<(date: Date) => boolean>,
    default: undefined,
  },
});

const emit = defineEmits<{
  (event: "update:modelValue", value: any): any;
}>();

const { t } = useI18n();
const timezone = computed(() => props.tz ?? inject(TimeZoneInjectionKey)?.value);

const pickerMode = computed(() => {
  const isRange = props.range === true || Array.isArray(props.modelValue);

  let flow: undefined | string[] = ["calendar", "time"];

  if ([DatePickerType.MONTH, DatePickerType.YEAR].includes(props.type)) {
    flow = undefined;
  }
  if (props.type === DatePickerType.DAY) {
    flow = ["calendar"];
  }
  return {
    /**
     * if range is supplied or requested
     */
    range: isRange,
    "multi-calendars": isRange,

    flow,
    "week-picker": props.type === DatePickerType.WEEK,
    "year-picker": props.type === DatePickerType.YEAR,
    "month-picker": props.type === DatePickerType.MONTH,
    "enable-time-picker": [
      DatePickerType.HOUR,
      DatePickerType.MINUTE,
      DatePickerType.SECOND,
    ].includes(props.type),
  };
});

const calendarIcon = computed(() => {
  switch (props.type) {
    case DatePickerType.DAY:
      return "fa-calendar-day";
    case DatePickerType.WEEK:
      return "fa-calendar-week";
    default:
      return "fa-calendar";
  }
});

const formatString = computed(() => {
  switch (props.type) {
    case DatePickerType.SECOND:
      return "datetimeLong";
    case DatePickerType.MINUTE:
      return "datetime";
    case DatePickerType.HOUR:
      return "datetimeWithOutMinute";
    case DatePickerType.DAY:
      return "date";
    case DatePickerType.MONTH:
      return "yearWithMonth2Digits";
    case DatePickerType.YEAR:
      return "years";
    case DatePickerType.WEEK:
      return "week";
    default:
      return "datetimeLong";
  }
});
function formatter(date: string | (Date | Date[])) {
  if (date == null) {
    return "";
  }
  if (props.type === DatePickerType.WEEK) {
    /**
     * Week is a tuple, if not parsed by us with the start and end of the selected week
     * We are only interested in the start
     */
    let week: Date;
    if (Array.isArray(date)) {
      [week] = date;
    } else {
      week = date as Date;
    }
    return DateTime.fromJSDate(week).toFormat(t(`components.shared.DatePickerComponent.week`));
  }

  if (props.type === DatePickerType.YEAR) {
    return DateTime.fromJSDate(date as Date)
      .startOf("year")
      .toFormat(t(`components.shared.DatePickerComponent.years`).toString());
  }
  /**
   * If it's a range, we return it like this: <date> - <date>
   */
  if (Array.isArray(date)) {
    return `${DateTime.fromJSDate(date[0]).toFormat(
      t(`components.shared.DatePickerComponent.${formatString.value}`).toString(),
    )} - ${DateTime.fromJSDate(date[1]).toFormat(
      t(`components.shared.DatePickerComponent.${formatString.value}`).toString(),
    )}`;
  }
  return DateTime.fromJSDate(date as Date).toFormat(
    t(`components.shared.DatePickerComponent.${formatString.value}`).toString(),
  );
}

const textInputOptions = computed(() => {
  return {
    format: (dateRaw: string) => {
      const date = dateRaw.trim();
      if (props.type === DatePickerType.WEEK) {
        const stringParts = date.split(" ");
        const week = Number(stringParts[stringParts.length - 2].trim());
        let year: number | string = stringParts[stringParts.length - 1].trim();
        year = Number(year.substring(1, year.length));
        if (Number.isNaN(week) || Number.isNaN(year)) {
          return null;
        }
        return DateTime.now()
          .setZone(timezone.value)
          .set({
            weekYear: year,
            weekNumber: week,
          })
          .startOf("week")
          .toJSDate();
      }
      return DateTime.fromFormat(
        date,
        t(`components.shared.DatePickerComponent.${formatString.value}`),
        {
          zone: timezone.value,
        },
      ).toJSDate();
    },
  };
});

function formatOutputToModelValueOutput(date: string | number | Date | null, returnType: any) {
  if (date instanceof Date) {
    if (returnType === "date") {
      return date;
    }
    if (returnType === "string") {
      return date.toISOString();
    }
  }
  if (typeof date === "number" || date instanceof Number) {
    if (returnType === "date") {
      return new Date(date);
    }
    if (returnType === "string") {
      return new Date(date).toISOString();
    }
  }
  if (typeof date === "string" || date instanceof String) {
    if (returnType === "date") {
      return new Date(date);
    }
    if (returnType === "string") {
      return new Date(date).toISOString();
    }
  }
  return date;
}

/**
 * This is {any} because the DatePicker does'nt like null or null[]. But it handles it like undefined.
 * No issues there except for typescript
 */
const formattedValue = computed<any>(() => {
  if (props.modelValue == null) {
    return undefined;
  }
  if (Array.isArray(props.modelValue) || props.range === true) {
    return props.modelValue ?? [];
  }
  if (!Array.isArray(props.modelValue) && props.type === DatePickerType.WEEK) {
    const modelValueDate = DateTime.fromJSDate(
      formatOutputToModelValueOutput(props.modelValue, "date") as Date,
    )
      .setZone(timezone.value)
      .startOf("week");
    return [modelValueDate.toJSDate(), modelValueDate.endOf("week").toJSDate()];
  }
  if (props.type === DatePickerType.MONTH) {
    const modelValueDate = DateTime.fromJSDate(
      formatOutputToModelValueOutput(props.modelValue, "date") as Date,
    ).setZone(timezone.value);
    return {
      /**
       * months in this picker start at 0
       * months in luxon start at 1
       */
      month: modelValueDate.month - 1,
      year: modelValueDate.year,
    };
  }
  if (props.type === DatePickerType.YEAR) {
    const modelValueDate = DateTime.fromJSDate(
      formatOutputToModelValueOutput(props.modelValue, "date") as Date,
    ).setZone(timezone.value);
    return modelValueDate.year;
  }
  if (props.type === DatePickerType.DAY) {
    const modelValueDate = DateTime.fromJSDate(
      formatOutputToModelValueOutput(props.modelValue, "date") as Date,
    ).setZone(timezone.value);
    return modelValueDate
      .set({
        second: 0,
        hour: 0,
        minute: 0,
      })
      .toJSDate();
  }

  if (props.type === DatePickerType.HOUR) {
    const modelValueDate = DateTime.fromJSDate(
      formatOutputToModelValueOutput(props.modelValue, "date") as Date,
    ).setZone(timezone.value);
    return modelValueDate
      .set({
        minute: 0,
        second: 0,
      })
      .toJSDate();
  }

  if (props.type === DatePickerType.MINUTE) {
    const modelValueDate = DateTime.fromJSDate(
      formatOutputToModelValueOutput(props.modelValue, "date") as Date,
    ).setZone(timezone.value);
    return modelValueDate
      .set({
        second: 0,
      })
      .toJSDate();
  }
  return props.modelValue;
});

function handleUpdate(
  date:
    | typeof props.modelValue
    | { month?: number; year?: number; hours?: number; minutes?: number },
) {
  if (Array.isArray(date) && props.type !== DatePickerType.WEEK) {
    /**
     * Range stuff
     */
    emit("update:modelValue", [
      formatOutputToModelValueOutput(date[0], typeof date[0]),
      formatOutputToModelValueOutput(date[1], typeof date[0]),
    ]);
  } else if (Array.isArray(date) && props.type === DatePickerType.WEEK) {
    /**
     * Week stuff
     */
    emit("update:modelValue", formatOutputToModelValueOutput(date[0], typeof date));
  } else if (props.type === DatePickerType.MONTH) {
    /**
     * Month Stuff
     */
    const monthDate = date as { month?: number; year?: number; hours?: number; minutes?: number };
    const parsedDate = DateTime.now()
      .setZone(timezone.value)
      .set({
        /**
         * months in this picker start at 0
         * months in luxon start at 1
         */
        month: monthDate.month! + 1,
        year: monthDate.year,
      })
      .startOf("month")
      .toJSDate();
    emit("update:modelValue", formatOutputToModelValueOutput(parsedDate, typeof date));
  } else if (props.type === DatePickerType.YEAR) {
    const monthDate = date as number;
    const parsedDate = DateTime.now()
      .setZone(timezone.value)
      .set({
        year: monthDate,
      })
      .startOf("year")
      .toJSDate();
    emit("update:modelValue", formatOutputToModelValueOutput(parsedDate, typeof date));
  } else if (props.type === DatePickerType.DAY) {
    /**
     * Reset the time to start of day
     */
    const dateForDay = date as Date;
    emit(
      "update:modelValue",
      formatOutputToModelValueOutput(
        DateTime.fromJSDate(dateForDay).setZone(timezone.value).startOf("day").toJSDate(),
        typeof date,
      ),
    );
  } else {
    /**
     * Non range stuff
     */
    emit("update:modelValue", formatOutputToModelValueOutput(date as any, typeof date));
  }
}

function resetDateToToday(callback?: () => void) {
  /**
   * should map the the current granularity
   */
  if (Array.isArray(props.modelValue)) {
    emit("update:modelValue", [
      formatOutputToModelValueOutput(new Date(), typeof props.modelValue[0]),
      formatOutputToModelValueOutput(new Date(), typeof props.modelValue[0]),
    ]);
  } else {
    /**
     * Non range stuff
     */
    emit("update:modelValue", formatOutputToModelValueOutput(new Date(), typeof props.modelValue));
  }
  callback?.();
}

const autoApply = computed(() =>
  [DatePickerType.DAY, DatePickerType.MONTH, DatePickerType.YEAR, DatePickerType.WEEK].includes(
    props.type,
  ),
);
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style>
.CustomDatePickerClass {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>

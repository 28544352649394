{
  "doYouWantToStampIn": "Sie waren zuletzt ausgestempelt, möchten Sie sich einstempeln?",
  "doYouWantToStampOut": "Sie waren zuletzt eingestempelt, möchten Sie sich ausstempeln?",
  "fields": {
    "breakTime": "Pause",
    "duration": "Dauer",
    "stampIn": "Einstempeln",
    "stampOut": "Ausstempeln",
    "stampedIn": "Eingestempelt",
    "stampedOut": "Ausgestempelt",
    "timeShould": "Soll",
    "total": "Total"
  },
  "header": {
    "breakTimeLabel": "Pausezeit:",
    "greeting": "Hallo",
    "lastStamps": "Vergangene Stempel"
  }
}

{
  "buttons": {
    "createNew": "Material avisieren",
    "rejectAdvise": "Buchung abbrechen",
    "resolveAdvise": "Wareneingang prüfen"
  },
  "tableFields": {
    "advised": "Avisiert",
    "date": "Datum",
    "index": "Nr.",
    "material": "Material",
    "quantity": "Bestand",
    "storageLocation": "Lagerplatz"
  },
  "title": "Wareneingang"
}

import { FilterEntity } from "../FilterTypes";
import { ActionType, EventType } from "@/graphql";
import { actionIdPrefix, eventIdPrefix } from "../../event/EventActionIdPrefix";

export function getEventTypeFromInput(mode: FilterEntity, prefix: string) {
  if (mode === FilterEntity.ACTION) {
    switch (prefix.toUpperCase()) {
      case actionIdPrefix[ActionType.Task]:
        return "Task";
      case actionIdPrefix[ActionType.PSC]:
        return "PSC";
      case actionIdPrefix[ActionType.IHOrder]:
        return "IHOrder";
      default:
    }
  } else if (mode === FilterEntity.EVENT) {
    switch (prefix.toUpperCase()) {
      case eventIdPrefix[EventType.AuditResult]:
        return "AuditResult";
      case eventIdPrefix[EventType.IHFailureNotice]:
        return "IHFailureNotice";
      case eventIdPrefix[EventType.IHMaintenance]:
        return "IHMaintenance";
      case eventIdPrefix[EventType.Issue]:
        return "Issue";
      case eventIdPrefix[EventType.HLPIdea]:
        return "HLPIdea";
      case eventIdPrefix[EventType.ChangeRequest]:
        return "ChangeRequest";
      default:
    }
  }
  return null;
}

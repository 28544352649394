export default {
  endpoints: {
    // Templates
    getTemplates: {
      route: "template",
      method: "GET",
    },
    getTemplate: {
      route: "template/:id",
      method: "GET",
    },
    getDefaultTemplate: {
      route: "template/default",
      method: "GET",
    },
    createTemplate: {
      route: "template",
      method: "POST",
    },
    updateTemplate: {
      route: "template/:id",
      method: "PUT",
    },
    deleteTemplate: {
      route: "template/:id",
      method: "DELETE",
    },

    // Boards
    getBoards: {
      route: "boards/:id",
      method: "GET",
    },
    getBoardBySite: {
      route: "boardBySite/:id",
      method: "GET",
    },
    boardByTileId: {
      route: "boardByTileId/:id",
      method: "get",
    },
    getBoardHierarchy: {
      route: "boardHierarchy",
      method: "GET",
    },
    getBoard: {
      route: "getBoard/:id",
      method: "POST",
    },
    getBoardForSettings: {
      route: "boardForSettings/:id",
      method: "GET",
    },
    updateBoard: {
      route: "board/:id",
      method: "PUT",
    },
    createBoard: {
      route: "board",
      method: "POST",
    },
    deleteBoard: {
      route: "board/:id",
      method: "DELETE",
    },

    // Tile
    updateTile: {
      route: "tile/:id",
      method: "PUT",
    },
    createTile: {
      route: "tile",
      method: "POST",
    },
    deleteTile: {
      route: "tile/:id",
      method: "DELETE",
    },
    getTile: {
      route: "tile/:id",
      method: "GET",
    },
    searchTileByName: {
      route: "tile/searchByName",
      method: "POST",
    },
    searchFreeSpaceInColumn: {
      route: "tile/searchFreeSpaceInColumn",
      method: "POST",
    },
    loadAggregation: {
      route: "tile/searchForAggregationCategories",
      method: "POST",
    },
    // Layout
    updateLayout: {
      route: "layout",
      method: "PUT",
    },
    searchTemplateByName: {
      route: "template/searchByName",
      method: "POST",
    },
    searchBoardByName: {
      route: "board/searchByName",
      method: "POST",
    },
  },
};

<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ meta, valid, dirty: dirtyPrivate, touched: touchedPrivate }"
    :noPropagation="noPropagation"
  >
    <LModalObserverInjector
      :dirty="dirtyPrivate"
      :touched="touchedPrivate"
      @update:dirty="dirty = $event"
      @update:touched="touched = $event"
    />
    <DialogPrime
      ref="dialog"
      v-model:visible="visibleProp"
      :header="title"
      maximizable
      closeOnEscape
      modal
      :breakpoints="breakpoints"
      v-bind="$attrs"
      :style="modalWith"
    >
      <template v-if="showFooter" #footer>
        <slot
          name="footer"
          :valid="valid"
          :disabled="loading || disabled"
          :okDisabled="okDisabled"
          :close="close"
          :hide="close"
          :ok="submitForm"
        >
          <ButtonPrime
            v-if="!okOnly"
            autofocus
            :disabled="disabled"
            class="p-button-secondary"
            @click="close"
          >
            <slot name="modal-cancel" :disabled="disabled">
              <i class="fas fa-times mr-1" />{{ $t("global.cancel") }}
            </slot>
          </ButtonPrime>
          <ButtonPrime
            :class="`p-button-${okVariant}`"
            :disabled="loading || disabled || okDisabled || !valid"
            @click="submitForm"
          >
            <slot name="modal-ok" :disabled="loading || disabled || okDisabled || !valid">
              <i class="fas fa-save mr-1" />{{ $t("global.save") }}
            </slot>
          </ButtonPrime>
        </slot>
      </template>
      <template #header>
        <slot name="header" :valid="valid" />
      </template>

      <template #default="args">
        <form ref="submitElement" @submit.prevent="submitForm">
          <slot name="default" v-bind="args" :valid="valid" :meta="meta" :submit="submitForm">
            <div v-if="loading" class="flex justify-content-center">
              <ProgressSpinner />
            </div>
          </slot>
          <ButtonPrime type="submit" style="display: none" />
        </form>
      </template>
    </DialogPrime>
  </ValidationObserver>
</template>

<script lang="ts">
import ProgressSpinner from "primevue/progressspinner";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import Button from "primevue/button";
import { useI18n } from "vue-i18n";
import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useconfirm";
import ValidationObserver from "@/modules/veevalidate/components/ValidationObserver.vue";
import LModalObserverInjector from "./shared/utility/LModalObserverInjector.vue";

export default defineComponent({
  name: "LModal",
  components: {
    ProgressSpinner,
    ButtonPrime: Button,
    DialogPrime: Dialog,
    ValidationObserver,
    LModalObserverInjector,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    okDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    okVariant: {
      type: String,
      required: false,
      default: "primary",
    },
    size: {
      type: String as PropType<"sm" | "md" | "lg" | "xl" | "xxl">,
      required: false,
      default: "md",
    },
    minHeight: {
      type: String,
      default: "unset",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    okOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPropagation: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ["discardChanges", "update:visible", "ok"],
  setup(props, { emit }) {
    const dirty = ref(false);
    const touched = ref(false);
    const observer = ref<InstanceType<typeof ValidationObserver>>();
    const { t } = useI18n();
    const confirm = useConfirm();

    const visibleProp = computed({
      get() {
        return props.visible;
      },
      set(value: boolean) {
        emit("update:visible", value);
      },
    });

    const dialog = ref<
      | (InstanceType<typeof Dialog> & {
          close: () => void;
          onMaskClick: (e: PointerEvent) => void;
        })
      | null
    >(null);
    onMounted(() => {
      if (dialog.value) {
        dialog.value.onMaskClick = (e: PointerEvent) => {
          const el = document.activeElement;
          /**
           * Due to the portal-function from primevue the active Element, while a "portaled" element is open, is the body.
           * This means we can determine if the user is inside a input field or not and therefor close the modal and vice versa.
           */
          if (
            el?.tagName === "BODY" &&
            (e.target as HTMLDivElement)?.classList?.contains("p-dialog-mask") === true
          ) {
            dialog.value?.close();
          }
        };
        const originalCloseMethod = dialog.value.close;
        dialog.value.close = async function closeFunc() {
          if (dirty.value === true && touched.value === true) {
            confirm.require({
              message: t("components.event-action.TaskModal.confirmText"),
              header: t("global.confirm"),
              acceptLabel: t("global.yes"),
              rejectLabel: t("global.cancel"),
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                emit("discardChanges");
                originalCloseMethod();
              },
            });
          } else {
            originalCloseMethod();
          }
        };
      } else {
        // eslint-disable-next-line no-console
        console.warn("Dialog close function could not be replaced");
      }
    });

    async function submitForm(...args: any) {
      if ((await observer.value?.validate()) === true) {
        emit("update:visible", false);
        emit("ok", ...args);
      }
    }

    function close() {
      dialog.value?.close();
    }

    const breakpoints = computed(() => {
      switch (props.size) {
        case "lg":
          return { "991px": "500px" } as Record<string, string>;
        case "xl":
          return { "1199px": "800px", "991px": "500px" } as Record<string, string>;
        case "xxl":
          return { "991px": "500px", "1199px": "800px" } as Record<string, string>;
        default:
          return undefined;
      }
    });

    const modalWith = computed(() => {
      switch (props.size) {
        case "sm":
          return `width: 300px; min-height: ${props.minHeight};`;
        case "lg":
          return `width: 800px; min-height: ${props.minHeight};`;
        case "xl":
          return `width: 1140px; min-height: ${props.minHeight};`;
        case "xxl":
          return `width: 90vw; min-height: ${props.minHeight};`;
        default:
          return `width: 500px; min-height: ${props.minHeight};`;
      }
    });

    function show() {
      visibleProp.value = true;
    }
    return {
      dirty,
      touched,
      show,
      visibleProp,
      breakpoints,
      modalWith,
      dialog,
      submitForm,
      close,
      observer,
    };
  },
});
</script>

<style lang="scss" scoped></style>

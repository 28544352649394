{
  "UserUpdate": "Benutzer ändern",
  "anonym": "Anonym",
  "deleteUser": "Benutzer löschen",
  "msgBox": {
    "delete": {
      "text": "Soll der Benutzer {name} unwiderruflich gelöscht werden?",
      "title": "Bitte bestätigen"
    },
    "reset": {
      "text": "Soll {name} ein Passwortrücksetzlink zugesendet werden? Der Link wird an folgende E-Mail-Adresse verschickt: {email}. Der Benutzer muss entweder über den Link in der zugesendeten Mail oder über das einloggen mit dem alten Passwort ein neues Passwort setzten.",
      "title": "Bitte bestätigen"
    }
  },
  "newUser": "Neuen Benutzer anlegen",
  "resetPassword": "Passwort zurücksetzen",
  "you": "Das sind Sie"
}

{
  "list": {
    "name": "Audit-Vorlagen",
    "options": {
      "board": "Board",
      "createdAt": "Erstellt am",
      "result": "Ergebnis"
    }
  },
  "removeAudit": "Wollen sie das Audit wirklich verwerfen?"
}

{
  "changeDatapointInfo": "Es liegen bereits Datenpunkte im angegebenen Zeitraum. Welchen möchten Sie ändern?",
  "changeDatapointInfoOne": "Es gibt bereits einen anderen Datenpunkt im angegebenen Zeitfenster möchten Sie diesen ändern?",
  "createWithIssue": "Hinzufügen und Ereignis anlegen",
  "datapoint": "Datenpunkt",
  "date": "Datum",
  "title": "Datenpunkt hinzufügen",
  "titleEdit": "Datenpunkt ändern",
  "virtualDatapointError": "Es gibt vituelle Datenpunkte in diesem Zeitfenstern die nicht geändert werden können."
}

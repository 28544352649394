{
  "issue": "Problem",
  "issuePlaceholder": "Beschreibung (Notwendig wenn Zielwert nicht erreicht wurde)",
  "noShift": "Kein Schichtbezug",
  "quantity": "Anzahl",
  "shift": "Schicht",
  "shiftInput": "Schicht:",
  "targetValue": "Zielwert",
  "title": "Feedback vom",
  "value": "Wert",
  "weekday": "Wochentag"
}

{
  "auto": "Automatisch",
  "break": "Pause",
  "copy": "Kopieren",
  "copyDisable": "Es können nur terminierte Teampläne kopiert werden",
  "custom": "Benutzerdefiniert",
  "customTitle": "Automatische Schichtzuweisung",
  "day": "Tag",
  "early": "Früh",
  "editShiftTitle": "Schicht",
  "end": "Ende",
  "free": "Frei",
  "hardCopy": "Kopie",
  "header": "Benutzerschichtplan",
  "late": "Spät",
  "mode": "Automatische SOLL-Zeit",
  "name": "Name",
  "new": "Neue Schicht hinzufügen",
  "night": "Nacht",
  "noBreak": "Manuelle Pauseneingabe",
  "overview": "Übersicht",
  "position": "Pos.",
  "roll": "Rollierend (Wochen)",
  "rollDirection": "Vorwärts rollierend",
  "shift": "Schichten",
  "shiftPlan": "Schichtplan",
  "shiftplan": "Name",
  "start": "Start",
  "target": "SOLL",
  "team": "Team",
  "teams": "Schichtpläne / Teams",
  "template": "Vorlage",
  "timezone": "Zeitzone",
  "weeks": "Wochen"
}

<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import StatusFilter from "../../filterImplementations/StatusFilter";
import FilterBadge from "./FilterBadge.vue";

export default defineComponent({
  name: "StatusFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<StatusFilter>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const text = computed(() =>
      props.filter.status
        .map((status) => {
          return t(`components.TaskModal.columns.status${status}`);
        })
        .join(", "),
    );
    return { text };
  },
});
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <div class="field" :class="{ 'mb-0': small }">
      <label v-if="displayName != null" for="name" :class="labelClass">
        {{ displayName }}
        <InfoTooltipVue v-if="tooltipOptions" class="ml-1" v-bind="tooltipOptions" />
      </label>
      <div
        :class="{
          'p-inputgroup': $slots['prepend'] != null || $slots['append'] != null,
          'mb-0': small,
        }"
      >
        <span v-if="$slots['prepend'] != null" class="p-inputgroup-addon">
          <slot name="prepend" />
        </span>

        <slot
          :modelValue="customModel"
          :required="isRequired"
          :disabled="formDisabled || disabled"
          :class="{ 'p-valid': state === true, 'p-invalid': state === false }"
          :state="state"
          :valid="state !== false"
        >
          <component
            :is="inputTypeComputed"
            :id="name"
            v-model="customModel"
            :required="isRequired"
            :name="name"
            class="w-100"
            :class="{ 'p-valid': state === true, 'p-invalid': state === false }"
            :disabled="formDisabled || disabled"
            :placeholder="placeholder"
            :readonly="readonly"
            v-bind="$attrs"
            v-on="$attrs"
          />
        </slot>

        <span v-if="$slots['append'] != null" class="p-inputgroup-addon">
          <slot name="append" />
        </span>
      </div>
      <small v-if="invalidFeedback != null" class="text-red-500">{{ invalidFeedback }}</small>
      <small v-else-if="description">{{ description }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, computed, defineComponent, inject, PropType, watch } from "vue";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import { useField } from "vee-validate";
import InfoTooltipVue from "../InfoTooltip.vue";
import { InputFrameInjectionKey } from "./InputFrameMode";

export default defineComponent({
  name: "InputRow",
  components: {
    InputText,
    InputNumber,
    InfoTooltipVue,
  },
  props: {
    modelValue: {
      type: [Object, String, Number, Array, Date, Boolean],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    rules: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },
    description: {
      type: String,
      required: false,
      default: undefined,
    },
    inputType: {
      type: Object as PropType<Component>,
      required: false,
      default: undefined,
    },
    tooltipOptions: {
      type: Object as PropType<InstanceType<typeof InfoTooltipVue>["$props"]>,
      required: false,
      default: null,
    },
    labelClass: {
      type: [String, Object],
      required: false,
      default: "",
    },
    type: {
      type: String as PropType<"number" | "text">,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const inputFrameProps = inject(InputFrameInjectionKey, { disable: false });
    const formDisabled = computed(() => inputFrameProps.disable);

    const {
      errorMessage,
      value: customModel,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.modelValue,
      label: computed(() => `"${props.displayName}"`),
    });

    watch(customModel, (value) => {
      emit("update:modelValue", value);
    });

    const state = computed(() => {
      return Object.keys(props.rules).length > 0 ? meta.valid : null;
    });

    const invalidFeedback = computed(() => {
      if (state.value != null) {
        if (errorMessage.value != null) {
          return errorMessage.value;
        }
        return undefined;
      }
      return undefined;
    });

    const inputTypeComputed = computed(() => {
      if (props.inputType) {
        return props.inputType;
      }
      if (props.type === "number") {
        return InputNumber;
      }
      return InputText;
    });

    const inputClass = computed(() => {
      if (props.type === "number") {
        return "text-right";
      }
      return "";
    });

    const isRequired = computed(() => props.rules != null && "required" in props.rules);

    return {
      inputTypeComputed,
      customModel,
      formDisabled,
      inputClass,
      isRequired,
      state,
      invalidFeedback,
      meta,
    };
  },
});
</script>

<style lang="scss" scoped></style>

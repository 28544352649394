{
  "buttons": {
    "createOrder": "Bestellung avisieren"
  },
  "options": {
    "advised": "Avisiert",
    "available": "Verfügbar",
    "description": "Beschreibung",
    "materialNr": "Material Nr.",
    "quantity": "Bestand",
    "reportingStock": "Meldebestand",
    "reserved": "Reserviert",
    "safetyStock": "Sicherheitsbestand",
    "status": "Status"
  },
  "title": "Bestandsübersicht",
  "tooltip": {
    "option": {
      "date": "Datum",
      "quantity": "Menge",
      "target": "Ziel",
      "updater": "Ersteller"
    }
  }
}

{
  "costcenter": "Kostenstelle",
  "department": "Abteilung",
  "initialPages": {
    "dashboard": "Dashboard",
    "kpiboard": "KPI-Board",
    "taskboard": "Problemlösungsboard"
  },
  "name": "Name",
  "newAnonymUser": "Anonymen Benutzer anlegen",
  "newUser": "Anlegen",
  "options": {
    "anonym": "Anonym",
    "cost": "Kostenstelle",
    "department": "Abteilung",
    "id": "ID",
    "name": "Name",
    "site": "Standort",
    "username": "Benutzername"
  },
  "personalInformation": "Persönliche Informationen",
  "rfidErrorText": "Dieser RFID-Transponder wird bereits verwendet!",
  "rights": "Berechtigungen",
  "site": "Standort",
  "siteDepartment": "Standort & Abteilung",
  "tabAnonymUsers": "Anonyme Benutzer",
  "timestampclock": "Zeiterfassung",
  "user": "Benutzer",
  "userList": "Benutzerübersicht",
  "userName": "Benutzername",
  "userSpecificInformation": "Benutzerspezifische Informationen"
}

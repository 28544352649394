{
  "buttons": {
    "createTransportRequest": "Umwandeln",
    "rejectTransfer": "Transport abbrechen"
  },
  "info": {
    "storagehasNoMaterial": "Das angegebene Material befindet sich nicht in diesem Lagerplatz."
  },
  "title": "Transportanfragen",
  "toast": {
    "danger": {
      "errorText": "Die Anfrage wurde nicht abgebrochen"
    },
    "error": {
      "resolveText": "Es ist ein Fehler aufgetreten."
    },
    "success": {
      "resolveText": "Die Anfrage wurde als Transportauftrag angelegt.",
      "successText": "Die Anfrage wurde abgebrochen."
    },
    "warning": {
      "wsErrorText": "Es wurden neue Aufträge erstellt, diese konnten aber nicht abgerufen werden. Bitte diese Seite neu laden!"
    }
  }
}

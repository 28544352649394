{
  "additionalInfos": "Zusatzinformation",
  "costCenters": "Kostenstellen",
  "createNewCostCenter": "Kostenstelle anlegen",
  "description": "Beschreibung",
  "identifier": "Kostenstelle",
  "parent": "Elternknoten auswählen",
  "stCenters": "Kostenstellen",
  "updateCostCenter": "Kostenstelle ändern"
}

{
  "FilterLabel": "Filter für",
  "Main": {
    "loadingInitialize": "Wird geladen...",
    "loadingText": "Report wird erstellt...",
    "modals": {
      "locationAndBoardModal": {
        "asteriks": {
          "dlz": "* DLZ = gleitender Durchschnitt"
        },
        "boardsTitle": "Boards",
        "buttons": {
          "createReport": "Report anlegen",
          "saveSettings": "Standardeinstellungen speichern"
        },
        "dateTitle": "Zeitraum",
        "dlz": "Durchlaufzeit in Tagen (DLZ*)",
        "flags": "Flags",
        "selectSite": "Bitte Standort auswählen:",
        "sites": "Standorte",
        "title": "Bitte Standorte auswählen",
        "toast": {
          "message": "Die Einstellungen werden ab jetzt automatisch geladen beim betreten des Moduls.",
          "title": "Einstellungen wurden gespeichert"
        }
      }
    },
    "reportCreated": "Report wurde erstellt"
  },
  "NavBar": {
    "branding": "Reports",
    "buttonLabels": {
      "configError": "Konfigurationsfehler",
      "createReport": "Report anlegen"
    },
    "inputLabel": {
      "from": "Von",
      "hideSiteData": "Standortdaten ausblenden",
      "resetFilter": "Filter löschen",
      "showSiteData": "Standortdaten anzeigen",
      "to": "Bis"
    }
  },
  "chartSeries": {
    "analyticsSeriesTitle1": "Durchlaufzeit {siteName} ({value} Tage)",
    "closedIssues": "geschlossene Probleme",
    "createdIssues": "erstellte Probleme",
    "dlzInDays": "Durchlaufzeit [Tage]",
    "emptySite": "{siteName}",
    "flagClosed": "geschlossene Probleme ({name})",
    "flagNew": "neue Probleme ({name})",
    "flagdlz": "Durchlaufzeit {name} ({dlz} Tage)",
    "newIssues": "neue Probleme",
    "openIssues": "offene Probleme"
  },
  "chartSettings": {
    "additionalChart": {
      "title": "Probleme pro Board"
    },
    "analyticsHeader": "Auswertung für {siteName}",
    "buttons": {
      "resetZoom": "Reset Zoom"
    },
    "dlzHeader": "Auswertung Durchlaufzeit ({dlz} Tage)",
    "dlzInDays": "Durchlaufzeit [Tage]",
    "dlzWithDays": "Durchlaufzeit (gleitender Durchschnitt {dlz} Tage)",
    "issues": "Probleme",
    "rangeButtons": {
      "month": "Monat"
    }
  },
  "infoText": "Das Datum kann nur über die Exporteinstellungen gefiltert werden",
  "labels": {
    "createReport": "Export anlegen"
  },
  "menu": [
    null,
    "Problemübersicht",
    "Standortanalyse",
    "Durchlaufzeiten",
    "Export"
  ],
  "tableFields": {
    "boardId": "Board ID",
    "boardName": "Board",
    "boards": "Boards",
    "closedFlags": "geschlossene Probleme ({name})",
    "closedIssues": "geschlossene Probleme",
    "closedIssuesPerBoard": "geschlossene Probleme pro Board",
    "createdFlags": "neue Probleme ({name})",
    "createdIssues": "erstellte Probleme",
    "createdIssuesPerBoard": "erstellte Probleme pro Board",
    "date": "Datum",
    "dlz": "Durchlaufzeit [Tage]",
    "dlzInDays": "Durchlaufzeit [{dlz} Tage]",
    "dlzPerBoard": "Durchlaufzeit pro Board",
    "dlzPerIssue": "Durchlaufzeit in Tagen",
    "flagdlz": "Durchlaufzeit ({name}) [{dlz} Tage]",
    "issueDeadline": "Problemdatum",
    "issueDeletedAt": "Archiviert am",
    "issueDescription": "Problembeschreibung",
    "issueFlags": "Flags",
    "issueId": "Problem ID",
    "issueOwner": "Besitzer",
    "newIssues": "neue Probleme",
    "newIssuesPerBoard": "neue Probleme pro Board",
    "openIssues": "offene Probleme",
    "openIssuesAvg": "offene Probleme Ø pro Tag",
    "openIssuesPerBoard": "offene Probleme pro Board",
    "site": "Standort",
    "siteId": "Standort ID",
    "siteName": "Standort",
    "siteWithDLZ": "{name} [{dlz} Tage]",
    "taskDeadline": "Termin",
    "taskDescription": "Aufgabenbeschreibung",
    "taskId": "Aufgaben ID",
    "taskResponsible": "Verantwortlicher",
    "taskStatus": "Status"
  }
}

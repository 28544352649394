import { DateTime } from "luxon";
import { ActionListActionFieldsFragment, EventListEventFieldsFragment } from "@/graphql";
import { EventFilter, ActionFilter } from "../FilterTypes";
import RecentlyChangedFilterUI from "../ui/RecentlyChangedFilterUI.vue";
import RecentlyChangedFilterBadge from "../ui/badges/RecentlyChangedFilterBadge.vue";

export default class RecentlyChangedFilter implements EventFilter, ActionFilter {
  constructor(
    public readonly definitionOfRecentInHours: number,
    public readonly isNegated?: boolean,
  ) {}

  static readonly Badge = RecentlyChangedFilterBadge;

  static readonly UI = RecentlyChangedFilterUI;

  isEventActive(event: EventListEventFieldsFragment, boardId?: number) {
    const isActive = (() => {
      return this.isRecentlyChanged(DateTime.fromISO(event.updatedAt));
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isActionActive(action: ActionListActionFieldsFragment) {
    const isActive = (() => {
      const actionRecentlyChanged = this.isRecentlyChanged(DateTime.fromISO(action.updatedAt));
      if (actionRecentlyChanged) {
        return true;
      }
      if ("Revisions" in action) {
        return (
          action.Revisions?.some((rev) =>
            this.isRecentlyChanged(DateTime.fromISO(rev.updatedAt)),
          ) === true
        );
      }
      return false;
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isRecentlyChanged(time: DateTime) {
    return time.plus({ hours: this.definitionOfRecentInHours }) > DateTime.now();
  }
}

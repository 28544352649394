import { ActionListActionFieldsFragment } from "@/graphql";
import { ActionFilter } from "../FilterTypes";
import StatusFilterUI from "../ui/StatusFilterUI.vue";
import StatusFilterBadge from "../ui/badges/StatusFilterBadge.vue";

export default class StatusFilter implements ActionFilter {
  constructor(public readonly status: number[], public readonly isNegated?: boolean) {}

  static readonly Badge = StatusFilterBadge;

  static readonly UI = StatusFilterUI;

  isActionActive(action: ActionListActionFieldsFragment) {
    const isActive = (() => {
      return this.status.includes(action.status ?? -1);
    })();
    return this.isNegated ? !isActive : isActive;
  }
}

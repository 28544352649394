import store from "@/store";
import { Router } from "@/typeDefinitions/custom/Router";

const router: Router.LicensedRouterExportType = [
  {
    path: "/mqtt/deviceList",
    name: "MQTTDeviceList",
    component: () => import("./views/MQTTDeviceList.vue"),
    meta: {
      rights: ["mqtt.admin"],
    },
    props: (route) => {
      return {
        showDevice: true,
      };
    },
  },
  {
    path: "/mqtt/device/create",
    name: "MQTTCreateDevice",
    component: () => import("./views/MQTTDeviceCreate.vue"),
    meta: {
      rights: ["mqtt.admin"],
    },
    props: (route) => {
      return {
        siteId: Number(route.query.siteId ?? store.getters["login/preferredSite"]),
      };
    },
  },
  {
    path: "/mqtt/device/:deviceId",
    name: "MQTTDeviceUpdate",
    component: () => import("./views/MQTTDeviceUpdate.vue"),
    meta: {
      rights: ["mqtt.admin"],
    },
    props: (route) => {
      return {
        deviceId: route.params.deviceId,
      };
    },
  },
  {
    path: "/mqtt/ruleList",
    name: "MQTTRuleList",
    component: () => import("./views/MQTTDeviceList.vue"),
    meta: {
      rights: ["mqtt.admin"],
    },
  },
  {
    path: "/mqtt/rule/:deviceId",
    name: "MQTTSetRule",
    component: () => import("./views/MQTTSetRule.vue"),
    meta: {
      rights: ["mqtt.admin"],
    },
    props: (route) => {
      return {
        deviceId: route.params.deviceId,
      };
    },
  },
];

export default router;

{
  "created": "Hier erstellt",
  "createdText": "Das Ereignis wurde auf Board \"{boardName}\" am {createdAt} erstellt.",
  "createdTextOwn": "Das Ereignis wurde auf diesem Board am {createdAt} erstellt.",
  "delegated": "Delegiert",
  "delegatedText": "Das Ereignis wurde am {createdAt} auf Board \"{boardName}\" delegiert.",
  "delegatedTextOwn": "Das Ereignis wurde am {createdAt} auf diesem Board delegiert.",
  "escalated": "Eskaliert",
  "escalatedText": "Das Ereignis wurde am {createdAt} auf Board \"{boardName}\" eskaliert.",
  "escalatedTextOwn": "Das Ereignis wurde am {createdAt} auf diesem Board eskaliert.",
  "otherOrigin": "Das Ereignis wurde auf einem anderen Board erstellt.",
  "reason": "Der Grund war \"{reason}\".",
  "sameOrigin": "Das Ereignis wurde auf diesem Board erstellt."
}

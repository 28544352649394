{
  "archiveWithoutTasks": {
    "names": {
      "archive": "Archivierung von Ereignisse ohne Aktionen zulassen"
    },
    "title": "Archiv"
  },
  "businessYear": {
    "names": {
      "businessYearStart": "Startmonat"
    },
    "title": "Beginn des Geschäftsjahres"
  },
  "changeRequestBoardId": {
    "names": {
      "changeRequestBoardId": "ID"
    },
    "title": "Änderungsantragsboard"
  },
  "issueDestroyTimer": {
    "names": {
      "hour": "Stunden"
    },
    "title": "Zeitintervall indem archivierte Issues angezeigt werden"
  },
  "placeholderImage": {
    "names": {
      "placeholderImage": "Default Image"
    },
    "title": "Sonstiges"
  },
  "plbSort": {
    "names": {
      "inlineEditActionsAnonym": "Inline editieren von Aktionen als anonymer Benutzer",
      "issueSort": "Sortierung der Ereignisse:",
      "taskSort": "Sortierung der Aktionen:"
    },
    "options": {
      "ASC": "Aufsteigend",
      "DESC": "Absteigend"
    },
    "title": "Problemlösungsboard"
  }
}

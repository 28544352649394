{
  "Feedback": "Eine Rückmeldung von {method} \"{targetName}\" ist am {date} angekommen.\n\nZielzustand:\n{description}",
  "FeedbackCopy": "Eine Rückmeldung wurde am {date} an Ereignis \"{id}\" an {method} \"{targetName}\" gesendet.\n\nZielzustand:\n{description}",
  "Forwarded": "Das Ereignis wurde am {date} an {method} \"{targetName}\" weitergeleitet.\n\nDer Grund ist:\n{description}",
  "ForwardedBoard": "Board",
  "ForwardedCopy": "Das Ereignis wurde am {date} von {method} \"{targetName}\" weitergeleitet.\n\nDer Grund ist:\n{description}",
  "ForwardedInstance": "Instanz",
  "attachments": "Anhänge",
  "change": "Ändern",
  "changelog": "Änderungen",
  "columns": {
    "assignee": "Verantwortlicher",
    "assigneeId": "Verantwortlicher",
    "deadline": "Zieldatum",
    "description": "Beschreibung",
    "id": "ID",
    "recommend": "Empfehlung",
    "revision": "Revision",
    "status": "Status",
    "status0": "Keine Handlung vorgenommen",
    "status1": "Plan",
    "status2": "Do",
    "status3": "Check",
    "status4": "Act",
    "status5": "Veto",
    "status6": "Kein Veto",
    "status7": "Empfehlung",
    "status8": "Empfehluing nicht möglich",
    "status9": "Kein Empfehlung",
    "veto": "Veto"
  },
  "comment": "Kommentar",
  "commentPlaceholder": "Kommentar eingeben",
  "commentUser": "Kommentator",
  "comments": {
    "created": "Aufgabe erstellt",
    "status": "Status geändert"
  },
  "create": "anlegen",
  "customAttributes": "Zusätzliche Attribute",
  "customAttributesPlaceholder": "Hier ist es möglich der Aufgabe noch weitere Felder hinzuzufügen, die dann später auf dem Problemlösungsboard gepflegt werden können.",
  "deadline": "Zieldatum",
  "deadlinePlaceholder": "Bitte Frist eintragen",
  "description": "Beschreibung",
  "descriptionPlaceholder": "Bitte Beschreibung eingeben",
  "duplicatedTag": "Doppelter Eintrag",
  "feedbackResponse": "Rückmeldung",
  "forwardBadge": "Weitergeleitet",
  "headline": "Neue Aufgabe anlegen",
  "headlineChange": "Aufgabe ändern",
  "idea": {
    "alreadyChoosen": "Es wurde bereits ein Veto abgegeben",
    "noVeto": "Kein Veto",
    "nothing": "Noch nicht abgestimmt",
    "recommend": "Empfehlung",
    "recommendNot": "Keine Empfehlung",
    "recommendNotPossible": "Empfehlung nicht möglich",
    "veto": "Veto"
  },
  "ih": "Instandhaltung",
  "ihUser": "Vorarbeiter/Meister",
  "invalidShorthand": "Die zugehörige Aufgabe wurde gelöscht.",
  "noComments": "Keine Kommentare",
  "pdcaCircle1": "Plan",
  "pdcaCircle2": "Do",
  "pdcaCircle3": "Check",
  "pdcaCircle4": "Act",
  "redirectIH": "Störung an IH weiterleiten",
  "redirectIHHappend": "Störung wurde an IH weitergeleitet",
  "sap": "SAP Nr.:",
  "status": "Status",
  "statusChanged": "Status geändert",
  "title": "Ereignis",
  "user": "Verantwortlicher",
  "userPlaceholder": "Benutzer auswählen"
}

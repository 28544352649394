{
  "KPIBoardSettings": "KPI-Board",
  "TaskBoardSettings": "PL-Board",
  "allowedUser": "Board-Zugriff einschränken, außer für:",
  "balert": "Die Technische Kategorie weicht dem des Boards ab!",
  "board": "Board",
  "boardAccess": "Board-Zugriff",
  "boardAccessInfos": "Nur den angegebenen Benutzern aus der Liste ist der Board-Zugriff gestattet. Sind keine Benutzer angegeben, wird das Board für jeden sichtbar.",
  "boardId": "Board",
  "boardInformation": "Board-Informationen",
  "categoryId": "Kategorie ID",
  "categoryIdTooltip": "Achtung: Das ändern einer technischen Kategorie kann bei bereits existierenden Kacheln zur Folge haben, dass bereits eingegebene Daten nicht mehr angezeigt werden.",
  "categoryIdTooltipNew": "Achtung: Die Technische Kategorie bestimmt die Datengrundlage. Wenn ein Technischer Platz von einem vorhandenen Board genutzt wird und der selbe Datenpunkttyp benutzt wird, wird auf der selben Datengrundlage gearbeitet.",
  "costCenter": "Kostenstelle",
  "costCenterIdTooltip": "Achtung: Das ändern einer Kostenstelle kann bei bereits existierenden Kacheln zur Folge haben, dass bereits eingegebene Daten nicht mehr angezeigt werden.",
  "costCenterIdTooltipNew": "Achtung: Die Kostenstelle bestimmt die Datengrundlage. Wenn eine Kostenstelle von einem vorhandenen Board genutzt wird und der selbe Datenpunkttyp benutzt wird, wird auf der selben Datengrundlage gearbeitet.",
  "createBoard": "Board anlegen",
  "deleteBoard": "Board löschen",
  "deleteBoardConfirmation": "Soll das Board {name} unwiderruflich gelöscht werden?",
  "deleteFlag": "Flag löschen",
  "flag": "Flag",
  "flagDescription": "Hier ist es möglich Ereignisse von anderen Boards über Flags an diesem Board anzuzeigen.",
  "id": "ID",
  "name": "Name",
  "nameTooltip": "Der Boardname ist einzigartig über den Standort.",
  "newFlag": "Flag anlegen",
  "parentBoard": "Übergeordnetes Board",
  "pleaseConfirm": "Bitte bestätigen",
  "problemsolvingboard": "Problemlösungsboard",
  "responsible": "Verantwortlicher",
  "saveChanges": "Änderungen speichern",
  "selectTile": "Kachel auswählen",
  "settings": "Einstellungen",
  "site": "Standort",
  "technicalCategory": "Technische Kategorie",
  "technicalPlace": "Technischer Platz",
  "technicalPlaceIdTooltip": "Achtung: Das ändern eines technischen Platzes kann bei bereits existierenden Kacheln zur Folge haben, dass bereits eingegebene Daten nicht mehr angezeigt werden.",
  "technicalPlaceIdTooltipNew": "Achtung: Der Technische Platz bestimmt die Datengrundlage. Wenn ein Technischer Platz von einem vorhandenen Board genutzt wird und der selbe Datenpunkttyp benutzt wird, wird auf der selben Datengrundlage gearbeitet.",
  "template": "Boardvorlage",
  "templateTooltip": "Es können nur Vorlagen ausgewählt werden, die eine ausreichende Spaltenanzahl besitzen um alle bereits existierenden Kacheln darzustellen."
}

{
  "breadcrumbs": {
    "upload": "/ Uploads"
  },
  "buttons": {
    "newUpload": "Neuer Upload",
    "saveUpload": "Speichern"
  },
  "toasts": {
    "errorUpdateFile": {
      "title": "Fehler!",
      "text": "Beim speichern der Datei ist ein Fehler aufgetreten!"
    }
  },
  "list": {
    "name": "Uploads",
    "noItems": "Dieses Dokument hat noch keine Dateien!",
    "options": {
      "preview": "Vorschau",
      "pages": "Seiten",
      "fileName": "Dateiname",
      "createdAt": "Hinzugefügt",
      "id": "Aktionen"
    }
  },
  "editModal": {
    "title1": "'{name}' editieren",
    "title2": "Dateiname"
  },
  "confirmationDialog": {
    "deleteUpload": {
      "title": "Upload '{name}' löschen?",
      "okText": "Datei Löschen",
      "cancelText": "Abbrechen"
    }
  }
}

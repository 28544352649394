<template>
  <Badge class="cursor-pointer text-wrap" v-on="$attrs">
    <i v-if="filter.isNegated" class="fas fa-not-equal mr-2" />
    <slot />
    <i class="ml-1 fas fa-times" @click.stop="$emit('remove')" />
  </Badge>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Badge from "@/components/badges/BadgeComponent.vue";
import { ActionFilter, EventFilter } from "../../FilterTypes";

export default defineComponent({
  name: "FilterBadge",
  components: {
    Badge,
  },
  props: {
    filter: {
      type: Object as PropType<EventFilter | ActionFilter>,
      required: true,
    },
  },
  emits: ["remove"],
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>

{
  "assignee": "Verantwortlicher",
  "createNewTask": "Neue Aufgabe anlegen",
  "dateFormat": "DD.MM.YYYY",
  "deadline": "Zieldatum",
  "description": "Beschreibung",
  "id": "Nr.",
  "status": "Status",
  "title": "Titel"
}

{
  "addCalculation": "Berechnungsdatenreihe hinzufügen",
  "addDatapoint": "Datenreihe hinzufügen",
  "addSeries": "Datenreihe hinzufügen",
  "addTarget": "Zielwertdatenreihe hinzufügen",
  "unique": "Der Name muss über alle Datenpunkte, Zielwerte und Berechnungen einzigartig sein.",
  "uniqueKey": "Einzigartiger Datenreihenname",
  "updateCalculation": "Berechnungsdatenreihe ändern",
  "updateDatapoint": "Datenreihe ändern",
  "updateSeries": "Datenreihe ändern",
  "updateTarget": "Zielwertdatenreihe ändern"
}

{
  "buttons": {
    "addUser": "Benutzer hinzufügen"
  },
  "errors": {
    "unique": "Diese Kombination existiert bereits!"
  },
  "modalTitle": "Benutzer hinzufügen",
  "options": {
    "actions": "Aktionen",
    "site": "Standort",
    "user": "Benutzer"
  },
  "title": "E-Mail-Verteiler"
}

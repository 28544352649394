{
  "customerImage": "Kundenlogo",
  "customerName": "Kundenname",
  "domainName": "Domainname",
  "filePlaceholder": "Wähle eine .png-Datei als Kundenlogo aus",
  "hasStaging": "Staging",
  "internalName": "Interner Name",
  "modules": "Lizenzen",
  "numberOfInstances": "Anzahl an Instanzen",
  "numberOfInstancesInfoText": "Um so mehr Anzahl an Instanzen ein Kunde hat, umso mehr Anfragen können parallel verarbeitet werden. Allerdings steigt damit auch der RAM-Verbrauch auf unserem Server.",
  "visitVia": "erreichbar über"
}

{
  "createMeeting": "Meeting anlegen",
  "finishMyMeeting": "Mein Meeting beenden",
  "joinMeeting": "Meeting beitreten",
  "joinMeetingBy": "Meeting von {by} beitreten",
  "joinMyMeeting": "Meinem Meeting beitreten",
  "liveLoading": "Meetings werden live aktualisiert",
  "maximize": "Fenster einblenden",
  "meetings": "Meetings",
  "myMeetingsHeader": "Meine Meetings für dieses Board",
  "otherBoardMeetingsHeader": "Meetings für andere Boards",
  "thisBoardMeetingsHeader": "Meetings für dieses Board"
}

/* eslint-disable no-underscore-dangle */
import { ActionTree } from "vuex";

import { setLocale } from "@vee-validate/i18n";
import i18n, {
  languageKeys,
  loadLanguageAsync,
  mergeLocaleMessage,
  insertLanguageLocal,
} from "@/i18n";
import { languageNames } from "@/language";
import api from "../api/api";

const localState = {};

function checkIfLanguageIsSupported(locale: string) {
  const localeElements = locale.split("-");
  if (localeElements.length === 1) {
    if (languageKeys.some((lang) => lang === localeElements[0])) {
      return localeElements[0];
    }
  } else if (localeElements.length > 1) {
    const specificLanguage = `${localeElements[0]}_${localeElements[1]}`;
    if (languageKeys.some((lang) => lang === specificLanguage)) {
      return specificLanguage;
    }
    if (languageKeys.some((lang) => lang === localeElements[0])) {
      return localeElements[0];
    }
  }
  return null;
}

const actions: ActionTree<typeof localState, any> = {
  async setBrowserLocal(context) {
    for (let i = 0; i < navigator.languages.length; i += 1) {
      const matchingLanguage = checkIfLanguageIsSupported(navigator.languages[i]);
      if (matchingLanguage != null) {
        context.dispatch("setLocale", matchingLanguage);
        return;
      }
    }
    context.dispatch("language/setLocale", languageKeys[0]);
  },
  async setLocale(context, locale: (typeof languageKeys)[number]) {
    if (locale == null) {
      return;
    }

    let dynamicMessages = {} as any;
    if (context.rootGetters["login/isLogin"]) {
      dynamicMessages = await api.language.getLocale(locale ?? "de");
    }
    await loadLanguageAsync(locale, dynamicMessages[locale]);
    if (context.rootGetters["login/isLogin"]) {
      // temporary merge dynamic languages todo
      Object.keys(languageNames).forEach((cLocale) => {
        i18n.global.mergeLocaleMessage(cLocale, {
          dynamic: dynamicMessages.dynamicLanguages[cLocale],
        });
        // // unfortunately we have to trigger the reactivity of the messages object here manually, for up to now unknown reasons.
        // // @ts-expect-error _vm is not public, therefore we expect an error here.
        // // eslint-disable-next-line no-underscore-dangle
        // Vue.set(window.$root.$i18n._vm.messages, "reactivityFix", Math.random());
      });
    }
    // vee validate
    setLocale(locale);
    i18n.global.locale.value = locale as any;
  },
  async updateLanguage(context, payload) {
    const strings = Object.fromEntries(Object.entries(payload.strings));
    await api.language.updateLanguage(payload.id, strings);
    Object.keys(languageNames).forEach((locale) => {
      const messages = { dynamic: { [payload.id]: strings[locale] } };
      mergeLocaleMessage(locale, messages as any);
    });
  },
  localBulkUpdate(context, payload) {
    insertLanguageLocal(i18n.global.locale.value, payload);
  },
};

export default {
  namespaced: true,
  state: localState,
  actions,
};

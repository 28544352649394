{
  "answerGreen": "kein Ereignis ableiten (Optional, z.B. für Modul Kreis)",
  "answerGreenPlaceholder": "Antwort um kein Ereignis abzuleiten",
  "answerRed": "Ereignis ableiten (Optional, z.B. für Modul Kreis)",
  "answerRedPlaceholder": "Antwort um ein Ereignis abzuleiten",
  "chartLayouts": "Chart-Vorlagen",
  "createType": "Datenpunkttyp anlegen",
  "datapointType": "Datenpunkttypen",
  "deleteInfo": "Dieser DatenpunktTyp kann nicht gelöscht werden da er in {count} Kachel verwendet wird! | Dieser DatenpunktTyp kann nicht gelöscht werden da er in {count} Kacheln verwendet wir",
  "deleteInfo2": " Bitte Datenpunkttyp aus Kachel entfernen oder Kachel löschen.",
  "deleteModal": {
    "text": "Sind Sie sicher dass Sie diesen DatenpunktTypen löschen wollen?",
    "title": "Sind Sie sicher?"
  },
  "description": "Beschreibung",
  "exampleHeader": "Vorschau:",
  "fields": {
    "datapointCount": "Datenpunkte",
    "datapointcount": "Datenpunkte",
    "id": "ID",
    "name": "Name",
    "question": "Frage",
    "unit": "Einheit",
    "usage": "Verwendung"
  },
  "hint": "Hinweis",
  "newType": "Datenpunkttyp anlegen",
  "optionBoardName": "Board",
  "optionPSCLabel": "Problemlösung",
  "optionSiteName": "Standort",
  "optionSystemSettings": "Systemeinstellungen",
  "optionTileName": "Kachel",
  "question": "Frage (Optional, z.B. für Modul Kreis)",
  "questionNamaPlaceholder": "Datenpunkttyp Name",
  "questionPlaceholder": "Frage",
  "tooltip": "Die Darstellung der Fragen und Antworten in den einzelnen Kacheln kann sich Aufgrund des Zoomfaktors im Browser, der Bildschirmauflösung des Rechners und sonstigen undefinierten Einflussparametern unterscheiden. 💡 Zitat Friedrich Schiller (deutscher Dichter, 1759 – 1805): „Einfachheit ist das Resultat der Reife.“",
  "types": "Typen",
  "unit": "Einheit",
  "usageHeader": "Verwendung:",
  "validator": {
    "alreadyTakenName": "Dieser Name ist bereits vergeben",
    "doubleSpaceNotAllowed": "Doppelte Leerzeichen sind nicht erlaubt",
    "required": "Erforderliches Feld",
    "spaceNotAllowed": "Leerzeichen am Anfang und am Ende sind nicht erlaubt"
  }
}

export default {
  endpoints: {
    getIssues: {
      route: ":id",
      method: "POST",
    },
    getIssue: {
      route: ":id",
      method: "GET",
    },
    getForUser: {
      route: "forUser",
      method: "POST",
    },
    createIssue: {
      route: "",
      method: "POST",
    },
    reactivateIssue: {
      route: "reactivateIssue/:id",
      method: "PUT",
    },
    updateIssue: {
      route: ":id",
      method: "PUT",
    },
    createTask: {
      route: ":id/task",
      method: "POST",
    },
    updateTask: {
      route: "task/:id",
      method: "PUT",
    },
    updateStatus: {
      route: "task/:id/status/:status",
      method: "PUT",
    },
    moveIssue: {
      route: "moveIssue/:id/:type/:boardId",
      method: "POST",
    },
    getOpenIssues: {
      route: "openCount",
      method: "GET",
    },
  },
};

{
  "header": "Fehlgeschlagene Loginversuche",
  "options": {
    "name": "Username",
    "type": "Ort",
    "time": "Zeit",
    "ip": "IP",
    "uas": "User-Agent"
  },
  "types": {
    "Login": "Login",
    "PasswordRecovery": "Passwordwiederherstellung"
  }
}

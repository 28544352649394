import type Keycloak from "keycloak-js";
import { wsLink } from "@/graphql/apollo";

let refreshInterval: null | NodeJS.Timeout = null;

export async function startRefreshKeycloakAccessTokenInterval(
  keycloak: Keycloak,
  forceRestart = false,
) {
  if (forceRestart && refreshInterval != null) {
    clearInterval(refreshInterval);
  }

  if (refreshInterval != null) {
    return;
  }
  // Token Refresh
  refreshInterval = setInterval(async () => {
    if (keycloak.isTokenExpired(70)) {
      await keycloak.updateToken(-1);
      wsLink.client.terminate();
    }
  }, 6000);
}

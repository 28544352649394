{
  "boardliste": "Boardliste",
  "board": "Boards",
  "boardTemplate": "Template",
  "boardname": "Boardname",
  "configLayout": "Layout konfigurieren",
  "settings": "Einstellungen",
  "createNewBoard": "Neues Board anlegen",
  "options": {
    "id": "ID",
    "name": "Boardname",
    "template": "Template",
    "assignee": "Verantwortlicher",
    "config": "Konfigurieren"
  },
  "configuration": "Konfigurieren",
  "responsible": "Verantwortlicher"
}

import { EventListEventFieldsFragment, TaskStatus } from "@/graphql";
import { EventFilter } from "../FilterTypes";
import EventActionRelationFilterUI from "../ui/EventActionRelationFilterUI.vue";
import EventActionRelationFilterBadge from "../ui/badges/EventActionRelationFilterBadge.vue";

export enum EventActionRelationFilterOptions {
  WITHOUT_ACTION = "WITHOUT_ACTION",
  ONLY_ACT_ACTIONS = "ONLY_ACT_ACTIONS",
}

export default class EventActionRelationFilter implements EventFilter {
  constructor(
    public readonly filters: EventActionRelationFilterOptions[],
    public readonly isNegated?: boolean,
  ) {}

  static readonly Badge = EventActionRelationFilterBadge;

  static readonly UI = EventActionRelationFilterUI;

  isEventActive(event: EventListEventFieldsFragment) {
    const isActive = this.filter(event);
    return this.isNegated ? !isActive : isActive;
  }

  filter(item: EventListEventFieldsFragment) {
    return this.filters.some((filter) => {
      switch (filter) {
        case EventActionRelationFilterOptions.ONLY_ACT_ACTIONS:
          return (
            item.actions.length > 0 &&
            item.actions.every((action) => action.status === TaskStatus.act)
          );
        case EventActionRelationFilterOptions.WITHOUT_ACTION:
          return item.actions.length === 0;
        default:
          throw Error(`unknown EventActionRelationFilterOption: ${filter}`);
      }
    });
  }
}

{
  "forms": {
    "name": {
      "label": "Bezeichnung",
      "placeholder": "Bezeichnung"
    },
    "columnFormat": {
      "label": "Spaltenformatierung",
      "options": {
        "german": "Deutsch",
        "american": "Amerikanisch",
        "english": "Englisch",
        "specialDate1": "Montag, 15.12.1995"
      }
    }
  }
}

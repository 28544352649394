{
  "Error": "Error",
  "defaultPlaceholder": "Bitte wählen",
  "listEmptyString": "Die Liste ist leer.",
  "moreLetterString": "Bitte mehr Buchstaben eingeben.",
  "noResultPossibleString": "Keine Ergebnisse verfügbar.",
  "noResultString": "Kein Ergebnisse gefunden.",
  "searchString": "Bitte Suchbegriff eingeben.",
  "siteDefaultPlaceholder": "Bitte Standort wählen"
}

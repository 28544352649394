{
  "attributes": {
    "materialDescriptionId": "Beschreibung",
    "materialNr": "Material Nr.",
    "quantity": "Menge",
    "storageLocations": "Lagerplatz"
  },
  "buttons": {
    "checkOrder": "Bestellung prüfen",
    "saveSettings": "Voreinstellungen speichern"
  },
  "fields": {
    "action": "Aktion",
    "materialDescriptionId": "Beschreibung",
    "materialNr": "Material Nr.",
    "quantity": "Liefermenge"
  },
  "infotexts": {
    "saveToStorage": "Speichert die aktuellen Einstellungen (Versorgungspunkt, Standort) für die aktuelle Sitzung. Wird beim erneuten Öffnen automatisch ausgefüllt."
  },
  "infotooltip": {
    "text": "Das Material muss in einem Lagerplatz innerhalb eines Versorgungslagers sein.",
    "title": "Material kann nicht verbucht werden"
  },
  "materials": "Materialien",
  "modal": {
    "title": "Lieferung prüfen"
  },
  "noMaterials": "Keine Materialien zum Anzeigen",
  "scanQrcode": "QR-Code scannen",
  "select": {
    "siteId": "Standort",
    "supplyPoint": "Versorgungspunkt"
  },
  "title": "Material anfordern",
  "toast": {
    "succesSaveSettings": "Die Einstellungen wurden gespeichert",
    "sucess": {
      "text": "Es wurde ein Transport angelegt. | Es wurden {length} Transporte angelegt."
    }
  },
  "toastTableToptions": {
    "material": "Material",
    "target": "Ziel",
    "type": "Typ"
  }
}

{
  "ApprovedLabel": "Genehmigt",
  "ClosedLabel": "Abgeschlossen",
  "EditedLabel": "Bearbeitet",
  "ImplementedLabel": "Umgesetzt",
  "InImplementationLabel": "In der Bearbeitung",
  "OpenedLabel": "Geöffnet",
  "PostponedLabel": "Aufgeschoben",
  "RejectedLabel": "Abgelehnt",
  "ReturnedLabel": "Zurückgeschickt",
  "SubmittedLabel": "Eingereicht",
  "statusLabel": "Status:",
  "typeCR": "Änderungsantrag",
  "typeError": "Fehler",
  "typeLabel": "Typ:"
}

{
  "approvals": "Fehlende Stundenzettel",
  "lastStamp": "Letzter Stempel",
  "neverStamped": "Es wurde nocht nicht gestempelt.",
  "noShiftPlan": "Sie sind keinem Schichtplan zugeordnet.",
  "noTimeSheetsSubmitted": "Es wurde nichts eingereicht",
  "shiftPlan": "Aktueller Schichtplan",
  "team": "Aktuelles Team",
  "timeAccount": "Zeitkonto",
  "vacation": "Urlaubstage (noch nicht genehmigt)"
}

{
  "header": {
    "title": "Vorgabewerte für lisaCharts"
  },
  "forms": {
    "inputLocation": {
      "label": "Standort"
    },
    "inputEditType": {
      "label": "Eingabetyp",
      "options": {
        "ist": "Ist-werte",
        "target": "Vorgabe-werte"
      }
    },
    "inputLayout": {
      "label": "Layout",
      "placeholder": "Bitte Layout auswählen",
      "options": {
        "common": "Allgemein",
        "pps": "Target PPS"
      }
    },
    "inputTime": {
      "label": "Zeitpunkt",
      "text": "Kalenderwoche {kw}",
      "buttonToday": "Heute"
    },
    "tabs": {
      "preValues": "Vorgabewerte",
      "values": "Ist Werte"
    }
  },
  "loading": "Daten werden geladen",
  "table": {
    "boardTitle": "Board:",
    "tileTitle": "Kachel",
    "additionalTiles": "Weitere Kacheln",
    "dataTypeTitle": "Datentyp",
    "shiftScheduleTitle": "Schichtplan",
    "categoryTitle": "Kategorie",
    "fields": {
      "name": {
        "title": "Schicht"
      },
      "date": {
        "day": "dd",
        "date": "DD.MM.YYYY"
      }
    },
    "shift": "Schicht",
    "day": "Tag"
  },
  "headers": {
    "board": "Board:",
    "category": "Kategorie:",
    "tile": "Kachel:",
    "type": "Datenpunkttyp:"
  },
  "spinner": {
    "loading": "Daten werden geladen"
  }
}

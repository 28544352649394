{
  "buttons": {
    "createEvent": "Termin anlegen"
  },
  "info": {
    "noBoards": "Dieser Termin wird auf keinem Kalender angezeigt!"
  },
  "labels": {
    "addBoard": "Board hinzufügen",
    "addCategory": "Technischen Platz hinzufügen",
    "allDay": "Ganztägig",
    "boards": "Boards",
    "categories": "Technische Plätze",
    "description": "Beschreibung",
    "endTime": "Ende",
    "startTime": "Beginn",
    "title": "Titel",
    "viewType": "Darstellung"
  },
  "placeholder": {
    "technicalPlace": "Technischen Platz auswählen"
  },
  "rights": {
    "edit": {
      "false": "Unzureichende Berechtigung"
    }
  },
  "settings": {
    "labels": {
      "maximisedInput": "Minimierte Kalenderansicht",
      "minimizedInput": "Maximierte Kalenderansicht"
    }
  },
  "titles": {
    "emptyTitle": "Bitte Titel eingeben..."
  },
  "types": {
    "dayGridMonth": "Monatsansicht",
    "listDay": "Tagesansicht (Liste)",
    "listMonth": "Monatsansicht (Liste)",
    "listWeek": "Wochenansicht (Liste)",
    "timeGrid7Day": "Wochenansicht",
    "timeGridOneDay": "Tagesansicht",
    "workWeek": "Arbeitswoche"
  }
}

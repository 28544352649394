<template>
  <ValidationProvider v-slot="{ valid }" :modelValue="selected" :rules="{ required: true }">
    <MultiSelect
      ref="filterRef"
      v-model="selected"
      track-by="id"
      option-label="displayName"
      :filterFields="['name', 'displayName', 'id']"
      class="w-100"
      display="chip"
      autoFilterFocus
      filter
      :class="valid ? 'p-valid' : 'p-invalid'"
      :options="options"
      @filter="searchChange"
      @change="toggle"
    />
  </ValidationProvider>
</template>

<script lang="ts">
import MultiSelect from "primevue/multiselect";
import { computed, defineComponent, PropType, ref } from "vue";
import CreatorFilter from "../filterImplementations/CreatorFilter";
import { FilterEntity } from "../FilterTypes";
import refilterEventsWithout from "./RefilterEventsWithout";
import ValidationProvider from "@/modules/veevalidate/components/ValidationProvider.vue";

export default defineComponent({
  name: "CreatorFilterUI",
  components: {
    MultiSelect,
    ValidationProvider,
  },
  props: {
    mode: {
      required: true,
      type: Number as PropType<FilterEntity>,
    },
  },
  setup(props) {
    const selected = ref<typeof options.value>([]);
    const filteredEvents = refilterEventsWithout((filter) => !(filter instanceof CreatorFilter));

    const options = computed(() => {
      let people: Record<number, string>;
      switch (props.mode) {
        case FilterEntity.EVENT:
          people = filteredEvents.value.activeEvents.reduce((prev, curr) => {
            if (curr.owner) {
              prev[curr.owner.id] = curr.owner.preferredName;
            }
            return prev;
          }, {} as Record<number, string>);
          break;
        case FilterEntity.ACTION:
          people = filteredEvents.value.activeEvents
            .flatMap((event) => event.actions)
            .reduce((prev, curr) => {
              if (curr.assignee) {
                prev[curr.assignee.id] = curr.assignee.preferredName;
              }
              return prev;
            }, {} as Record<number, string>);
          break;
        default:
          return [];
      }
      return Object.entries(people)
        .map(([id, name]) => ({
          id: Number(id),
          name: name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          displayName: name,
        }))
        .sort((lhs, rhs) => {
          return lhs.name.localeCompare(rhs.name);
        });
    });

    const filterRef = ref<InstanceType<typeof MultiSelect>>();

    function searchChange(event: { originalEvent: Event; value: string }) {
      if (filterRef.value != null && "filterValue" in filterRef.value) {
        filterRef.value.filterValue = event.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }
    }
    function toggle() {
      filterRef.value?.hide();
    }
    return {
      selected,
      toggle,
      options,
      getFilter() {
        return new CreatorFilter(selected.value.map(({ id }) => id));
      },
      applyFilter(filter: CreatorFilter) {
        selected.value = filter.creators.map((id) => {
          const user = options.value.find((v) => v.id === id);
          return {
            id,
            name: user?.name ?? "?",
            displayName: user?.displayName ?? "?",
          };
        });
      },
      filterRef,
      searchChange,
    };
  },
});
</script>

<style lang="scss" scoped></style>

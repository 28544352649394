{
  "addColumnOverlay": "Säulen mit Säulen hinterlegen",
  "breakLines": "Linie bei Unterbrechung verbinden",
  "columnOverlay": "Säulen",
  "columnOverviewTooltipText": "Wenn zwei Säulen überlagert werden, muss für jede Datenreihe eine weitere Datenreihe ausgewählt werden, welche im Hintergrund angezeigt wird. Diese Überlagerung gilt nicht für vorhergegangene Werte.",
  "dataLabel": "Datenlabel",
  "general": "Allgemein",
  "hideSecondary": "Blende Datenreihen auf der Sekundaärachse aus",
  "interval": "Zeitraum",
  "intervalStart": "Zeitraumstart",
  "label": "Label",
  "legend": "Legende",
  "line": "Linien",
  "showDataLabels": "Werte über den Säulen einblenden",
  "showDataLabelsAll": "Werte über anderen Diagrammtypen einblenden",
  "showValueAsTitle": "Zeige einen Datenreihen Wert als Label an",
  "xAxisName": "X-Achse",
  "yAxisNamePrim": "Primäre Y-Achse",
  "yAxisNameSec": "Sekundäre Y-Achse"
}

{
  "actions": {
    "label": "Aktionen"
  },
  "buttons": {
    "addMaterial": "Material hinzufügen"
  },
  "downloadAll": "Alle herunterladen",
  "header": "Versorgungspunkt",
  "list": {
    "header": "Versorgungspunkte",
    "headerMultiOrZero": "Versorgungspunkt | Versorgungspunkte"
  },
  "materials": {
    "label": "Materialien"
  },
  "name": {
    "label": "Name"
  },
  "qrCode": {
    "label": "QR-Code"
  },
  "shorts": {
    "materialNr": "MN",
    "storageLocation": "LP",
    "supplyPoint": "VP"
  },
  "storageLocation": {
    "label": "Lagerplatz"
  },
  "storageLocationId": {
    "label": "Lagerplatz"
  }
}

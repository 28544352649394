{
  "headlines": {
    "miniHeader": "Design & Farben"
  },
  "menuOptions": {
    "colorPalette": "Farbpaletten",
    "navItems": "Navigationselemente"
  },
  "statusTexts": {
    "noSettingsAvailable": "Keine Elemente verfügbar"
  }
}

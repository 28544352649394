{
  "DateEnd": "Enddatum",
  "DateStart": "Startdatum:",
  "Deadline": "Zeige nur abgelaufene",
  "DeadlineEnd": "Enddatum",
  "DeadlineStart": "Startdatum:",
  "FilterFlags": "Bitte Flags auswählen",
  "FilterFlagsShowEmpty": "Zeige Probleme ohne Flag",
  "FilterHeadline": "Filter Einstellungen:",
  "FilterID": "Nur kürzlich geänderte anzeigen",
  "FullText": {
    "boundBoards": "Eingebundene Boards",
    "onlyEscalatedAndDelegated": "Zeige nur Eskalierte und Delegierte Probleme",
    "onlyThisBoard": "Zeige nur Probleme von diesem Board",
    "searchLabel": "Suche nach Text"
  },
  "Status": "Zeige nur offene",
  "common": {
    "showClosedTasks": "Alle geschlossenen Aufgaben anzeigen",
    "showOpenTasks": "Alle offenen Aufgaben anzeigen",
    "title": "Allgemeine Einstellungen"
  },
  "showAllTasks": "Alle Aufgaben des Problems anzeigen"
}

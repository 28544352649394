{
  "BoardOriginFilter": "Filtern nach Boardherkunft",
  "CreatorFilter": "Filtern nach Ersteller",
  "DLZFilter": "Filtern nach Durchlaufszeit",
  "DateFilter": "Filtern nach Datum",
  "DescriptionFilter": "Filtern nach Beschreibung",
  "EventActionRelationFilter": "Filtern nach Aktionen ",
  "FlagsFilter": "Filtern nach Flags",
  "IDFilter": "Filtern nach ID und Typ",
  "OverdueFilter": "Filtern nach Überfälligkeit",
  "RecentlyChangedFilter": "Filtern nach letzter Änderung",
  "ResponsibleFilter": "Filtern nach Verantwortlicher",
  "StatusFilter": "Filtern nach Status"
}

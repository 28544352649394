<template>
  <div>
    <span>{{ $t("components.eventAction.filter.ui.DLZ.text") }}</span>
    <div class="flex flex-auto">
      <InputNumber v-model.number="numberOfHours" style="font-size: inherit" />

      <Dropdown
        v-model.number="timeMultiplier"
        :options="multiplierOptions"
        option-label="text"
        class="w-100"
        option-value="value"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import DLZFilter from "../filterImplementations/DLZFilter";

export default defineComponent({
  name: "DLZFilterUI",
  components: { Dropdown, InputNumber },
  setup() {
    const { t } = useI18n();
    const numberOfHours = ref(14);
    const timeMultiplier = ref<1 | 24>(24);

    function getFilter() {
      return new DLZFilter(numberOfHours.value * timeMultiplier.value);
    }

    function applyFilter(filter: DLZFilter) {
      if (filter.definitionOfDLZInHours % 24 === 0) {
        timeMultiplier.value = 24;
        numberOfHours.value = filter.definitionOfDLZInHours / 24;
      } else {
        timeMultiplier.value = 1;
        numberOfHours.value = filter.definitionOfDLZInHours;
      }
    }

    const multiplierOptions = computed(() => [
      {
        text: t("global.hours"),
        value: 1,
      },
      {
        text: t("global.days"),
        value: 24,
      },
    ]);
    return {
      numberOfHours,
      timeMultiplier,
      multiplierOptions,
      getFilter,
      applyFilter,
    };
  },
});
</script>

<style lang="scss" scoped>
.border-fix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

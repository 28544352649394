<template>
  <ValidationProvider v-slot="{ valid }" :modelValue="needle" :rules="{ required: true }">
    <InputText
      v-model="needle"
      :class="valid ? 'p-valid' : 'p-invalid'"
      class="w-100"
      autofocus
      @keydown.enter="$emit('applyFilter')"
    />
  </ValidationProvider>
</template>

<script lang="ts">
import InputText from "primevue/inputtext";
import { defineComponent, ref } from "vue";
import DescriptionFilter from "../filterImplementations/DescriptionFilter";
import ValidationProvider from "@/modules/veevalidate/components/ValidationProvider.vue";

export default defineComponent({
  name: "DescriptionFilterUI",
  components: {
    InputText,
    ValidationProvider,
  },
  emits: ["applyFilter"],
  setup() {
    const needle = ref("");
    return {
      needle,
      getFilter() {
        return new DescriptionFilter(needle.value);
      },
      applyFilter(filter: DescriptionFilter) {
        needle.value = filter.searchText;
      },
    };
  },
});
</script>

<style lang="scss" scoped></style>

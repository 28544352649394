{
  "Language": "Sprache",
  "UserSettings": "Benutzereinstellungen",
  "board": "Board",
  "boardTime": "Board-Zeit:",
  "boardWithId": "Board [id: {id}]",
  "changelog": "Changelog",
  "date": "Datum",
  "datum": "Datum",
  "documentation": "Wiki",
  "duration": "Dauer",
  "helpDesk": "Help-Desk",
  "imprint": "Impressum",
  "localTime": "Lokale Zeit:",
  "logout": "Abmelden",
  "meeting": "Meeting",
  "privacyStatement": "Datenschutz",
  "reset": "Reset",
  "standardboard": "standardboard",
  "start": "Start",
  "stop": "Stoppen",
  "week": "KW"
}

{
  "close": "Schließen",
  "decrement": "weniger",
  "hours": "Stunden",
  "increment": "mehr",
  "minutes": "Minuten",
  "noTimeSelected": "Keine Zeit gewählt",
  "seconds": "Sekunden",
  "selected": "Ausgewählt"
}

import store from "@/store";

export enum ModifierType {
  settings = 1 << 0,
  clippy = 1 << 1,
  unicorn = 1 << 2,
  experimentalFeature = 1 << 3,
  lucasStatusMode = 1 << 4,
  idHighlightWaves = 1 << 5,
  idHighlightBadge = 1 << 6,
  eabPerspective = 1 << 7,
}

export default function isModifierSet(modifier: ModifierType) {
  const currentModifier = store.getters["login/getModifier"];
  return (currentModifier & modifier) !== 0;
}

{
  "title": "Ordnerverwaltung",
  "buttons": {
    "saveButton": "Speichern",
    "deleteButton": "Löschen",
    "newDocument": "Neues Dokument",
    "newFolder": "Neuer Ordner"
  },
  "settings": "Einstellungen",
  "tooltips": {
    "deleteChildren": "Sie können nur Ordner löschen die keine Unterordner haben und keine Stamm Ordner sind"
  },
  "inputValidation": {
    "feedbackName": "Keine Sonderzeichen!"
  },
  "folderContent": {
    "title": "Ordnerinhalt"
  },
  "noDocuments": {
    "title": "Diesem Ordner wurden noch keine Dokumente zugewiesen!",
    "placeholder": "Ordner auswählen"
  },
  "noFolderStructure": "Es wurde noch keine Ordnerstruktur angelegt.",
  "dynamicVariables": {
    "newFolder": "Neuer Ordner"
  },
  "documentModal": {
    "title": "Dokument bearbeiten"
  },
  "confirmationDialog": {
    "title": "{name} löschen?",
    "text": "Wollen Sie den Ordner {name} wirklich löschen? Dokumente in diesem Ordner müssen neu zugewiesen werden!",
    "okText": "Ordner löschen",
    "cancelText": "Abbrechen"
  },
  "context": {
    "edit": "Bearbeiten",
    "newFolder": "Neuer Ordner",
    "newFile": "Neues Dokument",
    "new": "Neu"
  },
  "createModal": {
    "label": "Bezeichnung",
    "error": "mindestens 5 Zeichen",
    "min5": "mindestens 5 Zeichen",
    "min1Letter": "mindestens 1 Buchstabe"
  },
  "contextmenu": {
    "infotext": "Kontextmenü über Rechtsklick",
    "errorChilds": "Dieser Ordner hat Unterordner die zuerst gelöscht werden müssen!",
    "errorSystem": "Systemordner können nicht gelöscht werden!"
  },
  "document": {
    "title": "Upload: {date}"
  },
  "adress": "Adresse:"
}

{
  "aggSites": "Standorte",
  "aggTPs": "Technische Plätze",
  "aggregate": "Technische Plätze aggregieren?",
  "aggregateSite": "Alle Technischen Plätze eines Standortes aggregieren?",
  "aggregateSiteTooltip": "Achtung! Wenn Sie über einen Standort hinweg Daten aggregieren, dann geben Sie automatisch die Daten für alle Benutzer frei, die diesen Standort sehen können.",
  "aggregateTooltip": "Achtung! Die Dateneingabe ist bei aggregierten Charts deaktiviert. Außerdem geht bei der Aggregation von Zielwerten der Operator verloren. Auch werden Zielwerten und Datenpunkte unabhängig von einander aggregiert, sodass, wenn einer von beiden fehlt, der andere trotzdem aggregiert wird.",
  "category": "Kategorie",
  "chartLayout": "Chartlayout",
  "history": "Historie",
  "missingTarget": "Es wurde noch nie ein Zielwert für diesen Datentyp mit diesem Technischen Platz erstellt. Es muss ein Zielwert definiert werden, bevor die Kachel gespeichert werden kann.",
  "or": "oder",
  "summary": {
    "fields": {
      "display": {
        "label": "Darstellung der nicht fokusierten (kleinen) Kachel:"
      }
    },
    "infotext": "Die Darstellung der Kachel wird ausschließlich in der verkleinerten Kachel (nicht fokusiert / angeklickt) verändert. Wenn für die gewählte Darstellung keine Konfiguration vorhanden ist, wird die ursprüngliche Darstellung verwendet. Konfigurationen für alternative Darstellungen befinden sich in den {name}",
    "options": {
      "SUMMARY": {
        "label": "Zusammenfassung"
      },
      "default": {
        "label": "Chart"
      }
    },
    "replacements": {
      "chartLayout": "LISAChart - Vorlagen"
    }
  },
  "target": "Zielwert",
  "targetValueCanNotBeDisplayed": "            Dieser Zielwert ist Schichtbasiert. Die Werte können nicht in einer vereinfachten\n            Ansicht dargestellt werden. Zum Ansehen muss der Zielwert, durch Klicken, geöffnet\n            werden!",
  "targets": "Zielwerte",
  "technicalPlace": "Technischer Platz",
  "timestamp": "Gültig ab",
  "type": "Datenpunkttyp"
}

{
  "buttons": {
    "createTransportRequest": "Transportanfrage anlegen"
  },
  "header": "Materialverfügbarkeit",
  "info": {
    "storageLocationNoMaterial": "Dieser Lagerplatz hat keine Materialien"
  },
  "th": {
    "available": "Verfügbar",
    "description": "Beschreibung",
    "material": "Material",
    "materialNr": "Material Nr.",
    "quantity": "Anzahl",
    "reserved": "Reserviert",
    "transport": "Transport",
    "value": "Bestand"
  },
  "title": "Materialverfügbarkeit"
}

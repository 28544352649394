{
  "AssignmentTile": "Maschinenbelegung",
  "AuditTile": "Audit",
  "CalendarTile": "Kalender",
  "ChartTile": "LisaChart",
  "CheckListTile": "Checkliste",
  "ChecklistTile": "Checkliste",
  "ChecklistTileTile": "Checkliste",
  "CircleTile": "Kreis",
  "DashListTile": "Strichliste",
  "DocumentTile": "Dokument (Upload)",
  "DynamicChart": "LisaChart (Dynamisch)",
  "FeedbackTile": "Feedback",
  "LinkToTile": "LinkTo",
  "LisaChart": "Target PPS",
  "LisaChartTarget": "Target",
  "SVGTile": "Coil",
  "WeekScheduleTile": "Wochenplan",
  "copy": "Kopie",
  "dynamicChartTile": "LisaChart (Dynamisch)",
  "lisaChartTargetTile": "Target",
  "lisaChartTile": "Target PPS",
  "IFrameTile": "iFrame"
}

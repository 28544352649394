{
  "buttonLabel": "Art:",
  "delegate": "Delegieren",
  "escalate": "Eskalieren",
  "escalateButtonTooltip": "Eskalieren ist deaktiviert, da dieses Board kein übergeordnetes besitzt",
  "idLabel": "id:",
  "message": "Nachricht:",
  "reason": "Grund:",
  "save": "Speichern",
  "selectLabel": "Board:",
  "title": "Ereignis delegieren/eskalieren"
}

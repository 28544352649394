{
  "colors": {
    "black": "Schwarz",
    "blue": "Blau",
    "bluegray": "Blaugrün",
    "darkblue": "Dunkelblau",
    "darkgreen": "Dunkelgrün",
    "darkred": "Dunkelrot",
    "gold": "Gold",
    "gray": "grau",
    "green": "Grün",
    "lightblue": "Hellblau",
    "lightgray": "Hellgrau",
    "lightgreen": "Hellgrün",
    "orange": "Orange",
    "purple": "Lila",
    "red": "Rot",
    "white": "Weiß",
    "yellow": "Gelb"
  }
}

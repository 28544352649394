import { computed, inject, InjectionKey, Ref } from "vue";
import { DateTime } from "luxon";
import injectAsserted from "@/functions/assert/injectAsserted";
import { EventListEventFieldsFragment } from "@/graphql";
import { isActionActive, isEventActive } from "./Filter";
import { ActionFilterInjectionKey, EventFilterInjectionKey } from "./FilterTypes";
import { BoardInjectionKey } from "@/views/event-action/EventActionBoardInjectionKeys";

type UnpackInjectionKey<I> = I extends InjectionKey<infer T> ? T : never;

export default function useFilteredEvents(
  events: Readonly<Ref<Readonly<EventListEventFieldsFragment[]>>>,
  givenEventFilterList?: UnpackInjectionKey<typeof EventFilterInjectionKey>,
  givenActionFilterList?: UnpackInjectionKey<typeof ActionFilterInjectionKey>,
) {
  const eventFilterList = givenEventFilterList ?? injectAsserted(EventFilterInjectionKey);
  const actionFilterList = givenActionFilterList ?? injectAsserted(ActionFilterInjectionKey);
  const board = inject(BoardInjectionKey, null);

  return computed(() => {
    const activeEvents = events.value.filter((event) => {
      const eventIsRecent = eventFilterList.value.updatedAt < DateTime.fromISO(event.updatedAt);

      const actionFiltersAreEmpty = actionFilterList.value.actionFilters.length === 0;

      const actionIsActive = event.actions.some((action) => {
        return isActionActive(actionFilterList.value, action);
      });
      const eventIsActive = isEventActive(eventFilterList.value, event, board?.value?.id);
      return eventIsRecent || ((actionFiltersAreEmpty || actionIsActive) && eventIsActive);
    });

    return {
      activeEvents,
      numberOf: {
        events: activeEvents.length,
        actions: activeEvents
          .flatMap((event) => event.actions)
          .filter((action) => isActionActive(actionFilterList.value, action)).length,
      },
    };
  });
}

{
  "taskBoardFilter": {
    "toast": {
      "success": {
        "text": "Der Filter ist jetzt unter \"Eigener Filter\" verwendbar",
        "title": "Filtereinstellungen gespeichert!"
      }
    }
  },
  "toast": {
    "error": {
      "AuthCannotChangePassword": "Fehler bei der Überprüfung. Erneut versuchen!  \n Wenn dieser Fehler erneut auftritt wenden Sie sich an einen Administrator.",
      "AuthInvalidPolicy": "Die Passwortrichtlinie wurde nicht erfüllt",
      "BISConfigNotFound": "BIS ist für diesen Standort nicht konfiguriert!",
      "BoardNameTaken": "Es gibt bereits ein Board mit diesem Namen!",
      "IHMailConfigDoesntExists": "IH-Mail ist für diesen Standort nicht konfiguriert!",
      "Method not implemented": "Die Funktion ist noch nicht umgesetzt!",
      "NoShiftAvailable": "Keine Schichten verfügbar. Datum liegt vielleicht zu weit in der Zukunft oder in der Vergangenheit.",
      "TargetsCombinationExists": "Die Kombination aus Standort, Datentyp und Kategorie existiert bereits.",
      "UserNotFound": "Benutzer nicht gefunden.",
      "anonymUserNotAllowed": "Anonyme Benutzer haben kein Recht für diese Aktion!",
      "assignmentIsInUse": "Maschinenbelegung wird von einer Kachel benutzt",
      "badJson": "Fehlerhafte Eingabe",
      "boardAccessNotAllowed": "Kein Zugriff erlaubt",
      "cannotApproveVacationOnSubmittedMonth": "Es ist nicht möglich einen Urlaubsantrag in einem eigereichtem Monat zu erlauben.",
      "cannotChangeOwnData": "Es ist nicht möglich seine eigenen Daten zu pflegen.",
      "cannotChangeOwnRequestsToStateOtherThanPending": "Es ist nicht möglich seine eigenen Fehltage zu pflegen.",
      "cannotCreateStampForUserThatIsStampedIn": "Es ist nicht möglich einen Stempel einzutragen, wenn der Benutzer eingestempelt ist.",
      "cannotCreateStampInFuture": "Es ist nicht möglich einen Stempel in der Zukunft anzulegen.",
      "cannotCreateStampOnSubmittedMonth": "Es nicht möglich einen Stempel in einem eingereichten Monat zu anlegen.",
      "cannotCreateStampsOverStamps": "Es ist nicht möglich Stempel über bereits exisitierende zu setzen.",
      "cannotCreateVacationOnSubmittedMonth": "Es können keine Fehltage an eingereichten Monaten erstellt werden.",
      "cannotCreateVacationThatOverlapsAnother": "Es können keine überlappenden Fehltage erstellt werden.",
      "cannotSubmitMonthWhenUserHasOpenStamp": "Es ist nicht möglich mit einem offenen Stempel den Stundenzettel einzureichen. Bitte ausstempeln.",
      "cantCreateTeamBeforeOtherTeam": "Es ist nicht mögliche eine Teamplanung vor einer anderen zu setzen.",
      "common": "Aktion nicht erfolgreich!",
      "default": "Ein Fehler ist aufgetreten",
      "defaultAlreadyInUse": "Es gibt bereits ein Standardtemplate",
      "expiredSession": "Session ist abgelaufen, fehlt oder ist ungültig",
      "hasChilds": "Es ist nicht möglich ein Board zu löschen, das Unterboards hat.",
      "howDareYou": "🖕 So nicht.",
      "invalidTimeFrame": "Unmögliches Zeitfenster gewählt",
      "issueAlreadyOnWeekSchedule": "Dieses Ereignis befindet sich bereits auf dem Wochenplan.",
      "lockedDueToBruteForce": "Zu viele fehlgeschlagene Anmeldeversuche. Bitte versuchen Sie es in wenigen Minuten erneut.",
      "logout_error": "Der Logout ist fehlgeschlagen!",
      "missingAccessRight": "Sie verfügen nicht über genug Rechte um diese Aktion auszuführen",
      "missingIHConfig": "Der Standort verfügt nicht über eine IH-Konfiguration",
      "nameNotUnique": "Der Name muss einzigartig sein!",
      "networkError": "Server nicht erreichbar",
      "noFeedbackConnectionConfigured": "Es wurde noch keine ausgehenden Verbindung konfiguriert. Feedback kann nicht gesendet werden.",
      "noHLPConfigFound": "Das Vorschlagswesen ist für diesen Standort nicht konfiguriert",
      "notAuthorized": "Sie sind nicht autorisiert für diese Aktion.",
      "notFound": "Die API konnte die gesuchten Daten nicht finden!",
      "onlyUserFlagIsDeleteAble": "Es können nur Benutzer-Flags gelöscht werden.",
      "pingNotSuccessful": "Die Ping-Anfrage ist fehlgeschlagen.",
      "possiblyPasswordProtected": "Die PDF-Datei ist möglicherweise verschlüsselt",
      "recoveryCodeIsWrong": "Der Schlüssel ist falsch oder abgelaufen",
      "refUserDoesNotHaveAnEmailAndPasswordSurpassLifetime": "Das Passowort ist abgelaufen und der Referenzbenutzer konnte nicht benachritigt werden, da dieser keine E-Mail-Adresse besitzt. Das Passwort muss von einem Admin neu gesetzt werden.",
      "refUserSurpassLifetime": "Das Passwort ist abgelaufen und der Referenzbenutzer wurde benachrichtigt und kann das Passwort ändern.",
      "reloadRequired": "Die Serverversion hat sich geändert. Bitte Seite neu laden!",
      "reusedOldPassword": "Sie können ein altes Passwort nicht wieder verwenden!",
      "stampDataCorrupted": "Stempeldaten sind vielleicht beschädigt. Bitte Admin kontaktieren",
      "stampInterferesWithSubmittedDate": "Quell- oder Zielzeitstempel liegt in einem eingereichten Monat",
      "tileIsAlreadyOnBoard": "Diese Kachel befindet sich bereits auf dem Board",
      "timestampInFeatureExists": "Es wurde bereits für die Zukunft gestempelt! Bitte Admin kontaktieren.",
      "title": "Fehler!",
      "userDoesNotHaveAnReferenceUserAndPasswordSurpassLifetime": "Das Passowort ist abgelaufen und es wurde kein Referenzbenutzer für diesen Account eingetrage. Das Passwort muss von einem Admin neu gesetzt werden.",
      "username_or_password_is_incorrect": "Benutzername oder Passwort nicht korrekt!",
      "validator": {
        "email": "E-Mail ungültig",
        "firstname": "Vorname ungültig",
        "lastname": "Nachname ungültig",
        "roleSites": "Berechtigungen ungültig",
        "siteId": "Standort ungültig",
        "startUpBoardId": "Startboard ungültig",
        "startUpSiteId": "Start Standort ungültig",
        "username": "Benutzername ungültig"
      },
      "virtualDatapointsCannotBeUpdated": "Virtuelle Datenpunkte können nicht manuell geändert werden."
    },
    "success": {
      "activeNewsCreated": "Aktive News wurde erstellt",
      "archivNewsCreated": "Archive News wurde erstellt",
      "boardSaved": "Änderungen im Board gespeichert",
      "common": "Aktion erfolgreich!",
      "emailSend": "Der Link zum Zurücksetzen Ihres Passworts wurde an die angegebene E-Mail-Adresse gesendet. Bitte folgen Sie dem darin enthaltenen Link, um ein neues Passwort festzusetzen.",
      "plannedNewsCreated": "Geplante News wurde erstellt",
      "stampChanged": "Zeitstempel erfolgreich geändert",
      "title": "Erfolgreich!"
    }
  }
}

{
  "Active": "Aktiv",
  "Archiv": "Archiv",
  "Planned": "Geplant",
  "header": "Teamübersicht",
  "listName": "teams",
  "options": {
    "id": "ID",
    "name": "Teamname",
    "shiftplan": "Schichtplan",
    "start": "Start",
    "team": "Standort"
  }
}

{
  "cancel": "Abbrechen",
  "createPSC": "A3/A5 anlegen",
  "createPSCHeadline": "A3/A5 anlegen",
  "locationPlaceholder": "An welchem Standort ist das Problem aufgetreten?",
  "memberPlaceholder": "Welcher Mitarbeiter soll dem Team hinzugefügt werden?",
  "place": "Ort",
  "problem": "Problem",
  "problemPlaceholder": "Welches Problem ist aufgetreten?",
  "teamLeader": "Teamleiter",
  "teamLeaderPlaceholder": "Wer leitet das Problemlösungsteam?",
  "teamMembers": "Teammitglieder",
  "type": "Typ",
  "typePlaceholder": "Welcher Problemlösungstyp soll erstellt werden?"
}

{
  "align": "Labelausrichtung",
  "axis": "Achse",
  "color": "Farbe",
  "label": "Label",
  "line": "Linie",
  "pos": "Position",
  "posText": "Textposition",
  "verticalAlign": "Vertikale Labelausrichtung"
}

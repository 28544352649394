{
  "AnalyzeChangeRequest": "Änderungsantrag analysieren",
  "AppStore": "Audit App",
  "AssignmentLayoutOverview": "Maschinenbelegung",
  "AuditDetail": "Audit detailliert",
  "AuditOverview": "Audits",
  "AuditResult": "Audit Ergebnisse",
  "BIS": "BIS",
  "CMDetail": "Kunde",
  "CMList": "Kundenverwaltung",
  "ChangeRequestOverviewAll": "Gesamtübersicht der Änderungsanträge",
  "ChangeRequestOverviewClosed": "Liste der geschlossenen Änderungsanträge",
  "ChangeRequestOverviewOpened": "Liste der geöffneten Änderungsanträge",
  "ChangeRequestOverviewReturned": "Liste der zurückgesandten Änderungsanträge",
  "ChangeRequestOverviewSubmittedEdited": "Liste der eingereichten Änderungsanträge",
  "ChangeRequestReview": "Änderungsantrag Überprüfung",
  "Changelog": "Versionshinweise",
  "ChangelogNew": "Versionshinweise",
  "ChartLayouts": "LISAChart Layouts",
  "CompleteChangeRequest": "Änderungsantrag | Vervollständigen",
  "CostCenters": "Kostenstellen",
  "CreateChangeRequest": "Änderungsantrag anlegen",
  "CreateCostCenter": "Kostenstelle anlegen",
  "CreateIHConfig": "IH-Config",
  "CreateIncomingInstance": "Eingehende Verbindung anlegen",
  "CreateNews": "News anlegen",
  "CreateOutgoingInstance": "Ausgehende Verbindung anlegen",
  "CreateSiteConfig": "Zeiterfassung - Standorteinstellung",
  "CreateTechnicalPlace": "Technischen Platz anlegen",
  "CreateWorkflow": "Workflow anlegen",
  "EABShorthandList": "Vordefinierte Aktionen",
  "EditNews": "News ändern",
  "ErrorsOverview": "Fehler | Übersicht",
  "EventActionBoard": "Problemlösungsboard",
  "EventActionBoardNew": "Problemlösungsboard",
  "HLPCategory": "HLP - Kategorie bearbeiten",
  "HLPCategoryList": "HLP Kategorien",
  "HLPConfig": "HLP - Konfiguration",
  "HLPExport": "HLP Ideenexport",
  "Holidays": "Zeiterfassung - Urlaube",
  "HolidaysOverview": "Zeiterfassung - Urlaubsübersicht",
  "IHConfigs": "IH-Configs",
  "IHEquipment": "Equipment",
  "IHEquipments": "Equipments",
  "IHMaintenanceSchedule": "Wartungsplan",
  "IHWeakPointAnalysis": "Schwachstellenanalyse",
  "IncomingInstanceList": "Eingehende Verbindungen",
  "KPIBoard": "KPI-Board",
  "KPIBoardNew": "KPI-Board",
  "MMMailDistribution": "E-Mail-Verteiler",
  "MMOrderOverview": "Wareneingang buchen",
  "MMStockOverview": "Bestandsübersicht",
  "MMStorageFill": "Lagerbestände füllen",
  "NewsDetail": "News",
  "NewsTabs": "News",
  "OutgoingInstanceList": "Ausgehende Verbindungen",
  "PSCArchive": "PSC Archiv",
  "PSCCreate": "PSC A3/A5 anlegen",
  "PSCCreateKaizen": "PSC KAIZEN anlegen",
  "PSCEdit": "PSC bearbeiten",
  "PSCOverviewA3A5": "PSC A3/A5",
  "PSCOverviewKaizen": "PSC KAIZEN",
  "RegisterError": "Fehler melden",
  "Reporting": "Reports",
  "ReviewChangeRequest": "Änderungsantrag Überprüfung",
  "ReviewError": "Fehler Überprüfen",
  "SettingsOverview": "HLP - Konfiguration",
  "SiteConfigs": "Zeiterfassung - Standorteinstellungen",
  "StampOverview": "Zeiterfassung - Übersicht",
  "StampUserList": "Zeiterfassung - Benutzerliste",
  "Tabnews": "News",
  "Taskboard": "Problemlösungsboard",
  "TechnicalPlaces": "Technische Plätze",
  "UpdateCostCenter": "Kostenstelle ändern",
  "UpdateIHConfig": "IH-Config",
  "UpdateIncomingInstance": "Eingehende Verbindung ändern",
  "UpdateOutgoingInstance": "Ausgehende Verbindung ändern",
  "UpdateSiteConfig": "Zeiterfassung - Standorteinstellung",
  "UpdateTechnicalPlace": "Technischen Platz ändern",
  "UpdateWorkflow": "Workflow ändern",
  "User": "Benutzer",
  "UserShift": "Zeiterfassung",
  "UserShiftOverview": "Zeiterfassung",
  "UserShiftTeamPlanning": "Zeiterfassung - Team",
  "UserShiftTeamPlanningOverview": "Zeiterfassung - Teamplan Übersicht",
  "Vacations": "Zeiterfassung - Urlaube",
  "WorkflowOverview": "Workflow Übersicht",
  "YearlyStampOverview": "Zeiterfassung - Jahresübersicht",
  "board": "KPI-Board",
  "boardManagement": "Board Management",
  "boardTemplate": "Boardvorlage",
  "boardTemplates": "Board-Vorlagen",
  "changepassword": "Passwort ändern",
  "changepassword_with_username": "Passwort ändern",
  "chartLayoutOverview": "Chart-Vorlagen",
  "competenceMatrix": "Qualifikationsmatrix",
  "consumMaterial": "Material verbrauchen",
  "coreCompetenceEditor": "Stammkompetenzen",
  "createChartLayout": "Chart-Vorlagen anlegen",
  "dashboard": "Dashboard",
  "dashlists": "Strichliste",
  "datapointType": "Datenpunkttyp",
  "datapointTypes": "Datenpunkttypen",
  "department": "Abteilung",
  "departments": "Abteilungen",
  "documents": "Dokumente",
  "flag": "Flag",
  "flags": "Flags",
  "folder": "Ordnerverwaltung",
  "generalSettings": "Systemeinstellungen",
  "ih": "Instandhaltung",
  "ihMail": "E-Mail-Verteiler für die Instandhaltung",
  "ishikawaCreate": "Ishikawa-Diagramm anlegen",
  "ishikawaEdit": "Ishikawa-Diagramm bearbeiten",
  "ishikawaOverview": "Ishikawa-Diagramm",
  "loggedInStamp": "Ein- und Ausstempeln",
  "login": "Anmelden",
  "mailDistribution": "E-Mail-Verteiler",
  "mailDistributions": "E-Mail-Verteilerliste",
  "newsDetail": "News",
  "orbanForum": "Orban-Forum",
  "orderMaterial": "Material anfordern",
  "overviewCommoditCodes": "Warengruppen",
  "overviewContactPerson": "Kontaktpersonen",
  "overviewMaterial": "Materialien",
  "overviewVendor": "Lieferanten",
  "pageNotFound": "Seite nicht gefunden",
  "password": "Passwort vergessen",
  "ppsValue": "Vorgabewerte Target PPS",
  "redirect": "Weiterleitung",
  "rfid": "RFID",
  "role": "Rolle bearbeiten",
  "roleAssignment": "Rollen zuweisen",
  "roles": "Rollen",
  "settings": "Einstellungen",
  "settingsColor": "Farbeinstellungen",
  "shiftSchedule": "Schichtplan",
  "shiftSchedules": "Schichtpläne",
  "site": "Standort",
  "siteConfigs": "Standorteinstellungen",
  "sites": "Standorte",
  "stamp": "Ein- und Ausstempeln",
  "supplyPoints": "Versorgungspunkte",
  "target": "Zielwerte",
  "timeStampOverviewSubmittedSheets": "Zeiterfassung - Eingereichte Übersicht",
  "timeStampVacationPlanner": "Urlaubsplaner",
  "transportOrderList": "Transportaufträge",
  "transportRequestList": "Transportanfragen",
  "updateChartLayout": "Chart-Vorlagen ändern",
  "users": "Benutzerliste",
  "viewStorageLocations": "Lagerplatz",
  "viewWarehouses": "Lager"
}

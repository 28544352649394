{
  "createNewFlag": "Neue Flag anlegen",
  "flag": "Flag",
  "header": "Flags",
  "options": {
    "id": "ID",
    "name": "Flag",
    "systemFlag": "System Flag",
    "type": "Typ"
  },
  "type": {
    "mailDistribution": "E-Mail-Verteiler",
    "system": "System Flag",
    "userFlag": "Benutzer Flag"
  }
}

{
  "DDHeadline": "Suchoptionen",
  "buttonOptionsText": "Optionen",
  "fuzzySearchTitle": "Unscharfe Suche",
  "inputSearchPlaceholder": "Suchbegriff eingeben",
  "inputSearchPlaceholderIndividual": "Suchbegriff für {name} eingeben...",
  "off": "aus",
  "on": "an",
  "optionsText": "Optionen",
  "outputSearchText": "{name}: {searchLength} von {length} vorhanden",
  "outputSearchText2": "{length} {name} vorhanden",
  "searchFor": "Suche nach",
  "selectAll": "Alle selektieren",
  "selectNone": "Alle deselektieren"
}

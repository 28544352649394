{
  "column": {
    "available": "Verfügbar:",
    "reserved": "Reserviert:",
    "storageLocation": "Lagerplatz",
    "value": "Bestand:",
    "warehouse": "Lager"
  },
  "materialNotAvailable": "Das Material ist an diesem Lagerplatz nicht vorhanden",
  "tableField": {
    "createdAt": "Erstellt am",
    "material": "Material",
    "quantity": "Anzahl",
    "source": "Ursprungslager",
    "status": "Status",
    "target": "Ziellager"
  }
}

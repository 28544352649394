{
  "datetime": "dd.MM.yyyy, HH:mm",
  "datetimeLong": "dd.MM.yyyy, HH:mm:ss",
  "datetimeWithOutMinute": "dd.MM.yyyy, HH:mm",
  "date": "dd.MM.yyyy",
  "hourOnly": "HH",
  "time": "HH:mm:ss",
  "timeShort": "HH:mm",
  "yearWithMonth2Digits": "MM.yyyy",
  "years": "yyyy",
  "week": "'KW' WW' ,'yyyy"
}

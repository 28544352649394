import { ActionTree, GetterTree, MutationTree } from "vuex";

import $api from "../api/api";

const localState = {
  datapoints: {} as { [key: number]: { [key: number]: { [key: number]: main.Datapoint } } },
  /*
    datapoints: {
      "typeId": {
        "costCenterId": {
          "datapointId": datapoint
        }
      }
    },
    */
  datapointTypes: {} as { [keys: number]: main.DatapointType },
  targets: {} as { [keys: number]: main.Target },
};

export const getters: GetterTree<typeof localState, any> = {
  for: (state) => (costCenterId: number, typeId: number) => {
    const datapointObject = state.datapoints?.[typeId]?.[costCenterId];
    if (!datapointObject) {
      return [];
    }
    return Object.values(datapointObject);
  },
  typeInformation: (state) => (typeId: number) => {
    return Object.values(state.datapointTypes).find((type) => type.id === typeId);
  },
  targets: (state) => (costCenterId: number, typeId: number) => {
    return Object.values(state.targets).filter((target) => {
      return target.costCenterId === costCenterId && target.typeId === typeId;
    });
  },
};

const actions: ActionTree<typeof localState, any> = {
  async fetch(context, payload) {
    const data = await $api.datapoints.fetch(payload);
    context.commit("set", { data });
  },
  async create(context, datapoint) {
    const response = await $api.datapoints.upsert(datapoint);
    context.commit("add", response);
    return response;
  },
  async upsert(context, datapoint) {
    const response = await $api.datapoints.upsert(datapoint);
    context.commit("add", response);
  },
  async delete(context, datapoint) {
    const response = await $api.datapoints.delete(datapoint);
    context.commit("delete", response);
  },
  async upsertWithIssue(context, createWithIssue) {
    const response = await $api.datapoints.upsertWithIssue({
      datapoint: createWithIssue.datapoint,
      issue: createWithIssue.issue,
    });
    if (!createWithIssue.issue.id) {
      if (response.issue.tasks == null) {
        response.issue.tasks = [];
      }
      createWithIssue.store.dispatch("issues/addIssueLocally", response.issue);
    }
    context.commit("add", response.datapoint);
    return response;
  },
  async loadBoard(context, board) {
    const data = await $api.datapoints.getForBoard(board.id);
    context.commit("set", { data });
  },
};

const mutations: MutationTree<typeof localState> = {
  set(state, { data }) {
    if (data.types) {
      data.types.forEach((element: main.Datapoint) => {
        state.datapointTypes[element.id] = element;
      });
    }
    if (data.datapoints) {
      data.datapoints.forEach((element: main.Datapoint) => {
        if (element.costCenterId == null) {
          return;
        }
        if (!state.datapoints[element.typeId]) {
          state.datapoints[element.typeId] = {};
        }
        if (!state.datapoints[element.typeId][element.costCenterId]) {
          state.datapoints[element.typeId][element.costCenterId] = {};
        }
        state.datapoints[element.typeId][element.costCenterId][element.id] = element;
      });
    }
    if (data.targets) {
      data.targets.forEach((element: main.Datapoint) => {
        state.targets[element.id] = element;
      });
    }
    if (data.datapointType) {
      if (Array.isArray(data.datapointType)) {
        data.datapointTypes.forEach((element: main.Datapoint) => {
          state.datapointTypes[element.id] = element;
        });
      } else {
        state.datapointTypes[data.datapointType.id] = data.datapointType;
      }
    }
  },
  add(state, datapoint) {
    if (!state.datapoints[datapoint.typeId]) {
      state.datapoints[datapoint.typeId] = {};
    }
    if (!state.datapoints[datapoint.typeId][datapoint.costCenterId]) {
      state.datapoints[datapoint.typeId][datapoint.costCenterId] = {};
    }
    state.datapoints[datapoint.typeId][datapoint.costCenterId][datapoint.id] = datapoint;
  },
  delete(state, datapoint) {
    datapoint.ids.forEach((id: number) => {
      // @ts-expect-error not typed correctly. kill feedback and trash it
      delete state.datapoints[datapoint.typeId][datapoint.costCenterId].id;
    });
  },
};

export default {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};

{
  "dayline": "{days} Tage Linie",
  "leading": "KW {week} {year}",
  "legend": {
    "dayLine": "60 Tage Linie",
    "difference": "Abweichung",
    "valueDefault": "Vorgabe",
    "valueIS": "IST",
    "valueTarget": "Ziel"
  },
  "mponents": {
    "lisaChart": {
      "forms": {
        "deviation": {
          "label": "Abweichung in % (+/-):"
        }
      }
    }
  },
  "ppsTableEditor": {
    "forms": {
      "inputDay": {
        "label": "Tag:"
      },
      "inputMonth": {
        "label": "Monat"
      },
      "inputShift": {
        "label": "Schicht:"
      },
      "inputType": {
        "label": "Typ auswählen",
        "options": {
          "value1": "Ist-Wert",
          "value2": "Vorgabewert"
        }
      },
      "inputValue": {
        "label": "Wert:"
      },
      "inputYear": {
        "label": "Jahr"
      }
    },
    "title": "PPS werte pflegen"
  },
  "targetTableEditor": {
    "title": "Werte pflegen"
  }
}

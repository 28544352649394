<template>
  <MultiSelect
    ref="multiselect"
    v-model="selected"
    track-by="id"
    filter
    option-label="name"
    class="w-100"
    display="chip"
    :options="options"
    @change="toggle"
  >
    <template #option="{ option }"> {{ option?.name }} [{{ option?.count }}x] </template>
  </MultiSelect>
</template>

<script lang="ts">
import MultiSelect from "primevue/multiselect";
import { computed, defineComponent, ref } from "vue";
import FlagsFilter from "../filterImplementations/FlagsFilter";
import refilterEventsWithout from "./RefilterEventsWithout";

export default defineComponent({
  name: "FlagFilterUI",
  components: {
    MultiSelect,
  },
  emits: ["hide"],
  setup(props) {
    const filteredEvents = refilterEventsWithout((filter) => !(filter instanceof FlagsFilter));
    const multiselect = ref();
    const options = computed(() => {
      const flags: Record<number, { name: string; count: number }> =
        filteredEvents.value.activeEvents
          .flatMap((event) => {
            if ("flags" in event) {
              return event.flags;
            }
            return [];
          })
          .reduce((prev, curr) => {
            if (curr) {
              if (prev[curr.id] == null) {
                prev[curr.id] = {
                  name: curr.name,
                  count: 1,
                };
              } else {
                prev[curr.id].count += 1;
              }
            }
            return prev;
          }, {} as Record<number, { name: string; count: number }>);

      return Object.entries(flags)
        .map(([id, attr]) => ({
          id: Number(id),
          name: attr.name,
          count: attr.count,
        }))
        .sort((lhs, rhs) => {
          return lhs.name.localeCompare(rhs.name);
        });
    });
    const selected = ref<{ id: number; name: string }[]>([]);

    function toggle() {
      multiselect.value?.hide?.();
    }
    function getFilter() {
      return new FlagsFilter(selected.value.map(({ id }) => id));
    }

    function applyFilter(filter: FlagsFilter) {
      selected.value = filter.flags.map((id) => ({
        id,
        name: options.value.find((v) => v.id === id)?.name ?? "?",
      }));
    }

    return {
      selected,
      multiselect,
      toggle,
      options,
      getFilter,
      applyFilter,
    };
  },
});
</script>

<style lang="scss" scoped></style>

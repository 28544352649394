{
  "addQuestion": "Neue Frage hinzufügen",
  "configError": "Konfigurationsfehler",
  "configSites": "Seiten konfigurieren",
  "configTargetMessage": "Ziel konfigurieren",
  "createTask": "Soll beim nicht Erreichen des Zielwertes ein Problem angelegt werden?",
  "dataTypeIdLabel": "[id: {id}]",
  "datapoint": "Datentyp",
  "datapointPlaceholder": "Datenpunkt auswählen",
  "goal": "Ziel",
  "goalPlaceholder": "Zielwert",
  "maxQuestions": "Maximale Anzahl an Fragen erreicht",
  "noQuestions": "Aktuell sind keine Fragen vorhanden",
  "pageHeader": "Seite {page}",
  "pageSelect": {
    "Question": "Frage"
  },
  "pageSelectModalTitle": "Seiten konfigurieren",
  "pause": "Pause",
  "question": "Frage",
  "questionCounterTitle": "Feedback Frage ",
  "questionTableOptionDataType": "Datenpunkttyp",
  "questionTableOptionOperator": "Operator",
  "questionTableOptionQuestion": "Frage",
  "questionTableOptionValue": "Wert",
  "shiftPlaceholder": "Schicht auswählen",
  "shiftRelation": "Schichtbezug?",
  "targetMax": "Max:",
  "targetMin": "Min:",
  "targetSettingsLabel": "Zielwert Eigenschaften:",
  "targetSuffix": "Ziel"
}

{
  "CreateMailDistribution": "E-Mail-Verteiler anlegen",
  "IHFailureNotice": "Störmeldung",
  "IHMaintenance": "Wartung",
  "IHMaintenanceOrderCreated": "IH-Auftrag ID {id} wurde erstellt.",
  "IHMaintenanceOrderCreatedPrefix": "IH-Auftrag ID ",
  "IHMaintenanceOrderCreatedSuffix": " wurde erstellt.",
  "IHMaintenanceOrderLinkList": {
    "header": "IH-Aufträge"
  },
  "IHOrder": "Instandhaltungsauftrag",
  "IHOrderHeadline": "Neuen Instandhaltungsauftrag anlegen",
  "IHOrderHeadlineChange": "Instandhaltungsauftrag ändern",
  "IHWeakPointAnalysis": " Schwachstellenanalyse",
  "MailDistributor": "E-Mail-Verteiler",
  "UpdateMailDistribution": "E-Mail-Verteiler ändern",
  "all": "Alle",
  "archiv": "Archiviert",
  "bis": "BIS",
  "bisWarning": "Die Planungsgruppe und der oberste Technische Platz müssen gesetzt werden, damit BIS aktiv ist!",
  "board": "Board",
  "cantArchiveFailureNotice": "Kann nur archiviert werden, wenn das Enddatum gepflegt ist!",
  "chooseTechnicalPlace": "Bitte technische Plätze auswählen",
  "commentText": "An Instandhaltung weitergeitet",
  "configuration": "Konfiguration",
  "createEquipment": "Equipment anlegen",
  "createEquipments": "Equipments anlegen",
  "createIHConfig": "IH-Config anlegen",
  "createIHOrder": "Instandhaltungsauftrag anlegen",
  "createTechnicalPlace": "Technischer Platz anlegen",
  "day": "T",
  "deleteEquipment": "Equipment löschen",
  "deleteTechnicalPlace": "Technischer Platz löschen",
  "description": "Beschreibung",
  "endDate": "Enddatum",
  "equipment": "Equipment",
  "equipments": "Equipments",
  "failure": "Störung",
  "faultCost": "Störungskosten",
  "faultCostWithUnit": "Störungskosten [€]",
  "faultCount": "Störungsanzahl",
  "faultTime": "Störungszeit",
  "faultTimeWithUnit": "Störungszeit [Std.]",
  "forwardToIH": "An die Instandhaltung weiterleiten",
  "forwardToIHText": "Hierduch wird der Kommentar in 'An Istandhaltung weitergeleitet' geändert. Der eingegebene Kommentar geht verloren.",
  "hideResponsible": "IH-Leiter in Instandhaltungsauftrag verbergen",
  "hideResponsibleModal": "Instandhaltung",
  "hour": "Std.",
  "hr": "hr",
  "ih": {
    "sites": "Standorte"
  },
  "ihConfig": "IH-Config",
  "ihConfigs": "IH - Konfiguration",
  "ihOrder": "Instandhaltungsauftrag",
  "maintenance": "Wartung",
  "maintenanceSchedule": "Wartungsplan",
  "manager": "Verantwortlicher",
  "mntEquipments": "Equipments",
  "month": "M",
  "name": "Name",
  "noData": "Keine Daten zum Anzeigen vorhanden",
  "notNull": "Mit Störungen",
  "open": "offen",
  "order": "Auftrag",
  "owner": "Besitzer",
  "period": "Zeitraum",
  "planGroup": "Planungsgruppe:",
  "preventDeleteTechnicalPlace": "Der technische Platz kann nicht gelöscht werden, da dieser entweder einen Standort repräsentiert oder Kindknoten hat!",
  "repTimeNoRange": "Reperaturzeitraum durch Tag ersetzen",
  "responsible": "IH-Leiter:",
  "responsibleAsInitial": "IH-Leiter zu Beginn in Instandhaltungsauftrag eintragen",
  "responsibleIHOrder": "Verantwortlicher",
  "saveChange": "Änderungen speichern",
  "saveSettings": "Standardeinstellungen speichern",
  "selectBoard": "Bitte Board auswählen",
  "showCost": "Kosten anzeigen",
  "showPriority": "Priorität anzeigen",
  "site": "Standort:",
  "siteTable": "Standort",
  "sites": "Standorte",
  "startDate": "Startdatum",
  "status": "Status",
  "task": "Automatische Aufgabe bei Störmeldungen anlegen",
  "teamMember": "Mail-Verteiler Mitglieder:",
  "teamMembersWarning": "Mindestens einen Benutzer muss ausgewählt werden, damit der E-Mail-Verteiler aktiv ist!",
  "technicalPlace": "Technischer Platz",
  "technicalPlaceMaintained": "Wurden die Technischen Plätze gepflegt?",
  "technicalPlaceMaintainedWarning": "Es sind Technische Plätze auf mindestens vier Ebenen erforderlich, damit alle Funktionen der Instandhaltung genutzt werden können.",
  "technicalPlaces": "Technische Plätze",
  "title": "Bezeichnung",
  "tpm": "TPM",
  "updateIHConfig": "IH-Konfiguration ändern",
  "upperTechincalPlace": "Oberster Technischer Platz:",
  "warningIHConfig": "Es muss eine IH-Konfiguration angelegt sein, damit etwas angezeigt wird!",
  "warningTP": "Es müssen vier Level an Technischen Plätzen angelegt sein, damit etwas angezeigt wird!",
  "weakPointAnalysis": "Schwachstellenanalyse ({sum}{unit})",
  "week": "KW",
  "year": "J",
  "yearToDate": "YTD"
}

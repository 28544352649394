{
  "actionsWithChanges": "Aktionen mit Änderungen",
  "all": "Zurücksetzen",
  "eventsWithChanges": "Ereignisse mit Änderungen",
  "myActions": "Meine Aktionen",
  "onlyExpired": "Nur Abgelaufene",
  "resetFilter": "Filter zurücksetzen",
  "withActions": "Mit Aktionen",
  "withoutActions": "Ohne Aktionen"
}

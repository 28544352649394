import { Ref, InjectionKey, VueElement } from "vue";
import { DateTime } from "luxon";
import {
  Action,
  ActionListActionFieldsFragment,
  Event,
  EventListEventFieldsFragment,
  IEventOption,
} from "@/graphql";
import { SerializedFilter } from "./FilterSerializer";

export type UserId = number;

export enum FilterEntity {
  EVENT,
  ACTION,
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export class Filter {
  isNegated?: boolean;

  static UI: VueElement;

  static Badge: VueElement;

  serialize?(): SerializedFilter["values"];

  static deserialize?(values: SerializedFilter["values"]): Filter;
}

export interface EventFilter extends Filter {
  isEventActive: (event: EventListEventFieldsFragment, boardId?: number) => boolean;
}

export interface ActionFilter extends Filter {
  isActionActive: (action: ActionListActionFieldsFragment) => boolean;
}

export type EventFilterFunction<T extends EventFilter = EventFilter> = (
  filter: T,
  event: Event,
) => boolean;
export type ActionFilterFunction<T extends ActionFilter = ActionFilter> = (
  filter: T,
  action: Action,
) => boolean;

export type EventFilters = {
  updatedAt: DateTime;
  eventFilters: EventFilter[];
};

export type ActionFilters = {
  updatedAt: DateTime;
  actionFilters: ActionFilter[];
};

export const EventFilterInjectionKey = Symbol("EventFilterInjectionKey") as InjectionKey<
  Ref<EventFilters>
>;
export const ActionFilterInjectionKey = Symbol("ActionFilterInjectionKey") as InjectionKey<
  Ref<ActionFilters>
>;

export const JumpToEventInjectionKey = Symbol("JumpToEventInjectionKey") as InjectionKey<
  (eventToJumpTo: IEventOption) => void
>;

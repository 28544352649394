<template>
  <div>
    <span>{{ $t("components.eventAction.filter.ui.Overdue.text") }}</span>
    <div class="flex flex-auto">
      <InputNumber v-model.number="numberOfHours" style="font-size: inherit" />

      <Dropdown
        v-model.number="timeMultiplier"
        :options="multiplierOptions"
        class="w-100"
        option-label="label"
        option-value="value"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import OverdueFilter from "../filterImplementations/OverdueFilter";

export default defineComponent({
  name: "OverdueFilterUI",
  components: { Dropdown, InputNumber },
  setup() {
    const { t } = useI18n();
    const numberOfHours = ref(14);
    const timeMultiplier = ref<1 | 24>(24);
    const multiplierOptions = computed(() => [
      {
        label: t("global.hours"),
        value: 1,
      },
      {
        label: t("global.days"),
        value: 24,
      },
    ]);
    return {
      multiplierOptions,
      getFilter() {
        return new OverdueFilter(numberOfHours.value * timeMultiplier.value);
      },
      applyFilter(filter: OverdueFilter) {
        if (filter.definitionOfOverdueInHours % 24 === 0) {
          timeMultiplier.value = 24;
          numberOfHours.value = filter.definitionOfOverdueInHours / 24;
        } else {
          timeMultiplier.value = 1;
          numberOfHours.value = filter.definitionOfOverdueInHours;
        }
      },
      numberOfHours,
      timeMultiplier,
    };
  },
});
</script>

<style lang="scss" scoped>
.border-fix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

import { ConfirmationOptions } from "primevue/confirmationoptions";

export function confirmModal(options: Partial<ConfirmationOptions> & { showDeleteText?: boolean }) {
  window.$confirm?.require({
    message: options.showDeleteText
      ? window.$i18n?.t("components.ConfirmationModal.deleteText")
      : window.$i18n?.t("global.cancelMsgBox"),
    header: window.$i18n?.t("components.ConfirmationModal.title"),
    rejectLabel: window.$i18n?.t("components.ConfirmationModal.rejectLabel"),
    acceptLabel: options.showDeleteText
      ? window.$i18n?.t("global.delete")
      : window.$i18n?.t("global.next"),
    ...options,
  });
}

{
  "custom": "Benutzerdefiniert",
  "customCharts": "Benutzerdefiniert",
  "delete": "Layout kann nur gelöscht werden, wenn es in keiner Kachel verwendet wird.",
  "header": "LisaChart-Vorlagen",
  "losePreDefined": "Einfache Vorlage",
  "modalOk": "Weiter zum Vorlageneditor",
  "modalTitle": "Chart-Vorlage anlegen",
  "preDefinedCharts": "Vordefinierte Vorlagen",
  "type": "Typ",
  "usage": "Verwendung"
}

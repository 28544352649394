{
  "applicant": "Antragsteller",
  "cancelButton": "Abbrechen",
  "createTaskButton": "Aufgabe anlegen",
  "discardChanges": "Änderungen verwerfen",
  "frameBreadcrumbs": "Fehler / Überprüfung",
  "goToPLB": "Zu PLB wechseln",
  "loading": "Wird geladen...",
  "manager": "Manager",
  "nextButton": "Weiter",
  "noCompletelyButton": "Nicht Vollständig",
  "returnButton": "Zurückschicken",
  "saveChangesButton": "Änderungen speichern",
  "title": "Fehler überprüfen durch"
}

{
  "area": "Fläche",
  "arearange": "Flächenbereich",
  "areaspline": "Splinefläche",
  "areasplinerange": "Splineflächenbereich",
  "column": "Säule",
  "columnrange": "Säulenbereich",
  "globalType": "Global",
  "line": "Linie",
  "normalType": "Einfache",
  "pie": "Torte",
  "rangeType": "Bereiche",
  "spline": "Spline"
}

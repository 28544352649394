<template>
  <RFIDScanModal
    v-if="rfidCodeData != null && rfidModal"
    v-model="rfidModal"
    :data="rfidCodeData"
    :login="{ code: rfidLastCode }"
    @update-table="updateTable"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import scanner from "onscan.js";
import { TCGetStampStateForRfidQuery, useTCGetStampStateForRfidQuery } from "@/graphql";
import RFIDScanModal from "./RFIDScanModal.vue";

export default defineComponent({
  name: "RFIDScanComponent",
  components: { RFIDScanModal },
  setup(props) {
    const rfidDisabled = ref(false);

    const rfidCode = ref("");
    const rfidModal = ref(false);
    const rfidCodeData = ref<TCGetStampStateForRfidQuery["TCGetStampStateForRfid"] | null>(null);
    const rfidLastCode = ref("");

    function disableRFID() {
      rfidDisabled.value = true;
      rfidModal.value = false;
      rfidCodeData.value = null;
      rfidLastCode.value = "";
    }

    function enableRFID() {
      rfidModal.value = false;
      rfidCodeData.value = null;
      rfidLastCode.value = "";
      rfidDisabled.value = false;
    }

    const { onResult, onError } = useTCGetStampStateForRfidQuery(
      () => ({
        code: rfidCode.value,
      }),
      () => ({
        enabled: rfidCode.value.length > 0,
        fetchPolicy: "network-only",
      }),
    );
    onError(() => {
      rfidModal.value = false;
      rfidCodeData.value = null;
      rfidLastCode.value = "";
    });
    onResult((res) => {
      rfidLastCode.value = rfidCode.value;
      rfidCodeData.value = res.data.TCGetStampStateForRfid;
      rfidCode.value = "";
      rfidModal.value = true;
    });
    if (document.scannerDetectionData === undefined) {
      scanner.attachTo(document, {
        stopPropagation: true,
        onScan: async (scanCode: string) => {
          if (rfidModal.value === true) {
            return;
          }
          if (scanCode.split("LISAPP").length === 4) {
            rfidCode.value = scanCode;
            if (rfidDisabled.value === true) {
              rfidCode.value = "";
              return;
            }
            if (document.activeElement) {
              // if a User scanned while in focus of a input field it tries to remove the code after the input finished;
              const el = document.activeElement as Element & {
                value: string;
              };
              if (el.value && el.value.includes(scanCode)) {
                el.value = el.value.replace(scanCode, "");
              }
            }
          }
        },
      });
    }

    function updateTable(data: TCGetStampStateForRfidQuery["TCGetStampStateForRfid"]) {
      rfidCodeData.value = data;
    }
    return {
      updateTable,
      rfidCodeData,
      rfidModal,
      rfidLastCode,
      disableRFID,
      enableRFID,
      rfidDisabled,
    };
  },
});
</script>

<style lang="scss" scoped></style>

export default {
  endpoints: {
    updateColors: {
      route: "updateColors",
      method: "POST",
    },
    getColors: {
      route: "colors",
      method: "GET",
    },
    getInlineEditActionsAnonym: {
      route: "inlineEditActionsAnonym",
      method: "GET",
    },
    getModuleFolders: {
      route: "moduleFolder",
      method: "GET",
    },
    getGeneralSettings: {
      route: "generalSystemSettings",
      method: "GET",
    },
    getGeneralSettingsByKey: {
      route: "generalSystemSettingsByKey/:id",
      method: "GET",
    },
    saveGeneralSettings: {
      route: "generalSystemSettings",
      method: "PUT",
    },
    getSlider: {
      route: "slider",
      method: "GET",
    },
  },
};

{
  "discoveredAt": "Entdeckt am",
  "discoveredAtPlaceholder": "Wann wurde das Problem entdeckt?",
  "discoveredFrom": "Entdeckt durch",
  "discoveredFromPlaceholder": "Benutzer auswählen",
  "goal": "Ziele",
  "goalPlaceholder": "Welche Ziele werden verfolgt?",
  "location": "Standort",
  "nrDefects": "Anzahl Defekte",
  "nrDefectsPlaceholder": "Wie viele Defekte sind aufgetreten?",
  "place": "Bereich/Arbeitsplatz",
  "placePlaceholder": "Wo ist das Problem entstanden?",
  "problem": "Problem/Untersuchungsbereich:",
  "problemPlaceholder": "Welches Problem ist aufgetreten?",
  "product": "Erzeugnis/Bauteil",
  "productPlaceholder": "Was ist betroffen?",
  "repeat": "Wiederholfall",
  "skizze": "Skizze/Foto",
  "title": "Fakten",
  "yes": "ja"
}

{
  "currency": {
    "label": "Währung"
  },
  "exportIdPostfix": {
    "label": "Werksnummer",
    "tooltip": "SAP Werksnummer"
  },
  "header": "Vorschlagswesen Konfiguration",
  "ideaBoardId": {
    "label": "Ideenmanagement-Board",
    "tooltip": "Ideenmanagement-Board auswählen, wenn die Idee automatisch am Problemlösungsboard des Ideenmanagement-Boards angezeigt werden soll. z.B.: wenn die Ideenkachel auf verschiedenen Boards im System verwendet wird."
  },
  "ideaBoardResponsibleId": {
    "label": "Ideenmanagementverantwortlicher",
    "tooltip": "Person, die das Ideenmanagementgremium leitet. Diese bekommt automatisch die Möglichkeit ein Veto gegen die Umsetzung der Idee einzulegen."
  },
  "ideaBoardUsers": {
    "label": "Ideenmanagementgremium",
    "tooltip": "E-Mail-Benachrichtigung an den Teilnehmerkreis des Ideenmanagementgremiums, dass eine neue Idee eigegeben wurde."
  },
  "language": {
    "label": "Sprache"
  },
  "manageBoardId": {
    "label": "Management Operation Board",
    "tooltip": "Management Operation Board auswählen, wenn die Idee automatisch am Problemlösungsboard des Management Operations Boards angezeigt werden soll."
  },
  "manageBoardResponsibleId": {
    "label": "Management-Operation-Verantwortlicher",
    "tooltip": "Person, die das Management Operations Meeting leitet. Diese bekommt automatisch die Aufgabe eine Empfehlung über die Umsetzung der Idee auszusprechen."
  },
  "manageBoardUsers": {
    "label": "Management Operation Meeting",
    "tooltip": "Mailverteiler an den Teilnehmerkreis des Management Operations Meeting, dass eine neue Idee eigegeben wurde."
  },
  "siteId": {
    "label": "Standort"
  },
  "submit": "Wollen Sie die Vorschlagswesenkonfiguration wirklich löschen?",
  "supervisorId": {
    "label": "Standortvorgesetzter",
    "tooltip": "Vorgesetzte(r) in dem Vorschlagwesen"
  },
  "vetoTime": {
    "label": "Vetozeit in Tagen",
    "tooltip": "Dauer bis wann ein Veto für eine Idee eingereicht werden kann"
  },
  "workflow": {
    "label": "Workflow",
    "tooltip": "Hier wird festgelegt, ob ein Workflow automatisch beim Anlegen einer Idee gestartet wird."
  }
}

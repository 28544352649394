{
  "knowledge": "Wissensdatenbank  (#hashtags)",
  "hasTags": "Schlagwörter für die Suche (Mit Enter bestätigen)",
  "reason": "Begründung bei nicht Notwendigkeit:",
  "reasonPlaceholder": "Warum muss die Problemlösung nicht gepflegt werden?",
  "presented": "im Steuerkreis vorgestellt",
  "finished": "durch Teamleiter fertig gemeldet",
  "finishedPlaceholder": "Markiert als fertig durch ",
  "closed": "von Führungskraft beendet",
  "closedPlaceholder": "Markiert als beendet durch"
}

{
  "alertNoDepartments": "Das können Sie mit ausreichenden Berechtigungen in den {name} ändern",
  "alertNoDepartmentsHeader": "Sie sind in diesem Standort weder Leiter einer Abteilung noch einer zugeordnet.",
  "alertNoDepartmentsUserSettingsKeyword": "Benutzereinstellungen",
  "chooseCompetenceLabel": "Kompetenz wählen",
  "columnLabelLegend": "Legende",
  "columnLabelNavigation": "Navigation",
  "competenceNotNeededLabel": "nicht erforderlich",
  "coreCompetencesDescriptionLabel": "Beschreibung",
  "coreCompetencesValueLabel": "Wert",
  "createMatrixLabel": "Qualifikationsmatrix anlegen?",
  "descriptionLabel": "Bezeichnung:",
  "editModeLabel": "Editiermodus",
  "editModeLeaveLabel": "Editiermodus verlassen",
  "entityModalCreateLabel": "Kompetenz anlegen",
  "entityModalEditLabel": "Kompetenz bearbeiten",
  "entityModalEditLabelRevisions": "Revisionen",
  "entityRevisions": {
    "change": {
      "label": "Änderung"
    },
    "datetime": {
      "label": "Zeitpunkt"
    },
    "responsible": {
      "label": "Verantwortlicher"
    },
    "revision": {
      "label": "Revision"
    }
  },
  "escriptionLabel": "Bezeichnung:",
  "fullscreenLabel": "Vollbild",
  "inputDeleteLabel": "Eingabe löschen",
  "matrixMissingLabel": "Diese Abteilung hat noch keine Qualifikationsmatrix",
  "noUsersLabel": "Keine Benutzer vorhanden!",
  "userLabel": "Benutzer"
}

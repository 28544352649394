{
  "comment": "Bemerkung",
  "confirmationTitle": "Wollen Sie den Zeitstempel setzen?",
  "createSiteConfig": "Standorteinstellungen anlegen",
  "diffTime": "Arbeitszeit:",
  "header": "Ein-/Ausstempeln",
  "lastStamp": "Ihr letzter Stempel",
  "newStamp": "Folgenden Stempel setzen:",
  "noBreak": "Pausenzeit",
  "overview": "Meine Übersicht",
  "password": "Passwort",
  "resetCounter": "Eingaben werden jetzt zurückgesetzt. | Eingaben werden in 1 Sekunde zurück gesetzt. | Eingaben werden in {resetCounter} Sekunden zurückgesetzt.",
  "responsible": "Verantwortliche Person",
  "siteConfig": "Standorteinstellung",
  "siteConfigs": "Standorteinstellungen",
  "stampIn": "Einstempeln",
  "stampOut": "Ausstempeln",
  "timestamp": "Zeit",
  "type": "Stempelart",
  "updateSiteConfig": "Standorteinstellungen ändern",
  "username": "Benutzername"
}

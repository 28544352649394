{
  "buttons": {
    "create": "Kontaktperson anlegen",
    "toVendor": "Zum Lieferant"
  },
  "firstName": {
    "label": "Vorname"
  },
  "header": "Kontaktperson",
  "landline": {
    "label": "Festnetz"
  },
  "lastName": {
    "label": "Nachname"
  },
  "mail": {
    "label": "E-Mail"
  },
  "siteId": {
    "label": "Standort"
  },
  "tel": {
    "label": "Telefon"
  },
  "vendorId": {
    "label": "Lieferant"
  }
}

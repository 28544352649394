{
  "enabledAudits": "Ausgewählte Audits",
  "groupingMethod": "Gruppierungszeitraum",
  "reducingMethod": "Gruppierungsmethode",
  "reducingMethod_avg": "Durchschnitt",
  "reducingMethod_min": "Minimal",
  "reducingMethod_max": "Maximal",
  "reducingMethod_last": "Letztes",
  "reducingMethod_first": "Erstes",
  "groupingMethod_daily": "Täglich",
  "groupingMethod_weekly": "Wöchentlich",
  "groupingMethod_monthly": "Monatlich"
}

<template>
  <div class="flex justify-content-between" @click.stop>
    <ValidationProvider v-slot="{ valid }" :modelValue="selectedType" vid="type">
      <Dropdown
        v-model="selectedType"
        :class="valid ? 'p-valid' : 'p-invalid'"
        class="mr-2"
        autoFilterFocus
        filter
        showClear
        :placeholder="$t('global.all')"
        :options="options.types"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ valid }"
      :modelValue="selectedIDs"
      :rules="{ requiredArrayEntryIfNot: selectedType != null }"
      immediate
    >
      <MultiSelect
        ref="idFilterRef"
        v-model="selectedIDs"
        class="w-100"
        display="chip"
        filter
        optionValue="value"
        optionLabel="label"
        autoFilterFocus
        :class="valid ? 'p-valid' : 'p-invalid'"
        :options="options.ids"
        @click.stop
        @filter="searchChange"
        @change="toggle"
      />
    </ValidationProvider>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import IDFilter from "../filterImplementations/IDFilter";
import { FilterEntity } from "../FilterTypes";
import { getEventTypeFromInput } from "./FilterUIHelper";
import refilterEventsWithout from "./RefilterEventsWithout";
import ValidationProvider from "@/modules/veevalidate/components/ValidationProvider.vue";

export default defineComponent({
  name: "IDFilterUI",
  components: {
    ValidationProvider,
    MultiSelect,
    Dropdown,
  },
  props: {
    mode: {
      required: true,
      type: Number as PropType<FilterEntity>,
    },
  },
  setup(props) {
    const filteredEvents = refilterEventsWithout((filter) => !(filter instanceof IDFilter));
    const selectedIDs = ref<number[]>([]);
    const selectedType = ref<string | undefined>();

    const options = computed(() => {
      const types: Set<string> = new Set();
      const ids: Set<{ value: number; label: string }> = new Set();

      switch (props.mode) {
        case FilterEntity.EVENT:
          filteredEvents.value.activeEvents.forEach((event) => {
            types.add(event.__typename);
            if (selectedType.value == null || selectedType.value === event.__typename) {
              ids.add({ value: event.id, label: event.id.toString() });
            }
          });
          break;
        case FilterEntity.ACTION:
          filteredEvents.value.activeEvents
            .flatMap((event) => event.actions)
            .forEach((action) => {
              if (action.__typename) {
                types.add(action.__typename);
              }
              if (selectedType.value == null || selectedType.value === action.__typename) {
                ids.add({ value: action.id, label: action.id.toString() });
              }
            });
          break;
        default:
          return { types: [], ids: [] };
      }
      return {
        types: Array.from(types),
        ids: Array.from(ids).sort((a, b) => a.value - b.value),
      };
    });
    const { t } = useI18n();

    function labelFormatter(text: string) {
      return t(`global.EABTypes.${text}`);
    }

    const idFilterRef = ref<InstanceType<typeof MultiSelect>>();

    function searchChange(event: { originalEvent: Event; value: string }) {
      if (event.value.length > 0) {
        const eventActionType = getEventTypeFromInput(props.mode, event.value[0]);
        if (eventActionType != null) {
          selectedType.value = eventActionType;
        }
      }
      if (idFilterRef.value != null && "filterValue" in idFilterRef.value) {
        idFilterRef.value.filterValue = event.value.replace(/\D/g, "");
      }
    }
    function toggle() {
      idFilterRef.value?.hide();
    }

    return {
      filteredEvents,
      idFilterRef,
      labelFormatter,
      searchChange,
      selectedIDs,
      selectedType,
      toggle,
      options,
      getFilter() {
        return new IDFilter(selectedIDs.value, selectedType.value);
      },
      applyFilter(filter: IDFilter) {
        selectedIDs.value = filter.ids;
        selectedType.value = filter.type;
      },
    };
  },
});
</script>

<style lang="scss" scoped></style>

import { ActionTree, MutationTree } from "vuex";

import moment from "moment";
import $api from "../api/api";

const localState = {
  issues: [] as commondata.Issue[],
  board: undefined as undefined | sqdc.Board,
};

const actions: ActionTree<typeof localState, any> = {
  clearIssues(context) {
    context.commit("clearAll");
  },
  async getIssues(context, { boardId, params = {} }) {
    const { issues, board } = await $api.issues.getIssues(boardId, params);
    context.commit("setAll", { issues, board });
  },
  async reactivateIssue(context, { issueId }) {
    await $api.issues.reactivateIssue(issueId);
    context.commit("reactivateIssue", issueId);
  },
  async reloadIssue(context, { issueId }) {
    const newIssue = await $api.issues.getIssue(issueId);
    context.commit("updateIssue", { newIssue });
  },
  async createIssue(context, { issue }) {
    const response = await $api.issues.createIssue(issue);
    response.issue.psc = [];
    if (response.issue.boards == null) {
      response.issue.boards = [];
    }
    context.commit("addIssue", { issue: response.issue });
    return response.issue;
  },
  addIssueLocally(context, issue) {
    if (issue.psc == null) {
      issue.psc = [];
    }
    if (issue.tasks == null) {
      issue.tasks = [];
    }
    if (issue.boards == null) {
      issue.boards = [];
    }
    context.commit("addIssue", { issue });
  },
  async updateIssue(context, { issue, payload }) {
    const { issue: newIssue } = await $api.issues.updateIssue(issue.id, payload);
    context.commit("updateIssue", { newIssue });
    return newIssue;
  },
  async createTask(context, { issue, task }) {
    const response = await $api.issues.createTask(issue.id, task);
    context.commit("addTaskToIssue", { task: response.task, issue });
    return response;
  },
  async updateStatus(context, { task, status, comment, commentUserId, issueId }) {
    const response = await $api.issues.updateStatus(
      task.id,
      { comment, commentUserId },
      { status },
    );
    if (issueId === 0) {
      context.commit("updateStatus", response);
    } else {
      context.commit("updateTask", { newTask: response, issueId });
    }
    return response;
  },
  async updateTask(context, { task, payload }) {
    const response = await $api.issues.updateTask(task.id, payload);
    context.commit("updateTask", {
      newTask: response.task,
      secondTask: response.secondTask,
      issueId: response.issueId,
    });
  },
  addConnection(context, issue) {
    context.commit("addConnection", issue);
  },
};

const mutations: MutationTree<typeof localState> = {
  clearAll(state) {
    state.issues = [];
  },
  addConnection(state, issueElement) {
    const element = state.issues.find((issue) => issue.id === issueElement.id);
    if (element == null) {
      return;
    }
    if (element.boards == null) {
      element.boards = [];
    }
    element.boards.push(issueElement.connection);
  },
  setAll(state, { issues, board }) {
    state.issues = issues;
    state.board = board;
  },
  addIssue(state, { issue }) {
    if (
      state.board == null ||
      state.board.id === issue.boards[0]?.id ||
      state.board.flagBoards?.some((flagBoard) => {
        return (
          flagBoard.targetBoardId === issue.boards[0]?.id &&
          issue.flags.some((flag: main.Flag) => flag.id === flagBoard.flagId)
        );
      })
    ) {
      state.issues.unshift(issue);
    }
  },
  reactivateIssue(state, id) {
    const IssueIndex = state.issues.findIndex((issue) => issue.id === id);
    if (IssueIndex > -1) {
      const issue = state.issues[IssueIndex];
      issue.deletedAt = undefined;
      issue.alreadyInArchiv = false;
      issue.archiv = false;
      issue.tasks = issue.tasks?.map((task) => {
        return {
          ...task,
          deletedAt: null,
        };
      });
      state.issues[IssueIndex] = issue;
    }
  },
  updateIssue(state, { newIssue }) {
    const oldIssue = state.issues.find((stateIssue) => stateIssue.id === newIssue.id);
    if (oldIssue) {
      oldIssue.description = newIssue.description;
      oldIssue.alreadyInArchiv = newIssue.alreadyInArchiv;
      oldIssue.assigneeId = newIssue.assigneeId;
      oldIssue.deadline = newIssue.deadline;
      oldIssue.assignee = newIssue.assignee;
      oldIssue.flags = newIssue.flags;
      oldIssue.updatedAt = moment();
    }
  },
  addTaskToIssue(state, { task, issue }) {
    const stateIssue = state.issues.find((el) => el.id === issue.id);

    if (stateIssue) {
      stateIssue.tasks?.push(task);
    }
  },
  updateStatus(state, response) {
    // TODO: Make prettier; try to avoid setting issue.tasks if task is not in issue
    state.issues.forEach((issue) => {
      issue.tasks = issue.tasks?.map((task) => {
        if (task.id === response.id) {
          return response;
        }
        return task;
      });
    });
  },
  updateTask(state, { newTask, secondTask, issueId }) {
    state.issues
      .flatMap((issue) => issue.tasks)
      .forEach((stateTask) => {
        if (stateTask == null) {
          return;
        }
        if (stateTask.id === newTask.id) {
          Object.keys(stateTask).forEach((key) => {
            // @ts-expect-error kill it please
            stateTask[key] = newTask[key];
          });
        }
      });
    const targetIssue = state.issues.find((issue) => issue.id === issueId);
    if (targetIssue != null && secondTask !== null) {
      targetIssue.tasks?.push(secondTask);
    }
  },
};

export default {
  namespaced: true,
  state: localState,
  actions,
  mutations,
};

{
  "documentTitlePlaceholder": "Kein Name vergeben",
  "creatorText": "Dieses Dokument haben Sie erstellt",
  "buttons": {
    "deleteDocument": "Dokument löschen",
    "saveChanges": "Änderungen speichern",
    "createDocument": "Dokument anlegen"
  },
  "confirmationDialog": {
    "deletion": {
      "okText": "Dokument löschen",
      "title": "Dokument '{name}' löschen?"
    }
  },
  "tooltips": {
    "createButtonTip": "Um ein Dokument zu speichern müssen alle Eingabefelder ausgefüllt werden."
  },
  "options": {
    "common": "Allgemein",
    "uploads": "Dateien"
  },
  "informationTexts": {
    "newDocument": {
      "line1": "Hinweis: Um Dateien einem Dokument zuzufügen",
      "line2": "muss dieses erst erstellt werden!"
    }
  },
  "modals": {
    "uploadModal": {
      "title": "Neuer Upload",
      "uploadLabel": "Neuer Upload",
      "inputNameLabel": "Dateiname",
      "processingText": "Die Datei wird verarbeitet!",
      "cancelButton": "Abbrechen",
      "saveButton": "Upload",
      "processingCancelButton": "Vorgang abbrechen"
    },
    "exitModal": {
      "title": "Änderungen speichern?",
      "headline": "Folgende Änderungen wurden noch nicht gespeichert:",
      "cancelButton": "Änderungen verwerfen",
      "saveButton": "Änderungen speichern"
    }
  },
  "validationTexts": {
    "inputNameValidation": "Bitte mindestens 5 Zeichen eingeben!"
  },
  "detailTabs": {
    "common": {
      "title": "Allgemein",
      "subTitle": "Dokumenteneinstellungen"
    },
    "uploads": {
      "title": "Dateien",
      "subTitle": "Verwaltung der Dateien"
    }
  },
  "objectChanges": {
    "title": "Dokumententitel",
    "description": "Beschreibung",
    "site": "Standort",
    "folder": "Speicherort"
  }
}

{
  "city": {
    "label": "Stadt"
  },
  "country": {
    "label": "Land"
  },
  "header": "Lieferant",
  "name": {
    "label": "Name"
  },
  "identifier": {
    "label": "Lieferanten Nr."
  },
  "street": {
    "label": "Straße"
  },
  "zipCode": {
    "label": "Postleitzahl"
  }
}

import { DateTime } from "luxon";
import { ActionListActionFieldsFragment, TaskStatus } from "@/graphql";
import { ActionFilter } from "../FilterTypes";
import OverdueFilterUI from "../ui/OverdueFilterUI.vue";
import OverdueFilterBadge from "../ui/badges/OverdueFilterBadge.vue";

export default class OverdueFilter implements ActionFilter {
  constructor(
    public readonly definitionOfOverdueInHours: number,
    public readonly isNegated?: boolean,
  ) {}

  static readonly Badge = OverdueFilterBadge;

  static readonly UI = OverdueFilterUI;

  isActionActive(action: ActionListActionFieldsFragment) {
    const isActive = (() => {
      return (
        action.status !== TaskStatus.act &&
        this.isOverdue(DateTime.fromISO(action.deadline as string))
      );
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isOverdue(time: DateTime) {
    return time.plus({ hours: this.definitionOfOverdueInHours }) < DateTime.now();
  }
}

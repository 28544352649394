{
  "dropdowns": {
    "abonnements": "Abbonements",
    "leading": {
      "label": "gehe zu Ordner"
    },
    "trailing": {
      "item": "Datei",
      "label": "Download"
    }
  },
  "infotext": "Hier wurde innerhalb der letzten 24 Stunden ein neues Dokument hochgeladen.",
  "leading": {
    "label": "Upload"
  },
  "noUploads": "Es sind keine Uploads zum Anzeigen vorhanden"
}

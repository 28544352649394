{
  "forms": {
    "inputName": {
      "label": "Name",
      "placeholder": "Name eingeben"
    },
    "inputColor": {
      "label": "Farbe:",
      "color": "Grundfarbe",
      "colorNegative": "Negativ",
      "colorPositiv": "Positiv"
    }
  }
}

<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    <i class="fas fa-user mr-1" />
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { computed, defineComponent, PropType } from "vue";
import apolloCache from "@/graphql/apolloCache";
import CreatorFilter from "../../filterImplementations/CreatorFilter";
import FilterBadge from "./FilterBadge.vue";
import joinList from "./joinList";

export default defineComponent({
  name: "CreatorFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<CreatorFilter>,
      required: true,
    },
  },
  setup(props) {
    const text = computed(() => {
      const usernames = props.filter.creators.map((creatorId) => {
        const { preferredName } = apolloCache.readFragment<{ preferredName: string }>({
          id: `User:${creatorId}`,
          fragment: gql`
            fragment PreferredName on User {
              preferredName
            }
          `,
        }) ?? { preferredName: "Unknown" };
        return preferredName;
      });
      return joinList(usernames);
    });

    return {
      text,
    };
  },
});
</script>

<style lang="scss" scoped></style>

{
  "audit": {
    "children": {
      "audit": {
        "children": {
          "appstore": {
            "title": "AppStore Link"
          },
          "overview": {
            "title": "Übersicht"
          }
        },
        "title": "Auditverwaltung"
      }
    },
    "title": "Audits"
  },
  "buttons": {
    "hideMenu": "Menü einklappen"
  },
  "changeRequest": {
    "children": {
      "Archive": {
        "children": {
          "overview": {
            "title": "Übersicht"
          }
        },
        "title": "Archiv"
      },
      "CR": {
        "children": {
          "new": {
            "title": "Neue Änderung beantragen"
          },
          "overview": {
            "title": "Geöffnet"
          },
          "overviewReturned": {
            "title": "Zurückgesendet"
          },
          "overviewSubmitted": {
            "title": "Eingereicht"
          }
        },
        "title": "Änderungsanträge"
      },
      "Error": {
        "children": {
          "new": {
            "title": "Fehler melden"
          },
          "overview": {
            "title": "Fehlerübersicht"
          }
        },
        "title": "Fehlerübersicht"
      },
      "closed": {
        "children": {
          "overview": {
            "title": "Übersicht"
          }
        },
        "title": "Abgeschlosse Änderungsanträge"
      }
    },
    "title": "Änderungsanträge (Beta)"
  },
  "customerManagement": {
    "children": {
      "customerManagement": {
        "title": "Übersicht"
      }
    },
    "title": "Kundenverwaltung"
  },
  "dashboard": {
    "children": {
      "dashboard": {
        "title": "Dashboard"
      }
    },
    "title": "Dashboard"
  },
  "documents": {
    "children": {
      "documentsManagement": {
        "children": {
          "folderStructure": {
            "title": "Übersicht"
          },
          "showDocuments": {
            "title": "Dokumentenliste"
          }
        },
        "title": "Dokumentenverwaltung"
      }
    },
    "title": "Dokumente"
  },
  "hlp": {
    "children": {
      "export": {
        "children": {
          "export": {
            "title": "Exportieren"
          }
        },
        "title": "Administration"
      },
      "hlpSettings": {
        "children": {
          "categoryList": {
            "title": "Vorschlagswesen-Kategorien"
          },
          "settingsList": {
            "title": "Vorschlagswesen-Konfiguration"
          }
        },
        "title": "Einstellungen"
      }
    },
    "title": "Vorschlagswesen"
  },
  "ih": {
    "children": {
      "maintenance": {
        "children": {
          "equipments": {
            "title": "Technische Ausrüstung"
          },
          "maintenanceSchedule": {
            "title": "Wartungsplan"
          },
          "weakPointAnalysis": {
            "title": "Schwachstellenanalyse"
          }
        },
        "title": "IH-Maßnahmen"
      },
      "settings": {
        "children": {
          "IHConfigs": {
            "title": "IH-Konfiguration"
          },
          "equipments": {
            "title": "Equipments"
          }
        },
        "title": "Einstellungen"
      }
    },
    "title": "Instandhaltung"
  },
  "mm": {
    "children": {
      "material": {
        "children": {
          "new": {
            "title": "Neues anlegen"
          },
          "overview": {
            "title": "Materialliste"
          },
          "productGroup": {
            "title": "Warengruppen"
          }
        },
        "title": "Material"
      },
      "materialmanagement": {
        "children": {
          "consumMaterial": {
            "title": "Material verbrauchen"
          },
          "mailDistribution": {
            "title": "E-Mail-Verteiler"
          },
          "orderMaterial": {
            "title": "Material anfordern"
          },
          "orderOverview": {
            "title": "Wareneingang"
          },
          "stockOverview": {
            "title": "Bestandsübersicht"
          },
          "storageFill": {
            "title": "Lagerbestand füllen"
          },
          "transportOrder": {
            "title": "Transportaufträge"
          },
          "transportRequest": {
            "title": "Transportanfragen"
          }
        },
        "title": "Materialwirtschaft"
      },
      "organization": {
        "children": {
          "storageLocations": {
            "title": "Lagerplätze"
          },
          "warehouses": {
            "title": "Lager"
          }
        },
        "title": "Organisation"
      },
      "settings": {
        "children": {
          "mailDistribution": {
            "title": "E-Mail-Verteiler"
          }
        },
        "title": "Einstellungen"
      },
      "storage": {
        "children": {
          "orderOverview": {
            "title": "Wareneingang"
          },
          "stockOverview": {
            "title": "Bestandsübersicht"
          },
          "storageLocations": {
            "title": "Lagerplätze"
          },
          "supplyPoints": {
            "title": "Versorgungspunkte"
          },
          "warehouses": {
            "title": "Lager"
          }
        },
        "title": "Lager"
      },
      "transfer": {
        "children": {
          "createTransfer": {
            "title": "Transport anlegen"
          },
          "viewTransfers": {
            "title": "Übersicht"
          }
        },
        "title": "Transport"
      },
      "vendor": {
        "children": {
          "contactPerson": {
            "title": "Kontaktpersonen"
          },
          "new": {
            "title": "Neue anlegen"
          },
          "overview": {
            "title": "Lieferantenliste"
          }
        },
        "title": "Lieferanten"
      }
    },
    "title": "WMS"
  },
  "news": {
    "children": {
      "newsList": {
        "children": {
          "newsActive": {
            "title": "Aktiv"
          },
          "newsArchiv": {
            "title": "Archiv"
          },
          "newsCreate": {
            "title": "News anlegen"
          },
          "newsPlanned": {
            "title": "Geplant"
          }
        },
        "title": "News"
      }
    },
    "title": "News"
  },
  "organisation": {
    "children": {
      "competenceMatrix": {
        "children": {
          "competenceMatrix": {
            "title": "Qualifikationsmatrix"
          },
          "coreCompetenceEditor": {
            "title": "Stammkompetenzen"
          }
        },
        "title": "Qualifikationsmatrix"
      },
      "organisation": {
        "children": {
          "competenceMatrix": {
            "title": "Qualifikationsmatrix"
          },
          "departments": {
            "title": "Abteilungen"
          },
          "sites": {
            "title": "Standorte"
          }
        },
        "title": "Organisationsstruktur"
      }
    },
    "title": "Organisation"
  },
  "problemSolving": {
    "children": {
      "PSC": {
        "children": {
          "newPSC": {
            "title": "A3/A5 anlegen"
          },
          "overview": {
            "title": "Übersicht"
          }
        },
        "title": "Problemlösung"
      },
      "kaizen": {
        "children": {
          "kaizenOverview": {
            "title": "Übersicht"
          },
          "newKaizen": {
            "title": "Kaizen anlegen"
          }
        },
        "title": "Problemlösung"
      },
      "knowledge": {
        "children": {
          "knowledgeOverview": {
            "title": "Übersicht"
          }
        },
        "title": "Wissensdatenbank"
      },
      "short": "Problemlösung"
    },
    "title": "Problemlösung"
  },
  "reporting": {
    "title": "Reports"
  },
  "settings": {
    "children": {
      "costCenters": {
        "title": "Kostenstellen"
      },
      "info": {
        "children": {
          "changelog": {
            "title": "Changelog"
          }
        },
        "title": "Informationen"
      },
      "instance": {
        "children": {
          "incomingInstance": {
            "title": "Eingehende Verbindungen"
          },
          "outgoingInstance": {
            "title": "Ausgehende Verbindungen"
          }
        },
        "title": "Instanzen verknüpfen"
      },
      "mqtt": {
        "children": {
          "mqttDeviceList": {
            "title": "Geräteliste"
          },
          "mqttRuleList": {
            "title": "Regelliste"
          }
        },
        "title": "IoT"
      },
      "organisation": {
        "children": {
          "departments": {
            "title": "Abteilungen"
          },
          "flags": {
            "title": "Flag"
          },
          "shiftSchedules": {
            "title": "Schichtpläne"
          },
          "sites": {
            "title": "Standorte"
          }
        },
        "title": "Organisationsstruktur"
      },
      "systemSettings": {
        "children": {
          "costCenters": {
            "title": "Kostenstellen"
          },
          "generalSettings": {
            "title": "Systemeinstellungen"
          },
          "shiftSchedules": {
            "title": "Schichtpläne"
          },
          "systemSettingsColor": {
            "title": "Design & Farben"
          },
          "technicalPlaces": {
            "title": "Technische Plätze"
          }
        },
        "title": "Systemeinstellungen"
      },
      "userManagement": {
        "children": {
          "roleAssignment": {
            "title": "Rollenzuweisung"
          },
          "roles": {
            "title": "Rollen"
          },
          "user": {
            "title": "Benutzerliste"
          }
        },
        "title": "Benutzerverwaltung"
      }
    },
    "title": "Einstellungen"
  },
  "sqdc": {
    "children": {
      "boards": {
        "children": {
          "dataMaintenance": {
            "title": "Datenwartung (Beta)"
          },
          "kpiBoard": {
            "title": "KPI-Board"
          },
          "taskBoard": {
            "title": "Problemlösungsboard"
          }
        },
        "title": "Shopfloor"
      },
      "sqdcSettings": {
        "children": {
          "boardHierarchy": {
            "title": "Board-Aufbau"
          },
          "boardList": {
            "title": "Board-Liste"
          },
          "boardTemplates": {
            "title": "Board-Vorlagen"
          },
          "datapointTypes": {
            "title": "Datenpunkttypen"
          },
          "flags": {
            "title": "Flags"
          },
          "mailDistribution": {
            "title": "E-Mail-Verteiler"
          },
          "target": {
            "title": "Zielwerte"
          }
        },
        "title": "Einstellungen"
      },
      "sqdcTemplates": {
        "children": {
          "assignmentLayout": {
            "title": "Maschinenbelegung"
          },
          "boardTemplates": {
            "title": "Board-Vorlagen"
          },
          "chartLayout": {
            "title": "LisaChart-Vorlagen"
          },
          "dashlists": {
            "title": "Strichlistenvorlagen"
          },
          "eabShorthand": {
            "title": "Vordefinierte Aktionen"
          },
          "editChartLayout": {
            "title": "Chart-Vorlagen"
          },
          "workflowOverview": {
            "title": "Workflow-Vorlagen"
          }
        },
        "title": "Vorlagen"
      }
    },
    "title": "SQDC"
  },
  "timeStampClock": {
    "children": {
      "admin": {
        "children": {
          "timeStampAttendanceList": {
            "title": "Anwesenheitsliste"
          },
          "timeStampDailyOverview": {
            "title": "Tagesübersicht"
          },
          "timeStampOverviewSubmittedSheets": {
            "title": "Stundenzettel"
          },
          "timeStampOverviewVacation": {
            "title": "Urlaubsanträge/Fehltage"
          },
          "timeStampUserOverview": {
            "title": "Benutzerübersicht"
          },
          "timeStampYearlyOverview": {
            "title": "Jahresübersicht"
          }
        },
        "title": "Administration"
      },
      "config": {
        "children": {
          "holidays": {
            "title": "Feiertage"
          },
          "siteConfigs": {
            "title": "Standorteinstellungen"
          },
          "userShift": {
            "title": "Schichtplanverwaltung"
          },
          "userShiftAssociations": {
            "title": "Teamübersicht"
          }
        },
        "title": "Einstellungen"
      },
      "stamp": {
        "children": {
          "timeStampClock": {
            "title": "Ein-/Ausstempeln"
          },
          "timeStampOverviewSelf": {
            "title": "Meine Übersicht"
          },
          "timeStampOverviewSelfVacation": {
            "title": "Urlaubsanträge/Fehltage"
          }
        },
        "title": "Zeiterfassung"
      }
    },
    "title": "Zeiterfassung"
  }
}

import { ActionTree, GetterTree, MutationTree } from "vuex";
import { setTheme } from "@/Highcharts";

import $api from "../api/api";
import { keycloak } from "@/keycloak/Keycloak";
import router from "@/router";

export type GalleryImage = {
  itemImageSrc: string;
  thumbnailImageSrc?: string;
  alt?: string;
  title?: string;
  type?: "video" | "audio" | "image";
};

const localState = {
  user: null as null | (main.User & { menu: any }), // TODO: typing
  preferredSite: null as null | number,
  preferredBoard: null,
  lastPage: null,
  isLogin: false,
  gallery: {
    visible: false,
    indexOfFirstElement: 0, // if you provide multiple images and want to initially show the second or third...
    images: [] as GalleryImage[],
  },
};

export const getters: GetterTree<typeof localState, any> = {
  preferredName: (state) => state.user?.preferredName,
  lastPage: (state) => state.lastPage || state.user?.initialPage,
  username: (state) => state.user?.username,
  userId: (state) => state.user?.id,
  initialPage: (state) => state.user?.initialPage,
  profilePictureId: (state) => state.user?.pictureId,
  site: (state) => state.user?.site,
  isAnonym: (state) => state.user?.anonym,
  preferredSite: (state) => state.preferredSite,
  preferredBoard: (state) => state.preferredBoard,
  department: (state) => state.user?.team,
  hasAccessRight: (state) => (right: string) => state.user?.accessRights.includes(right),
  specialFlags: (state) => state.user?.specialFlags,
  taskBoardFilter: (state) => state.user?.taskBoardFilter,
  isLogin: (state) => state.isLogin,
  showDarkTheme: (state) => state.user?.darkMode,
  getModifier: (state) => state.user?.modifier,
  getLocale: (state) => state.user?.language,
  getDepartmentId: (state) => state.user?.teamId,
  getGalleryServiceData: (state) => {
    return {
      ...state.gallery,
      images: state.gallery.images.map((el) => ({
        ...el,
        type: el.type ?? "image",
      })),
    };
  },
};

const actions: ActionTree<typeof localState, any> = {
  mutateGalleryServiceData(
    context,
    payload: { visible: boolean; indexOfFirstElement?: number; images?: GalleryImage[] },
  ) {
    context.commit("mutateGalleryServiceData", payload);
  },
  updateLastPage(context, payload) {
    context.commit("updateLastPage", payload);
  },
  updatePreferredSite(context, payload) {
    context.commit("updateSite", payload);
  },
  updatePreferredBoard(context, payload) {
    context.commit("updateBoard", payload);
  },
  updateJWT(context, payload) {
    context.commit("update", payload);
  },
  setUser(context, payload) {
    context.commit("set", payload);
  },
  setAccessRights(context, payload) {
    context.commit("setAccessRights", payload);
  },
  setUserMenu(context, payload) {
    context.commit("setUserMenu", payload);
  },
  async recoverSession(context) {
    const data = await $api.users.me();
    if (data) {
      context.commit("set", data);
      return data;
    }
    return false;
  },
  async logout(context) {
    context.commit("set", null);
    const logoutUrl = new URL(keycloak.createLogoutUrl());
    // Currently, we cannot set a valid post_logout_redirect_uri, so we remove it here
    logoutUrl.searchParams.delete("post_logout_redirect_uri");
    window.location.replace(logoutUrl.toString());
  },
};

const mutations: MutationTree<typeof localState> = {
  mutateGalleryServiceData(
    state,
    payload: { visible: boolean; indexOfFirstElement?: number; images?: GalleryImage[] },
  ) {
    state.gallery.images = payload.images ?? state.gallery.images;
    state.gallery.indexOfFirstElement = payload.indexOfFirstElement ?? 0;
    state.gallery.visible = payload.visible;
  },
  updateLastPage(state, payload) {
    state.lastPage = payload;
  },
  update(state, payload) {
    if (payload.site && state.user != null) {
      state.user.site = payload.site;
      state.user.siteId = payload.site.id;
    }
    if (payload.startUpSiteId != null) {
      state.preferredSite = payload.startUpSiteId;
    } else if (state.preferredSite == null && state.user != null) {
      state.preferredSite = state.user.siteId;
    }
    if (payload.startUpBoardId != null) {
      state.preferredBoard = payload.startUpBoardId;
    }
  },
  updateSite(state, payload) {
    state.preferredSite = payload;
  },
  updateBoard(state, payload) {
    state.preferredBoard = payload;
  },
  setUserMenu(state, menu) {
    if (state.user != null) {
      state.user.menu = menu;
    }
  },
  setAccessRights(state, accessRights) {
    if (state.user != null) {
      state.user.accessRights = accessRights;
    }
  },
  set(state, user) {
    state.user = user;
    state.isLogin = true;
    if (user == null) {
      state.preferredSite = null;
      state.preferredBoard = null;
      state.isLogin = false;
      router.push("/");
      return;
    }
    setTheme();
    if (user.startUpSiteId != null) {
      state.preferredSite = user.startUpSiteId;
    } else if (state.preferredSite == null && state.user != null) {
      state.preferredSite = state.user.siteId;
    }

    state.preferredBoard = user.startUpBoardId;
  },
};

export default {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};

import { createApp, onMounted, provide, h, computed } from "vue";
import PrimeVue, { usePrimeVue } from "primevue/config";
import { useConfirm } from "primevue/useconfirm";
import "@fortawesome/fontawesome-free/css/all.css";
import "./veeValidate";
import "flag-icons/css/flag-icons.min.css";

import Vue2TouchEvents from "vue2-touch-events";
import responsive from "vue-responsive";
import VueKonva from "vue-konva";

import VueGridLayout from "vue3-drr-grid-layout";
import "vue3-drr-grid-layout/dist/style.css";

import VueLazyLoad from "vue-lazyload";
import "./assets/css/tree.css";
import ConfirmationService from "primevue/confirmationservice";
import ToastService, { ToastServiceMethods } from "primevue/toastservice";
import { useI18n } from "vue-i18n";
import AccessRightDirective from "@/functions/accessRightDirective";
// import { initializeBootstrap } from "./bootstrap";

import PortalToBreadcrumbs from "@/components/shared/PortalToBreadcrumbs.vue";

import router from "@/router";
import App from "@/App.vue";
import { initializeSentry } from "./sentry";
import api from "@/api/api";

import timezoneUtil, { TimeZoneInjectionKey } from "./functions/timezoneUtil";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import i18n from "./i18n";
import store from "./store";

import HighchartsVue from "./Highcharts";

import "primevue/resources/themes/saga-blue/theme.css"; // theme
import "primevue/resources/primevue.min.css"; // core css
import "primevue/resources/themes/bootstrap4-light-blue/theme.css"; // theme
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css"; // utility classes
import { startRefreshKeycloakAccessTokenInterval } from "./keycloak/KeycloakRefresher";
import keycloakConfig, { KeycloakInjectionKey } from "./keycloak/KeycloakConfig";
import { initKeycloak } from "./keycloak/Keycloak";

initKeycloak()
  .then(async ({ keycloak, auth }) => {
    if (auth === false) {
      window.location.replace(keycloakConfig.url);
    } else {
      // eslint-disable-next-line
      const user = await store.dispatch("login/recoverSession");
      const app = createApp({
        setup() {
          window.$primevue = usePrimeVue();

          /** provide timezone */
          provide(
            TimeZoneInjectionKey,
            computed(() => Intl.DateTimeFormat().resolvedOptions().timeZone),
          );
          /** provide keycloak */
          provide(KeycloakInjectionKey, keycloak);
          onMounted(async () => {
            store.dispatch("language/setLocale", user.language);
            store.dispatch("systemSettings/fetchColors");
            store.dispatch("systemSettings/fetchColors");
            store.dispatch("systemSettings/fetchModuleFolders");
            store.dispatch("systemSettings/fetchBusinessYear");
            store.dispatch("systemSettings/fetchImage");
            if (
              user.changelogNotification &&
              typeof import.meta.env.__VERSION__ === "string" &&
              user.lastLoginVersion.trim() !== import.meta.env.__VERSION__.trim() &&
              import.meta.env.PROD
            ) {
              router.push("/changelog/new");
            }
          });
        },
        render: () => h(App),
      });

      app.use(store);
      app.use(i18n);
      app.use(router);
      app.use(ConfirmationService);

      app.use(PrimeVue, { ripple: false });
      app.use(VueGridLayout);

      app.use(VueKonva);
      app.use(VueLazyLoad);

      initializeSentry(app);
      app.component("PortalBreadcrumbs", PortalToBreadcrumbs);
      app.use(ToastService);

      app.directive("access-right", AccessRightDirective);

      app.use(Vue2TouchEvents, {
        disableClick: true,
      });
      app.use(responsive);

      // @ts-expect-error invalid type
      app.use(HighchartsVue);
      // initializeBootstrap();

      if (import.meta.env.DEV) {
        app.config.performance = true;
      } else {
        app.config.performance = false;
        // app.config.globalProperties = false;
      }

      /**
       * This will be forced in Vue 3.3
       * https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity
       */
      app.config.unwrapInjectedRef = true;

      app.config.globalProperties.$$moment = (first: string, ...args: never[]) => {
        if (typeof first === "string") {
          return app.config.globalProperties.$moment(Date.parse(first), ...args);
        }
        return app.config.globalProperties.$moment(first, ...args);
      };

      app.config.globalProperties.$api = api;
      app.config.globalProperties.$dtz = timezoneUtil;

      app.mount("#app");
    }

    startRefreshKeycloakAccessTokenInterval(keycloak);
  })
  .catch((err: any) => {
    // eslint-disable-next-line
    console.error(err);
  });

declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
    $toast?: ToastServiceMethods["add"];
    $i18n?: ReturnType<typeof useI18n>;
    $confirm?: ReturnType<typeof useConfirm>;
    $primevue?: ReturnType<typeof usePrimeVue>;
  }
  interface Document {
    scannerDetectionData: undefined | any;
  }
}

{
  "color": "Farbe",
  "commulationNotShowAble": "Es ist aktuell nicht möglich Kommulationen in den Vorhergegangenen Werten anzuzeigen.",
  "default": "Standard",
  "defaultColor": "Original Chartfarbe",
  "defaultColorTooltip": "Das Chart benutzt seine interne Farbwahl für diese Datenreihe. ",
  "invalidSeries": "Datenreihe ist noch nicht valide.",
  "isVisibleBlocked": "Datenreihe mit anderer Granularität",
  "isVisibleBlockedText": "Bei dieser Datenreihe wurde eine andere Granularität gewählt.",
  "nonRangeOnRange": "Sind Sie sicher, dass sie eine normal Diagrammdarstellung für einen Bereichswert wählen wollen? Hiermit werden nur noch die Oberwerte des Bereichswertes angezeigt.",
  "series": "Datenreihe",
  "stackedSeriesTooltip": "Diagrammtyp kann im \"Datenreihen verbinden\"-Reiter geändert werden.",
  "stackingColumnText": "Diese Datenreihe liegt schon hinter einer anderen Säule. Wollen Sie diese Datenreihen noch ein zweites Mal anzeigen?",
  "stackingColumnTitle": "Hinterlegte Säule"
}

{
  "listEditor": {
    "labels": {
      "automatic": "Automatisch",
      "commulated": "Kumuliert",
      "date": "Datum",
      "entity": "Entität",
      "id": "ID",
      "name": "Name",
      "total": "Total"
    },
    "menu": [
      null,
      "Allgemein",
      "Entitäten",
      "Fälle",
      "Chart"
    ],
    "pasteModal": {
      "labels": {
        "number": "lfd. Nr."
      },
      "title": "Wollen Sie diese {count} Einträge hinzufügen?"
    },
    "used": "Wird von {used} Kacheln genutzt"
  },
  "lists": {
    "labels": {
      "createTemplate": "Vorlage anlegen",
      "id": "[id: {id}] '{name}' bearbeiten"
    },
    "table": {
      "createdAt": "Erstellt am",
      "datapointType": "Datenpunkttyp",
      "title": "Titel"
    }
  },
  "settings": {
    "labels": {
      "datapointType": "Datenpunkttyp",
      "primaryAxis": "Titel der Primärachse",
      "requiredInput": "Pflichtfeld",
      "secondaryAxis": "Titel der Sekundärachse",
      "template": "Vorlage",
      "templates": "Vorlage auswählen",
      "title": "Titel",
      "titleCase": "Titel des Falls",
      "titleEntity": "Titel der Entität"
    }
  },
  "tile": {
    "buttons": {
      "dataTable": "Datentabelle",
      "newDatapoint": "Neuer Datenpunkt"
    },
    "datapointModal": {
      "title": "Datenpunkt eintragen"
    },
    "editor": {
      "createQuestion": "anlegen?",
      "newCase": "Neuen Fall",
      "newEntity": "Neue Entität"
    },
    "labels": {
      "case": "Fall",
      "commulated": "Kumuliert",
      "date": "Datum",
      "entity": "Entität",
      "rank": "Rang",
      "total": "Total",
      "value": "Anzahl/Monat"
    },
    "legend": {
      "commulated": "Kumuliert",
      "values": "Einträge"
    },
    "quickSelectTitle": "Schnellzugriff",
    "table": {
      "id": "[id: {id}]",
      "noData": "Keine Daten vorhanden"
    },
    "tableModal": {
      "spinnerText": "Daten werden geladen",
      "title": "Datentabelle für {month}"
    },
    "top10Title": "Top 10"
  }
}

{
  "active": "Stapelung von Datenreihen aktivieren",
  "addStack": "Stapelung hinzufügen",
  "allStacked": "Gestapelte Datenreihen",
  "noSeriesInStack": "Die Stapelung besitzt keine Datenreihe",
  "noSeriesLeft": "Keine weiteren Datenreihen vorhanden",
  "noStackAdded": "Es wurden noch keine Stapelungen erstellt",
  "notStacked": "Nicht gestapelte Datenreihen",
  "stackAll": "Alle Datenreihen zusammen stapeln",
  "stackPlaceholder": "Stapelname",
  "stacks": "Stapelungen"
}

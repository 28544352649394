{
  "LisaChart": "LisaChart",
  "LisaChartTarget": "LisaChart (Target)",
  "DocumentTile": "Upload",
  "CircleTile": "Kreis",
  "SVGTile": "Coil",
  "LinkToTile": "LinkTo",
  "FeedbackTile": "Feedback",
  "WeekScheduleTile": "Wochenplan",
  "DynamicChart": "LisaChart (Dynamisch)",
  "DashListTile": "Strichliste",
  "CalendarTile": "Kalender",
  "AuditTile": "Audit"
}

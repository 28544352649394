{
  "chartTable": {
    "fields": {
      "name": "Name",
      "nr": "Nr.",
      "visible": "Sichtbar"
    }
  },
  "layoutManager": {
    "functions": {
      "changeTitle": "[{id}] | {name} | wird {used} mal verwendet"
    },
    "pages": {
      "datatypes": "Datentypen",
      "design": "Design"
    }
  },
  "modals": {
    "chartMenuDesign": {
      "general": "Allgemein",
      "xAxisTitle": "x-Achse",
      "yAxisTitle": "y-Achse"
    },
    "chartMenuHeader": {
      "script": {
        "ChartSettings": "Einstellungen"
      }
    },
    "createModal": {
      "buttons": {
        "back": "zurück",
        "continueButton": "Weiter im Layoutmanager",
        "next": "weiter"
      },
      "steps": {
        "step1": {
          "title": "Neues Chartlayout anlegen"
        },
        "step2": {
          "title": "Charttyp wählen"
        }
      }
    },
    "layoutManager": {
      "buttons": {
        "deleteLayout": "Layout Löschen",
        "save": "speichern"
      },
      "info": {
        "infoUse": "Dieses Layout kann erst gelöscht werden wenn es nicht mehr verwendet wird. Aktuell {used} mal."
      }
    },
    "ppsSettings": {
      "forms": {
        "Target": "Vorgabewert",
        "chooseDataType": "Datentyp wählen",
        "chooseTarget": "Datentyp wählen",
        "deviation": "Abweichung in %",
        "value": "Ist-Wert"
      }
    }
  }
}

{
  "buttons": {
    "saveButton": "Speichern"
  },
  "formFields": {
    "inputName": {
      "placeholder": "Farbe {value}"
    }
  },
  "headlines": {
    "customerSpecificColors": "Kundenspezifische Farbpalette:",
    "systemColors": "Systemweite Farbpalette:"
  },
  "toasts": {
    "errorSaveColor": {
      "text": "Farbpalette wurde nicht gespeichert",
      "title": "Fehler!"
    },
    "sucessSaveColor": {
      "text": "Farbpalette wurde gespeichert",
      "title": "Erfolgreich!"
    }
  }
}

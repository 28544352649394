{
  "ApprovedLabel": "Genehmigt",
  "ClosedLabel": "Abgeschlossen",
  "EditedLabel": "Bearbeited",
  "HIGHLabel": "Hoch",
  "ImplementedLabel": "Umgesetzt",
  "InImplementationLabel": "In der Bearbeitung",
  "LOWLabel": "Niedrig",
  "MEDIUMLabel": "Mittel",
  "OpenedLabel": "Geöffnet",
  "PostponedLabel": "Aufgeschoben",
  "RejectedLabel": "Abgelehnt",
  "ReturnedLabel": "Zurückgeschickt",
  "SubmittedLabel": "Eingereicht",
  "VERY_HIGHLebel": "Sehr hoch",
  "frameBreadcrumbs": "Übersicht",
  "tabAll": "Alle",
  "tabArchive": "Abgeschlossen",
  "tabOpened": "Geöffnet",
  "tabReturned": "Zurückgeschickt",
  "tabSubmitted": "Eingereicht",
  "tableHeadCreatedAt": "Erstellt am",
  "tableHeadCreator": "Ersteller",
  "tableHeadId": "ID",
  "tableHeadPriority": "Priorität",
  "tableHeadStatus": "Status",
  "tableHeadTitle": "Titel",
  "title": "Übersicht"
}

{
  "addMember": "Mitglied hinzufügen",
  "board": "Board",
  "createPreTask": "Nach Erstellung einer Störmeldung automatisch eine Aufgabe generieren und an Instandhaltung weiterleiten",
  "date": "Datum",
  "email": "E-Mail",
  "flags": "Flags",
  "issue": "Problem",
  "issueId": "Problem ID",
  "leader": "IH Leiter",
  "lfdnr": "lfd. Nr.",
  "lineBreak": "Zeilenumbruch",
  "link": "Link",
  "member": "Mitglieder",
  "minCharacters": "noch {count} Zeichen",
  "name": "Name",
  "paragraph": "Absatz",
  "preTask": "Automatische Aufgabe",
  "preTaskTemplate": "Aufgaben Template",
  "preTaskTemplateText": "Bitte prüfen, ob diese Störung durch die Produktion selbst behoben werden kann(TPM) oder ob für die Erledigung die Instandhaltung benötigt wird!",
  "responsible": "Verantwortlicher",
  "site": "Standort",
  "task": "Aufgabe",
  "taskId": "Aufgaben ID",
  "template": "Mail Template",
  "templateDeactivated": "Das Aufgabentemplate wird nur bei einer automatischen Aufgabe benötigt.",
  "templateText": "<p><b>Störmeldung</b></p>\n<p>Im LISA-System wurde die Störung {'{{'}issueId{'}}'} am Board {'{{'}site{'}}'} / {'{{'}board{'}}'} eingegeben.<br>\nBitte entnehmen Sie Informationen dazu aus der nachstehenden Tabelle.</p>\n\n<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Datum:     {'{{'}date{'}}'}<br>\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Standort:  {'{{'}site{'}}'}<br>\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Board:     {'{{'}board{'}}'}<br>\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Flags:     {'{{'}flags{'}}'}<br>\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rückfragen {'{{'}responsible{'}}'}<br>\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Störung:   {'{{'}issueId{'}}'} {'{{'}issue{'}}'}<br>\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aufgabe:   {'{{'}taskId{'}}'} {'{{'}task{'}}'}</p>",
  "user": "Teamleitername"
}

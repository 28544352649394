{
  "input": {
    "name": "Bezeichnung",
    "deletePage": "Seite Löschen"
  },
  "layout": {
    "preview": "Vorschau",
    "pages": "Seiten"
  },
  "tip": {
    "changesInstant": "Diese Änderungen sind permanent"
  },
  "table": {
    "page": "Seite"
  }
}

<template>
  <div class="lisaBadge border-round" :style="colorProperties" @click.stop="$emit('click', $event)">
    <slot />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import chroma from "chroma-js";

export default defineComponent({
  name: "BadgeComponent",
  props: {
    color: {
      type: String,
      default: "#FFFFFF00",
    },
  },
  emits: ["click"],
  setup(props) {
    const colorProperties = computed(() => {
      const color = chroma.valid(props.color) ? chroma(props.color) : null;
      return {
        background: props.color,
        border: `1px solid ${color?.darken() ?? "lightGray"}`,
        color: color != null && color.luminance() < 0.5 ? "white" : "black",
      };
    });

    return {
      colorProperties,
    };
  },
});
</script>

<style lang="scss" scoped>
.lisaBadge {
  display: inline-block;
  padding: 2.5px 7px;
  font-size: 1em;
  white-space: nowrap;
}
</style>

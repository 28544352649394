import moment from "moment";
import { ActionTree, GetterTree, MutationTree } from "vuex";

import $api from "../api/api";

const localState = {
  colorPicker: {
    designColors: [],
    standardColors: [],
    standardColorsFull: [],
  },
  businessYearStart: 10,
  moduleFolders: {},
  image: null,
  archiveWithoutTasks: null,
  inlineEditActionsAnonym: false,
  changeRequestBoardId: 1,
};

export const getters: GetterTree<typeof localState, any> = {
  getArchiveWithoutTasks: (state) => {
    return state.archiveWithoutTasks;
  },
  getInlineEditActionsAnonym: (state) => {
    return state.inlineEditActionsAnonym;
  },
  getBusinessYear: (state) => {
    return (today = moment()) => {
      const start = moment(today).subtract(1, "years").format("YYYY");
      return moment(`${start}-${state.businessYearStart}`);
    };
  },
  getBusinessYearStartMonth: (state) => {
    if (state.businessYearStart < 10) {
      return `0${state.businessYearStart}`;
    }
    return state.businessYearStart;
  },
  getColors: (state) => {
    return state.colorPicker;
  },
  getModuleFolders: (state) => {
    return state.moduleFolders;
  },
  getChangeRequestBoardId: (state) => {
    return state.changeRequestBoardId;
  },
};

const actions: ActionTree<typeof localState, any> = {
  async fetchInlineEditActionsAnonym(context) {
    const response = await $api.systemSettings.getInlineEditActionsAnonym();
    context.commit("updateInlineEditActionsAnonym", response);
  },
  async fetchModuleFolders(context) {
    const response = await $api.systemSettings.getModuleFolders();
    context.commit("updateModuleFolders", response.meta);
  },
  async fetchColors(context) {
    const response = await $api.systemSettings.getColors();
    context.commit("updateColors", response);
  },
  async fetchBusinessYear(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("businessYear");
    context.commit("updateBusinessYear", response.value || 10);
  },
  async fetchImage(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("placeholderImage");
    context.commit("updateImage", response.value);
  },
  async updateColors(context, payload) {
    const response = await $api.systemSettings.updateColors(0, payload);
    context.commit("updateColors", response);
  },
  async fetchArchiveWithoutTasks(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("archiveWithoutTasks");
    context.commit("updateArchiveWithoutTasks", response.value);
  },
  async fetchChangeRequestBoardId(context) {
    const response = await $api.systemSettings.getGeneralSettingsByKey("changeRequestBoardId");
    context.commit("updateChangeRequestBoardId", response.value);
  },
};

const mutations: MutationTree<typeof localState> = {
  updateInlineEditActionsAnonym(state, payload) {
    state.inlineEditActionsAnonym = payload;
  },
  updateArchiveWithoutTasks(state, payload) {
    state.archiveWithoutTasks = payload;
  },
  updateColors(state, payload) {
    state.colorPicker = payload;
  },
  updateImage(state, payload) {
    state.image = payload;
  },
  updateBusinessYear(state, payload) {
    state.businessYearStart = payload;
  },
  updateModuleFolders(state, payload) {
    state.moduleFolders = payload;
  },
  updateChangeRequestBoardId(state, payload) {
    state.changeRequestBoardId = payload;
  },
};

export default {
  namespaced: true,
  state: localState,
  getters,
  actions,
  mutations,
};

{
  "discardChanges": "Änderungen verwerfen",
  "error": "Ein Fehler ist aufgetreten, bitte füllen Sie die rot markierten Felder aus",
  "failureSaveMassage": "Etwas ist schiefgegangen!",
  "memberPlaceholder": "Welcher Mitarbeiter soll dem Team hinzufügt werden?",
  "nr": "Nr.",
  "print": "Drucken",
  "printDenied": "Das Drucken ist auf dem EAB nicht möglich. Öffnen Sie den PSC dafür über den Menüpunkt \"Problemlösung\"",
  "psc": "Problemlösungszyklus",
  "saveChanges": "Änderungen speichern",
  "successSaveMassage": "Due Änderungen wurden erfolgreich gespeichert.",
  "teamLeader": "Teamleiter",
  "teamMembers": "Teammitglieder"
}

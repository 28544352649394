<template>
  <Galleria
    v-model:visible="visible"
    :activeIndex="activeIndex"
    :value="images"
    :numVisible="images.length"
    containerStyle="max-width: 60%"
    :circular="true"
    :fullScreen="true"
    :showItemNavigators="true"
    :autoPlay="false"
    :showThumbnailNavigators="true"
    class="galleriaZIndex"
  >
    <template #item="slotProps">
      <img
        v-if="slotProps.item.type === 'image'"
        :src="slotProps.item.itemImageSrc"
        :alt="slotProps.item.alt"
        class="itemDimensions"
      />
      <video
        v-else
        class="itemDimensions"
        :src="slotProps.item.itemImageSrc"
        autoplay="true"
        controls
        muted
      ></video>
    </template>
    <template #thumbnail="slotProps">
      <img
        v-if="slotProps.item.type === 'image'"
        :src="slotProps.item.thumbnailImageSrc ?? slotProps.item.itemImageSrc"
        :alt="slotProps.item.alt"
        class="thumbnailDimensions"
      />
      <video
        v-else
        class="thumbnailDimensions"
        :src="slotProps.item.itemImageSrc"
        controls="false"
        muted
      ></video>
    </template>
  </Galleria>
</template>

<script lang="ts">
/**
 * Usage:
 * 
 * This is always available but not rendered when not visible.
 * You can manipulate it using the store.
 * Simply provide the data as shown below and it magically comes to life!
 * 
 *       
 *      store.dispatch("login/mutateGalleryServiceData", {
         visible: true,
         images: [{
              itemImageSrc: <minioURI>,
              thumbnailImageSrc: <minioURI *optional>,
              type: "image" // "video" | "image"
            }] as GalleryImage),
         ),
      });
 */
import { defineComponent, computed } from "vue";

import Galleria from "primevue/galleria";
import { useStore } from "vuex";
import { GalleryImage } from "@/store/login";

export default defineComponent({
  name: "GalleryService",
  components: {
    Galleria,
  },
  setup() {
    const store = useStore();
    const visible = computed<boolean>({
      get() {
        return store.getters["login/getGalleryServiceData"].visible;
      },
      set(v: boolean) {
        store.dispatch("login/mutateGalleryServiceData", { visible: v });
      },
    });
    const images = computed<GalleryImage[]>(
      () => store.getters["login/getGalleryServiceData"].images,
    );

    const activeIndex = computed<number>(
      () => store.getters["login/getGalleryServiceData"].indexOfFirstElement,
    );
    return {
      visible,
      images,
      activeIndex,
    };
  },
});
</script>

<style>
.p-galleria-mask {
  z-index: 1200 !important;
}
</style>

<style scoped>
.itemDimensions {
  max-width: 100%;
  display: block;
  max-height: 80vh !important;
}
.thumbnailDimensions {
  display: block;
  max-width: 80px;
  max-height: 100px;
  pointer-events: none;
}
.galleriaZIndex {
  z-index: 1200;
}
</style>

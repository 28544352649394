import { EventListEventFieldsFragment, Flaggable } from "@/graphql";
import { EventFilter } from "../FilterTypes";
import FlagsFilterUI from "../ui/FlagsFilterUI.vue";
import FlagsFilterBadge from "../ui/badges/FlagsFilterBadge.vue";

function isFlaggable(flaggableCandidate: any): flaggableCandidate is Flaggable {
  return Array.isArray(flaggableCandidate.flags);
}

export default class FlagsFilter implements EventFilter {
  constructor(public readonly flags: number[], public readonly isNegated?: boolean) {}

  static readonly Badge = FlagsFilterBadge;

  static readonly UI = FlagsFilterUI;

  isEventActive(event: EventListEventFieldsFragment) {
    const isActive = (() => {
      if (isFlaggable(event)) {
        if (this.flags.length === 0) {
          return event.flags.length === 0;
        }
        return event.flags.some((flag) => {
          return this.flags.includes(flag.id);
        });
      }
      return false;
    })();
    return this.isNegated ? !isActive : isActive;
  }
}

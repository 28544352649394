<template>
  <Teleport to="#breadcrumbs">
    <li class="breadcrumb-item active PortalToBreadcrumbScope">
      <slot />
    </li>
  </Teleport>
</template>

<script lang="ts">
export default {
  name: "PortalToBreadcrumbs",
};
</script>

<style lang="scss">
@media (min-width: 992px) {
  #breadcrumbs {
    min-width: 50ch;
  }
}

#breadcrumbs {
  min-height: calc(2ch + 1rem);
  display: flex;
  justify-content: center;
}
</style>

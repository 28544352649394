{
  "create": "Dokument anlegen",
  "headline": "Dokumente",
  "isLoading": "Dokument wird geladen",
  "noFolder": "Kein Ordner",
  "searchOptions": {
    "Folder": "Ordner",
    "Uploads": "Dateien",
    "createdAt": "Erstellt am",
    "description": "Beschreibung",
    "id": "ID",
    "name": "Name",
    "site": "Standort"
  }
}

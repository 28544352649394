<template>
  <MultiSelect
    ref="filterRef"
    v-model="selected"
    filter
    option-label="name"
    class="w-100"
    display="chip"
    :options="options"
    @change="toggle"
  >
  </MultiSelect>
</template>

<script lang="ts">
import MultiSelect from "primevue/multiselect";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import EventActionRelationFilter, {
  EventActionRelationFilterOptions,
} from "../filterImplementations/EventActionRelationFilter";

export default defineComponent({
  name: "EventActionRelationFilterUI",
  components: {
    MultiSelect,
  },
  setup() {
    const { t } = useI18n();
    const filterRef = ref<InstanceType<typeof MultiSelect>>();

    const options = computed(() =>
      Object.values(EventActionRelationFilterOptions).map((filterOptionKey) => ({
        name: t(
          `components.event-action.filter.ui.badges.EventActionRelationFilterBadge.${filterOptionKey}`,
        ).toString(),
        value: filterOptionKey,
      })),
    );

    const selected = ref<{ name: string; value: EventActionRelationFilterOptions }[]>([]);

    function labelFormatter(el: { id: number; name: string }) {
      return el.name;
    }

    function getFilter() {
      return new EventActionRelationFilter(selected.value.map((el) => el.value));
    }

    function applyFilter(filter: EventActionRelationFilter) {
      selected.value = filter.filters.map((filterElement) => {
        const result = options.value.find((option) => option.value === filterElement);
        if (result == null) {
          throw Error("unknown EventActionRelationFilter");
        }
        return result;
      });
    }
    function toggle() {
      filterRef.value?.hide();
    }
    return {
      labelFormatter,
      selected,
      toggle,
      filterRef,
      options,
      getFilter,
      applyFilter,
    };
  },
});
</script>

<style lang="scss" scoped></style>

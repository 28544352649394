{
  "archive": "Archivieren",
  "archiveBlocked": "Das Event kann nicht archiviert werden, da nicht alle Anforderungen erfüllt sind. (z.B. noch offene Aktionen, die möglicherweise auch ausgeblendet sein können aufgrund eines Filters)",
  "archiveBlockedFaulTimeEnd": "Archivieren ist nicht möglich, da die Strörmeldung kein Enddatum besitzt!",
  "archiveBlockedNoFeedback": "Es muss erst eine Rückmeldung gesendet werden, bevor dieses Ereignis archiviert werden kann!",
  "archiveBlockedOpenCopies": "Es müssen erst alle Rückmeldungen eingeggangen sein, bevor dieses Ereignis archiviert werden kann!",
  "escalateDelegate": "Eskalieren / Delegieren",
  "eventLinkage": "Ereignis verknüpfen",
  "forwardEvent": "Weiterleiten",
  "reactivateIssue": "Reaktivieren",
  "sendFeedBack": "Rückmelden",
  "sendFeedback": {
    "title": "Rückmeldung"
  },
  "taskAndIHOrderOpen": "Archivieren ist nicht möglich, da das Ereignis noch offene Aufgaben oder IH-Aufträge enthält!",
  "taskOpen": "Archivieren ist nicht möglich, da das Ereignis noch offene Aufgaben enthält!",
  "transferEventModal": {
    "confirmTransferInstanceMessage": "Wollen sie das Ereignis nochmal auf dieselbe Instanz weiterleiten?",
    "confirmTransferMessage": "Wollen sie das Ereignis nochmal auf dasselbe Board weiterleiten?",
    "title": "Ereignis weiterleiten"
  }
}

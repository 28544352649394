<template>
  <Field
    ref="field"
    v-slot="{ meta, field, errors }"
    :validate-on-mount="true"
    :validate-on-blur="true"
    :validate-on-change="true"
    :validate-on-input="true"
    :validate-on-model-update="true"
    :keep-value="true"
    :name="name"
    tag="div"
    :rules="rules"
  >
    <slot
      :valid="meta.valid"
      :invalid="!meta.valid"
      :passed="meta.valid"
      :errors="errors"
      :field="{
        ...field,
        class: {
          'p-invalid': !meta.valid && rules?.required === true,
          'p-valid': meta.valid && rules?.required === true,
        },
      }"
    ></slot>
  </Field>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import { v4 as uuid } from "uuid";
/**
 * This Component restores the functionality of vee-validate 3.0
 * Usage example:
 *
 * <ValidationProvider name="username" #="{field, valid}" v-model="anyModel">
 *    <input v-bind="field" :state="valid" />
 * </ValidationProvider>
 *
 * Note the bound field prop. It is absolutely necessary.
 * It is also important to put the v-model on the Provider and not the input component.
 * This is because vee-validate intercepts events and can not infer the correct attribute (modelValue, visible ...)
 *
 * P.S: since vue 3.0, v-bind is order sensitive. It must be the first attribute.
 */
export default defineComponent({
  name: "ValidationProvider",
  components: {
    Field,
  },
  props: {
    /**
     * vee validate forces a name and it needs to be unique per form/observer
     * if you don't want to provide one we create a temporary uuid
     * but this is not really good for screen-readers
     */
    name: {
      type: String,
      default: () => uuid(),
    },
    rules: {
      type: Object as () => Record<string, any>,
      default: () => ({}),
    },
  },
  setup(props, { attrs, slots }) {
    return {};
  },
});
</script>

{
  "calendar": "Kalender",
  "currentMonth": "Aktueller Monat",
  "help": "Pfeiltasten zur Navigation",
  "nav": "Navigation",
  "nextDecade": "+ 10 Jahre",
  "nextMonth": "+ 1 Monat",
  "nextYear": "+ 1 Jahr",
  "noDateSelected": "Kein Datum gewählt",
  "prevDecade": "- 10 Jahre",
  "prevMonth": "- 1 Monat",
  "prevYear": "- 1 Jahr",
  "selected": "Ausgewählt",
  "today": "Jetzt"
}

{
  "code": {
    "label": "Code"
  },
  "createGrid": {
    "material": {
      "title": "Materialien"
    }
  },
  "header": "Warengruppe",
  "materials": {
    "label": "Materialien"
  }
}

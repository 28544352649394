{
  "allIssues": "Alle Ereignisse",
  "archiv": "Archiv",
  "archiveModal": {
    "title": "Zeitraum auswählen"
  },
  "assignee": "Verantwortlicher",
  "createIssue": "Neues Ereignis anlegen",
  "createKaizen": "Kaizen anlegen",
  "createPSC": "A3/A5 anlegen",
  "createTask": "Neue Aufgabe anlegen",
  "createTaskShort": "Aufgabe",
  "creator": "Ersteller",
  "date": "Datum",
  "deadline": "Termin",
  "description": "Beschreibung",
  "download": "download Excel",
  "filterTaskBoardButton": "Eigener Filter",
  "flags": "Flags",
  "hoverIssueGotoBoard": "Zum Problemlösungsboard springen",
  "id": "ID",
  "idea": {
    "descriptionIdea": "Idee sichten und ggf. Vetorecht nutzen.",
    "descriptionManage": "Idee besprechen und Empfehlung geben, ob diese umgesetzt werden soll."
  },
  "issueAssignee": "Besitzer",
  "issues": "Ereignisse",
  "leaveArchive": "Archiv verlassen",
  "maintenance": "Instandhaltung",
  "newVersion": "Neue Version",
  "news": "Neuigkeiten",
  "noIssue": "Keinem Ereignis zugewiesen",
  "onlyOpenTasks": "Nur offene Aufgaben",
  "overdue": "Nur Überfällige",
  "problemsolvingboard": "Problemlösungsboard",
  "psButton": "KPI-Board",
  "reactivateModal": {
    "text": "Das Ereignis '{name}' wird mit allen Aufgaben und Abhängigkeiten wieder aktiviert.\nWollen Sie fortfahren?",
    "title": "Issue reaktivieren?"
  },
  "refresh": "Aktualisieren",
  "reloadFilter": "Filter aktualisieren",
  "resetFilter": "Filter zurücksetzen",
  "saveTaskBoardButton": {
    "save": "Filter speichern",
    "sure": "Sind Sie Sicher?"
  },
  "sendToWeekSchedule": "Zum Wochenplan senden",
  "specialFlag": {
    "created": "Ursprung: {value}",
    "delegate": "Das Ereignis wurde an Board {value} delegiert",
    "delegateT": "Das Ereignis wurde von Board {value} delegiert",
    "escalate": "Das Ereignis wurde an Board {value} eskaliert",
    "escalateT": "Das Ereignis wurde von Board {value} eskaliert",
    "exported": "Die Idee wurde bereits exportiert",
    "idea": "Die ID der Idee ist {value}",
    "included": "Das Ereignis wurde von Board {value} eingebunden"
  },
  "status": "Status",
  "talkedInSF": "Im SFM Meeting besprochen",
  "tasks": "Aufgaben",
  "toInfo": "Zur Info",
  "toast": {
    "issueFiltered": {
      "text": "Das Ereignis entspricht nicht dem aktuellen Filter und kann daher nicht angezeigt werden!",
      "title": "Ereignis gefiltert!"
    },
    "issueNotFound": {
      "text": "Das Ereignis wird nicht auf dem Problemlösungsboard angezeigt! Bitte wenden Sie sich an einen Administrator oder an den Support im Help-Desk!",
      "title": "Ereignis kann nicht angezeigt werden!"
    }
  },
  "withChanges": "Mit Änderung",
  "withChangesIssues": "Aufgaben mit Änderung",
  "withChangesTasks": "Ereignisse mit Änderung",
  "withTask": "Mit Aufgaben",
  "withoutTask": "Ohne Aufgaben"
}

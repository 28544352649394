{
  "Contacts": "Kontaktpersonen",
  "CreateContactPerson": "Kontaktperson anlegen",
  "DeleteContactPerson": "Kontaktperson löschen",
  "DeleteMaterial": "Material löschen",
  "Materials": "Materialien",
  "ProductGroups": {
    "productGroups": "Warengruppen"
  },
  "city": "Stadt",
  "contactPerson": "Kontaktperson",
  "contactPersonName": "Name",
  "contactPersons": "Kontaktpersonen",
  "country": "Land",
  "createContactPersons": "Kontaktperson anlegen",
  "deleteProductGroup": "Warengruppe löschen",
  "deleteVendor": "Lieferant löschen",
  "department": "Abteilung",
  "description": "Beschreibung",
  "designation": "Bezeichnung",
  "designation ": "Bezeichnung",
  "dor": {
    "identifier": {
      "label": "vendor"
    },
    "name": {
      "label": "Bezeichnung"
    }
  },
  "ductGroups": {
    "productGroups": "Warengruppen"
  },
  "email": "E-mail",
  "erialStorageList": {
    "header": "Materialverfügbarkeit"
  },
  "firstName": "Vorname",
  "fix": "Festnetz",
  "heigth": "höhe (mm)",
  "lastName": "Nachname",
  "le": "WMS",
  "length": "Länge (mm)",
  "material": "Material",
  "materialNr": "Material",
  "materials": "Materialien",
  "name": "Bezeichnung",
  "packing": "Verpackung",
  "productGroup": "Warengruppe",
  "productGroups": "Warengruppen",
  "saveChange": "Änderungen speichern",
  "street": "Straße",
  "tactPerson": {
    "header": "Kontaktperson"
  },
  "tel": "Telefonnummer",
  "textAreaInvalid": "mindestens ein Zeichen",
  "vendor": "Lieferant",
  "vendorMaterialNr": "Lieferant Nr.",
  "vendorName": "Firma",
  "vendors": "Lieferanten",
  "weigth": "Gewicht (g)",
  "width": "Breite (mm)",
  "ws": {
    "list": {
      "TransportRequest": {
        "listheader": "Transportanfragen"
      }
    }
  },
  "zipcode": "PostleitZahl"
}

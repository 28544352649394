<template>
  <ValidationProvider v-slot="{ field }" v-model="boardOrigin" :rules="{ required: true }">
    <Dropdown
      v-model="boardOrigin"
      class="w-100"
      v-bind="field"
      option-label="text"
      option-value="value"
      :options="options"
    />
  </ValidationProvider>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Dropdown from "primevue/dropdown";
import { SQDCBoardIssueConnectionTypes } from "@/graphql";
import BoardOriginFilter from "../filterImplementations/BoardOriginFilter";
import ValidationProvider from "@/modules/veevalidate/components/ValidationProvider.vue";

export default defineComponent({
  name: "BoardOriginFilterUI",
  components: {
    Dropdown,
    ValidationProvider,
  },
  setup(props) {
    const boardOrigin = ref<SQDCBoardIssueConnectionTypes>(SQDCBoardIssueConnectionTypes.created);
    const { t } = useI18n();

    return {
      boardOrigin,
      getFilter() {
        return new BoardOriginFilter(boardOrigin.value);
      },
      applyFilter(filter: BoardOriginFilter) {
        boardOrigin.value = filter.boardOrigin;
      },
      options: computed(() => [
        {
          value: SQDCBoardIssueConnectionTypes.escalate,
          text: t("components.event-action.issue.BoardOriginArrow.escalated"),
        },
        {
          value: SQDCBoardIssueConnectionTypes.delegate,
          text: t("components.event-action.issue.BoardOriginArrow.delegated"),
        },
        {
          value: SQDCBoardIssueConnectionTypes.created,
          text: t("components.event-action.issue.BoardOriginArrow.created"),
        },
      ]),
    };
  },
});
</script>

<style lang="scss" scoped></style>

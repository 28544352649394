import { IterableElement } from "type-fest";
import {
  EventListEventFieldsFragment,
  EventListEventFields_IHFailureNotice_Fragment,
  EventListEventFields_IHMaintenance_Fragment,
  EventListEventFields_Issue_Fragment,
  EventListEventFields_HLPIdea_Fragment,
  EventListEventBoardFields_IHFailureNotice_Fragment,
  EventListEventBoardFields_IHMaintenance_Fragment,
  EventListEventBoardFields_Issue_Fragment,
  SQDCBoardIssueConnectionTypes,
  EventListEventBoardFieldsOnlyBoardFragment,
} from "@/graphql";

export type EventWithoutAudit =
  | EventListEventFields_IHFailureNotice_Fragment
  | EventListEventFields_IHMaintenance_Fragment
  | EventListEventFields_Issue_Fragment
  | EventListEventFields_HLPIdea_Fragment;

export function isNotAuditResult(event: EventListEventFieldsFragment): event is EventWithoutAudit {
  return event.__typename !== "AuditResult";
}
/**
  @param {number?} boardId gets the desired board connection (if empty gets original board connection).
 */
export function getBoardWithConnection(
  event: EventWithoutAudit,
  boardId?: number,
  checkForAlternativeStatus = false,
) {
  const firstBoardConnection = event.boards?.[0];
  if (firstBoardConnection == null) {
    return null;
  }
  const boardConnectionProp = Object.keys(firstBoardConnection).find((key) =>
    key.endsWith("Connection"),
  );
  if (boardConnectionProp == null) {
    return null;
  }
  const currentBoardConnection: any = (event.boards as [] | undefined)?.find(
    (board: { id: number } & Record<string, { status: SQDCBoardIssueConnectionTypes }>) => {
      if (boardId != null) {
        return board.id === boardId;
      }
      return board[boardConnectionProp]?.status === SQDCBoardIssueConnectionTypes.created;
    },
  );

  /* 
    Some events that are imported from Flags are broken because of missing boardId match.
    This prevents these events from not being displayed:
    Hours wasted: 1,5
   */
  let guessedBoardConnection = (event.boards as [] | undefined)?.find((el: any) => {
    return el[boardConnectionProp]?.status === SQDCBoardIssueConnectionTypes.created;
  });

  if (checkForAlternativeStatus === true && guessedBoardConnection == null) {
    guessedBoardConnection = (event.boards as any[])?.[0];
  }

  if (currentBoardConnection == null) {
    if (guessedBoardConnection != null) {
      return {
        board: guessedBoardConnection,
        boardConnection: guessedBoardConnection[boardConnectionProp],
      };
    }
    return null;
  }
  return {
    board: currentBoardConnection as EventListEventBoardFieldsOnlyBoardFragment,
    boardConnection: currentBoardConnection[boardConnectionProp] as
      | IterableElement<
          EventListEventBoardFields_IHFailureNotice_Fragment["boards"]
        >["IHSQDCBoardFailureNoticeConnection"]
      | IterableElement<
          EventListEventBoardFields_IHMaintenance_Fragment["boards"]
        >["IHSQDCBoardMaintenanceConnection"]
      | IterableElement<EventListEventBoardFields_Issue_Fragment["boards"]>["BoardIssueConnection"],
  };
}

{
  "ExternalResources": "Externe Ressourcen",
  "HLPIdea": "Neue Idee",
  "IHFailureNotice": "Neue Störmeldung",
  "IHMaintenance": "Neue Wartung",
  "Issue": "Neues Problem",
  "Order": "Auftrag",
  "add": "Einfügen",
  "addNewInfo": "neue Info einfügen",
  "additionalInfos": "Zusätzliche Information",
  "alreadyInArchiv": "Issue ist bereits archiviert",
  "archive": "Archivieren",
  "archiving": "Archivieren",
  "archivingNotAllowed": "Archivieren ist nicht möglich, da noch nicht alle Aktionen beendet sind",
  "archivingWithoutTasksNotAllowed": "Archivieren ist nicht möglich, da das Problem keine Aktionen enthält",
  "area": "Flags",
  "board": "Board:",
  "categorie": "Kategorie:",
  "close": "anlegen + Neue Aufgabe",
  "cost": "Kosten",
  "create": "Anlegen",
  "createAndMaintenanceOrder": "Anlegen + Neuer IH-Auftrag",
  "createAndTask": "Anlegen + Neue Aufgabe",
  "createIdea": "Idee anlegen",
  "date": "Wann",
  "deadline": "Wann:",
  "description": "Beschreibung",
  "descriptionPlaceholder": "Bitte Beschreibung eingeben",
  "disabledDatePicker": "Das Ändern ist nur als Admin möglich oder bei eigenen Ereignissen",
  "duplicateTag": "duplizierte Tags",
  "duration": "Dauer",
  "equipments": "Equipments",
  "failure": "Ausfall",
  "fault": "Störung",
  "faultTime": "Störungzeitraum",
  "flags": "Flags:",
  "flagsPlaceholder": "Flags auswählen",
  "idea": {
    "category": "Kategorie",
    "id": "Ideen ID",
    "isDescription": "Istzustand",
    "isStatus": "ist:",
    "percentage": "Anteil (%)",
    "percentageToolTip": "Der gemeinsame Anteil darf 100% nicht überschreiten.",
    "should": "Sollzustand",
    "shouldStatus": "soll:",
    "title": "Neue Idee anlegen",
    "titleChange": "Idee anzeigen/ändern",
    "type": "Idee",
    "users": "Teilhaber"
  },
  "internalResources": "Interne Ressourcen",
  "mailDistribution": "Mail-Verteiler benötigt eine Aufgabe",
  "mailDistributionTitle": "Neue Aufgabe anlegen (Mail-Verteiler)",
  "maintenance": "Wartung",
  "maintenanceDescription": "Beschreibung",
  "newEvent": "Neues Ereignis",
  "newFailureNotice": "Neue Störmeldung anlegen",
  "newIssue": "Neues Problem anlegen",
  "newMaintenance": "Neue Wartung anlegen",
  "occurenceDatePlaceholder": "Bitte Datum eintragen",
  "order": "Auftrag",
  "planDate": "Plan-Datum",
  "plannedTime": "Geplanter Zeitaufwand",
  "priority": "Priorität",
  "repTime": "Wartungszeitraum",
  "sap": "SAP-Nummer",
  "sparePart": "Ersatzteile",
  "technicalCategorie": "Technische Kategorie:",
  "technicalCategory": "Technische Kategorie",
  "technicalPlace": "Technischer Platz",
  "title": "Titel",
  "tpm": "TPM",
  "update": "Ändern",
  "updateAndMaintenanceOrder": "Ändern + Neuer IH-Auftrag",
  "updateAndTask": "Ändern + Neue Aufgabe",
  "updateFailureNotice": "Störmeldung ändern",
  "updateIssue": "Problem ändern",
  "updateMaintenance": "Wartung ändern",
  "user": "Ersteller",
  "userPlaceholder": "Benutzer auswählen"
}

{
  "noTilesInConfig": "Keine Kacheln in der aktuellen Konfiguration",
  "settingsModal": {
    "addTilesError": "Bitte Kacheln hinzufügen",
    "buttons": {
      "addTiles": "Kacheln hinzufügen"
    },
    "form": {
      "tile": "Kacheln"
    },
    "headers": {
      "action": "Aktion",
      "board": "Board",
      "technicalPlace": "Technischer Platz",
      "tileId": "Kachel ID",
      "tileName": "Kachel Name"
    },
    "title": "Datenwartung - Einstellungen"
  },
  "settingsTitle": "Einstellungen",
  "title": "Datenwartung (Beta)"
}

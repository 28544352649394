{
  "breadcumbs": "Neues Board-Template anlegen",
  "buttons": {
    "createButton": "Anlegen",
    "deleteButton": "Boardvorlage löschen",
    "saveButton": "Änderungen speichern"
  },
  "deleteMessageIfUsage": "Dieses Template kann erst gelöscht werden wenn es nicht mehr verwendet wird.",
  "formFields": {
    "inputColor": "Hintergrund",
    "inputColumns": {
      "Flags": "Flag",
      "defaultColumns": "Sie können zwischen einer und acht Spalten wählen.",
      "defaultTemplate": "Diese Vorlage als Standard verwenden",
      "description": "Beschreibung",
      "div": "Spaltenzahl",
      "editColumns": "Das Template kann nur auf {count} Spalten reduziert werden, da bereits Kacheln in der {count}. Spalte auf Boards mit diesem Template existieren.",
      "label": "Spaltenanzahl"
    },
    "inputFontColor": "Schrift",
    "inputName": {
      "label": "Name",
      "text": "Name der Boardvorlage"
    }
  },
  "headlinModalTitle": "Überschriftenbild ändern",
  "headlineUsage": "{usage} mal verwendet",
  "headlines": {
    "newTemplate": "Neue Board-Vorlage anlegen",
    "updateTemplate": "Board-Vorlage ändern"
  },
  "image": "Bild",
  "letter": "Buchstabe",
  "modalMsg": "Soll das Board {name} unwiederruflich gelöscht werden?",
  "modalTitle": "Board-Template löschen"
}

{
  "archivTargets": "Archivierte Ziele",
  "buttons": {
  },
  "confirmationModals": {
    "delete": {
      "cancelText": "Abbrechen",
      "okText": "Ziel löschen",
      "title": "Ziel {name} löschen?"
    }
  },
  "currentTargets": "Aktuelle Ziele",
  "headline": "Zielwerte",
  "list": {
    "name": "keine Zielwerte |{templates} Zielwert | {templates} Zielwerte",
    "options": {
      "actions": "Aktionen",
      "category": "Kategorie",
      "datatype": "Datenpunkttyp",
      "id": "ID",
      "max": "Maximum",
      "min": "Minimum",
      "name": "Name",
      "operator": "Operator",
      "site": "Standort",
      "type": "Typ",
      "validity": "Gültig seit"
    }
  },
  "noTargetAvailable": "Kein Ziel vorhanden"
}

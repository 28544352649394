import { createStore, createLogger } from "vuex";
import VueXPersistence from "vuex-persist";

import login from "@/store/login";
import clientCache from "@/store/clientCache";
import systemSettings from "@/store/systemSettings";
import language from "@/store/language";
import meet from "@/store/meet";
import filterCache from "@/store/filterCache";
import taskComment from "@/store/taskComment";
import issues from "@/store/issues";
import datapoints from "@/store/datapoints";
import shiftSchedule from "@/store/shiftSchedule";

const debug = import.meta.env.PROD !== true;

const vueXLocal = new VueXPersistence({
  storage: window.sessionStorage, // Persist state in session storage
  modules: ["login", "clientCache"],
});

const modules = {
  login,
  systemSettings,
  clientCache,
  language,
  meet,
  filterCache,
  taskComment,
  issues,
  datapoints,
  shiftSchedule,
};

export default createStore({
  modules,
  strict: debug,
  plugins: debug ? [createLogger(), vueXLocal.plugin] : [vueXLocal.plugin],
});

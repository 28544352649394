{
  "author": "Autor",
  "begin": "Beginn",
  "cancel": "Abbrechen",
  "change": "News ändern",
  "changeNews": "Änderungen speichern",
  "confirm": "Bitte bestätigen",
  "confirmationTitle": "Sollen die News unwiederruflich gelöscht werden?",
  "content": "Inhalt",
  "createNewNews": "Neue Nachrichten anlegen",
  "createNews": "News anlegen",
  "deleteNews": "News löschen",
  "global": "Global",
  "globalRemark": "(wird überall angezeigt)",
  "miscellaneous ": "Verschiedenes",
  "news": "News",
  "priority": "Priorität",
  "priorityRemark": "(wird im News-Ticker als Erstes angezeigt)",
  "restoreNews": "News wiederherstellen",
  "site": "Ort",
  "textAreaInvalid": "mindestens ein Zeichen",
  "textAreaValid": "noch {count} Zeichen",
  "title": "Titel",
  "validity": "Ende",
  "validityDateInvalid": "Datum ist ungültig"
}

<template>
  <div class="flex align-items-center">
    <PrimeButton
      type="button"
      icon="fas fa-users"
      class="p-button-rounded"
      :class="{
        'active-meeting': activeMeeting,
        'p-button-secondary': !activeMeeting,
      }"
      @click="toggle"
    />
    <PrimeMenu
      ref="menu"
      :model="items"
      class="HeadNavBarScoped"
      :popup="true"
      style="font-size: 0.8em; width: 25em"
    >
    </PrimeMenu>
  </div>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { computed, defineComponent, ref } from "vue";
import PrimeButton from "primevue/button";
import PrimeMenu from "primevue/menu";
import { MenuItem } from "primevue/menuitem";

import {
  useMeetingsQuery,
  MeetingsChangedDocument,
  usefinishMeetingMutation,
  usecreateMeetingMutation,
  useJwtForRoomMutation,
} from "@/graphql";

export default defineComponent({
  name: "MeetItem",
  components: {
    PrimeButton,
    PrimeMenu,
  },
  props: {
    boardId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const menu = ref();
    const { result, subscribeToMore } = useMeetingsQuery();
    subscribeToMore({
      document: MeetingsChangedDocument,
      updateQuery: (previousResult, { subscriptionData }) => {
        return {
          meetings: subscriptionData.data.meetings,
        };
      },
    });
    const { t } = useI18n();
    const toggle = (event: any) => {
      menu.value.toggle(event);
    };
    const meetings = computed(() => result.value?.meetings ?? []);

    const store = useStore();

    const userId = store.getters["login/userId"];

    const otherMeetings = computed(() => {
      return meetings.value.filter((meeting) => {
        return meeting.board.id !== props.boardId;
      });
    });
    const meetingsForThisBoard = computed(() => {
      return meetings.value.filter((meeting) => {
        return meeting.board.id === props.boardId && meeting.creator?.id !== userId;
      });
    });
    const myMeeting = computed(() => {
      return meetings.value.find((meeting) => {
        return meeting.board.id === props.boardId && meeting.creator?.id === userId;
      });
    });

    const activeMeeting = computed(() => meetingsForThisBoard.value.length > 0 || myMeeting.value);

    const { mutate: finishMeetingMutation } = usefinishMeetingMutation({});
    const { mutate: createMeetingMutation } = usecreateMeetingMutation(() => ({
      variables: { boardId: props.boardId },
    }));

    const { mutate: getJWT } = useJwtForRoomMutation({});

    const joinMeeting = (roomName?: string) => {
      // get a reference to window.open synchronously, to support new tab functionality in iOS
      if (roomName == null) {
        return;
      }
      const windowReference = window.open();

      // not using async here intentionally, to support setting windowReference.location asynchronously
      getJWT({ roomName }).then((data) => {
        if (data?.data?.jwtForRoom && windowReference) {
          const link = `https://meet.lisapp.dev/${roomName}?jwt=${data.data.jwtForRoom}`;
          windowReference.location = link;
        }
      });
    };
    function finishMeeting(roomName: string | undefined) {
      if (roomName == null) {
        return;
      }
      finishMeetingMutation({ roomName });
    }
    async function createMeeting() {
      const data = await createMeetingMutation();
      joinMeeting(data?.data?.createMeeting.roomName);
    }

    const items = computed<MenuItem[]>(() => {
      const menuItems: MenuItem[] = [];
      /**
       * pushing my meeting when one is available
       *
       */
      if (myMeeting.value != null) {
        menuItems.push({
          label: t("components.MeetItem.myMeetingsHeader"),
          items: [
            {
              label: t("components.MeetItem.joinMyMeeting"),
              icon: "fas fa-sign-in",
              command: () => joinMeeting(myMeeting.value?.roomName),
            },
            {
              label: t("components.MeetItem.finishMyMeeting"),
              icon: "fas fa-times-circle text-error",
              command: () => finishMeeting(myMeeting.value?.roomName),
            },
          ],
        });
      }

      /**
       * pushing create and join operation if board is available
       */
      if (props.boardId && myMeeting.value == null) {
        menuItems.push({
          label: t("components.MeetItem.meetings"),
          items: [
            {
              label: t("components.MeetItem.createMeeting"),
              icon: "fas fa-plus",
              command: () => createMeeting(),
            },
          ],
        });
      }
      if (meetingsForThisBoard.value.length > 0) {
        menuItems.push({
          label: t("components.MeetItem.thisBoardMeetingsHeader"),
          items: meetingsForThisBoard.value.map((boardMeetings) => {
            return {
              icon: "fas fa-sign-in",
              label: t("components.MeetItem.joinMeetingBy", {
                by: boardMeetings.creator?.preferredName,
              }),
              command: () => joinMeeting(boardMeetings.roomName),
            };
          }),
        });
      }
      /**
       * pushing Meetings for other boards
       */
      menuItems.push({
        label: t("components.MeetItem.otherBoardMeetingsHeader"),
        items: otherMeetings.value.map((otherMeetingsForMenu) => {
          return {
            icon: "fas fa-sign-in",
            label: otherMeetingsForMenu.board.name,
            command: () => joinMeeting(otherMeetingsForMenu.roomName),
          };
        }),
      });
      menuItems.push({
        icon: "fas fa-spinner fa-spin",
        label: t("components.MeetItem.liveLoading"),
      });
      return menuItems;
    });

    return {
      myMeeting,
      menu,
      items,
      activeMeeting,
      otherMeetings,
      meetingsForThisBoard,
      joinMeeting,
      toggle,
      createMeeting,
      finishMeeting,
      finishMeetingMutation,
      createMeetingMutation,
    };
  },
});
</script>

<style lang="scss">
.active-meeting {
  background-color: var(--warn-color) !important;
  border-color: var(--warn-color) !important;

  &:hover,
  &:active {
    background-color: var(--warn-color) !important;
  }
}
</style>

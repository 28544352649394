{
  "cantChange": "Sie dürfen die Standortzuweisung nicht bearbeiten.",
  "department": "Abteilung (optional)",
  "inSite": "im Standort",
  "noright": "Sie sind nicht berechtigt, den Standort und die Abteilung des Benutzers zu bearbeiten.",
  "placeholder": "Bitte Standort wählen",
  "site": "Standort",
  "siteSelectTooltip": "Es ist nicht möglich einen Benutzer in einen anderen Standort zu verschieben.",
  "startUpBoard": "Startboard nach dem Login",
  "startUpSite": "Standort nach dem Login",
  "teamLeaderInfo": "Dieser Benutzer ist Teamleader in der Abteilung"
}

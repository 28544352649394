<template>
  <div>
    <span>{{ $t("components.eventAction.filter.ui.RecentlyChanged.text") }}</span>
    <div class="flex flex-auto">
      <InputNumber v-model.number="numberOfHours" style="font-size: inherit" />

      <Dropdown
        v-model.number="timeMultiplier"
        :options="multiplierOptions"
        option-label="text"
        class="w-100"
        option-value="value"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import RecentlyChangedFilter from "../filterImplementations/RecentlyChangedFilter";

export default defineComponent({
  name: "RecentlyChangedFilterUI",
  components: {
    InputNumber,
    Dropdown,
  },
  inheritAttrs: false,
  setup() {
    const { t } = useI18n();
    const numberOfHours = ref(1);
    const timeMultiplier = ref<1 | 24>(24);
    const multiplierOptions = computed(() => [
      {
        text: t("global.hours"),
        value: 1,
      },
      {
        text: t("global.days"),
        value: 24,
      },
    ]);
    return {
      multiplierOptions,
      getFilter() {
        return new RecentlyChangedFilter(numberOfHours.value * timeMultiplier.value);
      },
      applyFilter(filter: RecentlyChangedFilter) {
        if (filter.definitionOfRecentInHours % 24 === 0) {
          timeMultiplier.value = 24;
          numberOfHours.value = filter.definitionOfRecentInHours / 24;
        } else {
          timeMultiplier.value = 1;
          numberOfHours.value = filter.definitionOfRecentInHours;
        }
      },
      numberOfHours,
      timeMultiplier,
    };
  },
});
</script>

<style lang="scss" scoped>
.border-fix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

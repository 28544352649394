<template>
  <StatusSelectComponent v-model:status="status" multi />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import StatusSelectComponent from "@/components/event-action/task/StatusSelectComponent.vue";
import StatusFilter from "../filterImplementations/StatusFilter";

export default defineComponent({
  name: "StatusFilterUI",
  components: {
    StatusSelectComponent,
  },
  setup() {
    const status = ref<number[]>([]);
    return {
      status,
      getFilter() {
        return new StatusFilter(status.value);
      },
      applyFilter(filter: StatusFilter) {
        status.value = filter.status;
      },
    };
  },
});
</script>

<style lang="scss" scoped></style>

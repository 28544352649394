<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import RecentlyChangedFilter from "../../filterImplementations/RecentlyChangedFilter";
import FilterBadge from "./FilterBadge.vue";

export default defineComponent({
  name: "RecentlyChangedFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<RecentlyChangedFilter>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const text = computed(() => {
      const hours = props.filter.definitionOfRecentInHours;
      if (hours % 24 === 0) {
        return `${t("components.eventAction.filter.ui.RecentlyChanged.textShort")}: ${
          hours / 24
        } ${t(
          "components.eventAction.filter.ui.RecentlyChanged.days",
          Math.round((hours / 24) * 1000) / 1000,
        )}`;
      }
      return `${t("components.eventAction.filter.ui.RecentlyChanged.textShort")}: ${
        Math.round(hours * 1000) / 1000
      } ${t(
        "components.eventAction.filter.ui.RecentlyChanged.hours",
        Math.round(hours * 1000) / 1000,
      )}`;
    });
    return {
      text,
    };
  },
});
</script>

<style lang="scss" scoped></style>

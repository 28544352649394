<template>
  <div
    :style="`display: grid; grid-template-rows: ${
      isMonitorViewEnabled ? '0' : '3.5em'
    } minmax(0, auto) 0`"
    class="h-100 overflow-y-hidden"
  >
    <div class="header">
      <HeadNavbar v-if="!isMonitorViewEnabled" v-model:showSidebar="showSidebar" />
    </div>
    <div class="main h-100">
      <Sidebar
        v-if="!isMonitorViewEnabled && showSidebar"
        v-model:isCollapsed="isMenuCollapsed"
        class="doNotPrintMe"
      />
      <div class="rightContainer">
        <router-view
          ref="mainRouterView"
          class="router-view"
          :class="{ 'background-image': useBackgroundImage }"
        />
      </div>
    </div>
    <Placeholder />
    <RFIDScanComponent ref="rfidComponent" />
    <div id="LightBoxPortal" />
    <Toast position="bottom-right" />
    <ConfirmDialog />
    <GalleryService />
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { DefaultApolloClient } from "@vue/apollo-composable";
import {
  defineComponent,
  provide,
  onMounted,
  ref,
  computed,
  WritableComputedRef,
  InjectionKey,
} from "vue";
import { useRoute } from "vue-router";
import Toast, { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import Sidebar from "@/components/menu/Sidebar.vue";
import HeadNavbar from "@/components/HeadNavbar.vue";
import Placeholder from "@/components/shared/PlaceholderComponent.vue";
import lisaBG from "@/assets/logos/lisa.png";
import smoothScroll from "@/functions/smoothScroll";
import { isModuleActive, LicenseModule } from "@/functions/moduleConfig";
import { setTheme } from "./Highcharts";
import { apolloClient } from "./graphql/apollo";
import RFIDScanComponent from "./components/rfid/RFIDScanComponent.vue";
import GalleryService from "./modules/galleryService/components/GalleryService.vue";

export const MonitorViewInjectionKey = Symbol("MonitorViewInjectionKey") as InjectionKey<
  WritableComputedRef<boolean>
>;

export default defineComponent({
  name: "App",
  components: {
    ConfirmDialog,
    HeadNavbar,
    Sidebar,
    Placeholder,
    RFIDScanComponent,
    Toast,
    GalleryService,
  },
  provide() {
    return {
      lisaLogo: () => lisaBG,
      smoothScroll: (top: number | undefined) => smoothScroll(top),
      disableRFID: () => {
        (this.$refs?.rfidComponent as any)?.disableRFID?.();
      },
      enableRFID: () => {
        (this.$refs?.rfidComponent as any)?.enableRFID?.();
      },
    };
  },
  setup() {
    const isMenuCollapsed = ref(true);
    const monitorViewRef = ref(false);
    const spinnerVisible = ref(false);
    const VITE_APP_MEET_MODULE_ENABLED = ref(isModuleActive(LicenseModule.MEET));
    const route = useRoute();
    const store = useStore();
    const toast = useToast();
    window.$toast = (message: ToastMessageOptions) => {
      /**
       * providing toast to be usable in apollo and api,
       * setting defaults.
       */
      return toast.add({
        ...message,
        life: 3000,
      });
    };
    /**
     * providing i18n to be usable in scripts outside of setup / vue context (apollo, api)
     */
    const i18n = useI18n();
    window.$i18n = i18n as any;

    window.$confirm = useConfirm();

    const isMonitorViewEnabled = computed({
      get() {
        return (route.meta?.hideNavbar as boolean) ?? monitorViewRef.value;
      },
      set(value) {
        monitorViewRef.value = value;
      },
    });
    provide(MonitorViewInjectionKey, isMonitorViewEnabled);

    const useBackgroundImage = computed((): any => {
      return route.meta?.backgroundImage;
    });

    provide(DefaultApolloClient, apolloClient);

    onMounted(async () => {
      if (store.state.login.user != null) {
        setTheme();
      }
    });

    const showSidebar = ref(true);

    return {
      isMenuCollapsed,
      spinnerVisible,
      VITE_APP_MEET_MODULE_ENABLED,
      isMonitorViewEnabled,
      useBackgroundImage,
      showSidebar,
    };
  },
});
</script>

<style scoped>
.rotationTextWrapper {
  line-height: 30px;
  height: 2em;
  overflow: hidden;
  position: absolute;
  text-align: center;
}

.textAnimation {
  animation-name: head;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

@keyframes head {
  0% {
    transform: translateY(0);
  }

  19% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-30px);
  }

  29% {
    transform: translateY(-30px);
  }

  30% {
    transform: translateY(-60px);
  }

  39% {
    transform: translateY(-60px);
  }

  40% {
    transform: translateY(-90px);
  }

  49% {
    transform: translateY(-90px);
  }

  50% {
    transform: translateY(-120px);
  }

  59% {
    transform: translateY(-120px);
  }

  60% {
    transform: translateY(-150px);
  }

  69% {
    transform: translateY(-150px);
  }

  70% {
    transform: translateY(-180px);
  }

  79% {
    transform: translateY(-180px);
  }

  80% {
    transform: translateY(-210px);
  }

  89% {
    transform: translateY(-210px);
  }

  90% {
    transform: translateY(-240px);
  }

  99% {
    transform: translateY(-240px);
  }

  100% {
    transform: translateY(-270px);
  }
}
</style>

<style lang="scss">
body {
  margin: 0;
}

#app {
  height: 100%;
}

/* COMMON STYLES FOR FOLDER LIST */

.sl-vue-tree-node-item:hover,
.sl-vue-tree-node-item.sl-vue-tree-cursor-hover {
  background-color: var(--secondary);
  color: white;
}
</style>
<style>
/* BUTTONS STYLING GLOBAL */
.btn.disabled,
.btn:disabled {
  opacity: 0.5;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
</style>
<style>
.table.b-table thead tr th {
  background-color: var(--primary-color-text) !important;
  color: white !important;
}

.b-table .table-b-table-default {
  border: 0;
}

.b-table-sticky-header {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.main {
  display: flex;

  .rightContainer {
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media print {
  body {
    height: auto !important;

    div {
      height: auto !important;
      grid-template-rows: 0px minmax(0px, auto) 0px !important;

      .main {
        height: auto !important;

        .rightContainer {
          overflow-x: visible !important;
          padding-top: 0 !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" src="@/assets/css/global.scss"></style>

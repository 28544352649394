{
  "anonym": "anonym",
  "anonymUser": "anonymer Benutzer",
  "anonymUserNoPasswordReset": "Anonyme User können die Funktion \"Passwort zurücksetzen\" nicht benutzen!",
  "changelog": "Changelog-Einträge",
  "changelogNotification": "Neue Changelog-Einträge nach dem Login anzeigen",
  "createAnonymUser": "anonymen Benutzer anlegen",
  "darkMode": "Dunkler Modus",
  "darkModeActvive": "Dunklen Modus aktivieren (experimentell)",
  "emailNotification": "E-Mail-Benachrichtigung",
  "generate": "Generieren",
  "hide": "Verbergen",
  "isanonymUser": "Benutzerstatus",
  "normal": "Normal",
  "password": "Passwort",
  "passwordInfo": "Das Passwort muss der Passwortrichtlinie entsprechen und darf nur einmal verwendet werden. Falls ein bereits benutzes Passwort zu speichern versucht wird, erscheint eine rote Infobox am oberen rechten Rand mit einer entsprechenden Meldung.",
  "passwordPolicyFour": "mindestens 12 Zeichen enthalten",
  "passwordPolicyOne": "mindestens einen Kleinbuchstaben",
  "passwordPolicyThree": "mindestens ein Sonderzeichen",
  "passwordPolicyTwo": "mindestens einen Großbuchstaben",
  "print": "Passwort drucken",
  "referenceUser": "Referenz Benutzer",
  "rfid": "RFID",
  "rfidPlaceholder": "Hier klicken und Karte präsentieren",
  "show": "Anzeigen",
  "startpage": "Startseite",
  "taskExpiryDeadlineNotification": "Bitte informiere mich, wenn eine Aufgabe in 24 Stunden abläuft.",
  "taskNotification": "Bitte mich informieren, wenn eine Aufgabe für mich erstellt wurde."
}

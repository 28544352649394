{
  "area1Ktitle": "Fakten/Teambildung",
  "area1KtitleHead": "Fakten",
  "area1title": "Fakten/Teambildung",
  "area1titleHead": "Fakten",
  "area2title": "Problembeschreibung",
  "area2titleHead": "Problembeschreibung",
  "area3Ktitle": "Analyse",
  "area3KtitleHead": "Weiterführende Analyse",
  "area3title": "Sofortmaßnahmen",
  "area3titleHead": "Kurzfristige Sofortmaßnahme einleiten",
  "area4Ktitle": "Lösungssammlung",
  "area4KtitleHead": "Sammlung von Lösungsideen",
  "area4atitle": "Ursachenanalyse (Ishikawa)",
  "area4atitleHead": "Ursachenanalyse",
  "area4btitle": "Ursachenanalyse (5x Warum)",
  "area4btitleHead": "5x Warum - Übernehmen der wahrscheinlichsten Hauptursache(n) aus Ishikawa",
  "area5title": "Maßnahmenfestlegung und Umsetzung",
  "area5titleHead": "Maßnahmen zur Beseitigung der identifizierten Ursache(n)",
  "area6Ktitle": "Wirksamkeitsprüfung",
  "area6KtitleHead": "Beschreibung Nachweis Wirksamkeit (Dummy-Prüfung, Versuche, Kurzzeitfähigkeit, Kennzahlen, Audit, etc.)",
  "area6title": "Wirksamkeitsprüfung",
  "area6titleHead": "Beschreibung Nachweis Wirksamkeit (Dummy-Prüfung, Versuche, Kurzzeitfähigkeit)",
  "area7title": "Standardisierung",
  "area7titleHead": "Absicherung der erfolgreichen Lösung (FMEA, Control Plan, Anweisungen, Schulung, etc.)",
  "area8title": "Lösungstransfer",
  "area8titleHead": "Übertragung der Lösung auf andere Produkte/Prozesse (Lessons Learned)",
  "area9title": "Abschluss",
  "area9titleHead": "Der Problemlösungsfall ist vom Teamleiter in der Wissensdatenbank zu pflegen und dem Management vorzustellen",
  "document": "Dokument"
}

{
  "aggegationDescription": "Unterboards aggregieren",
  "aggregation": "Aggregation",
  "aggregationNoBoards": "Keine Kategorien zum Aggregieren gefunden",
  "andNot": "und nicht",
  "boardsAggregation": "Zu aggregierende Kategorien",
  "categoryId": "Kategorie ID",
  "color1Placeholder": "Erste Farbe der Kachel",
  "color2Placeholder": "Zweite optionale Kachelfarbe",
  "conditions": "Bedingungen",
  "datapointtype": "Datenpunkttyp",
  "eventType": {
    "BIS": "BIS",
    "ChangeRequest": "Änderungsantrag",
    "HLPIdea": "Idee",
    "IHFailureNotice": "Störmeldung",
    "IHMaintenance": "Wartung",
    "Issue": "Problem"
  },
  "eventTypes": "Anlegbare Ereignisse",
  "hideSecondQuestion": "Frage vom zweiten Datenpunkt ausblenden",
  "ifNot": "wenn nicht",
  "infotextForAggregation": "Es werden nur untergeordnete Technische Plätze mit deren Boards angezeigt, wenn diese eine Kreis- oder eine Coil-Kachel aufweisen, die min. einen gleichen Datenpunkttyp ausgewählt hat, nicht verlinkt ist, den gleichen Schichtplan enthält und selbst nicht aggregierend ist.",
  "shiftScheduleId": "Schichtplan",
  "shiftSchedulePlaceholder": "Schichtplan",
  "useSeconddatapointtype": "weiteren Datenpunkttyp hinzufügen",
  "view": "Anzeige",
  "views": {
    "daysSinceLastAccient": "Tage seit dem letzten Vorfall"
  },
  "year": "Kreiserweiterung",
  "yearExtend": "Kreis auf ein Jahr erweitern"
}

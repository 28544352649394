export default {
  endpoints: {
    // Board
    getAll: {
      route: "/",
      method: "GET",
    },
    fetch: {
      route: "fetch",
      method: "POST",
    },
    getForBoard: {
      route: "forBoard/:id",
      method: "GET",
    },
    // Datapoint
    upsert: {
      route: "upsert",
      method: "POST",
    },
    delete: {
      route: ":id",
      method: "DELETE",
    },
    getWithShiftInformation: {
      route: "withShiftInformation",
      method: "POST",
    },
    // Datapoint Issue
    getById: {
      route: "getById/:id",
      method: "GET",
    },
    upsertWithIssue: {
      route: "upsertWithIssue",
      method: "POST",
    },
    linkIssue: {
      route: "linkIssue",
      method: "POST",
    },

    // Datapoint Type
    checkNameAvailability: {
      route: "checkNameAvailability",
      method: "POST",
    },
    getTypeOnly: {
      route: "typeOnly/:id",
      method: "GET",
    },
    getDatapointTypes: {
      route: "types",
      method: "GET",
    },
    getDatapointType: {
      route: "type/:id",
      method: "GET",
    },
  },
};

{
  "header": "Schichtplanverwaltung",
  "listName": "Pläne",
  "options": {
    "cycle": "Zyklus",
    "id": "ID",
    "name": "Name",
    "teams": "Teams",
    "usage": "Verwendung"
  }
}

{
  "ArchiveButton": "Archievieren",
  "backButton": "Zurück",
  "cancelButton": "Abbrechen",
  "frameBreadcrumbs": "Analysieren & Evaluieren",
  "goToPLBButton": "Zu PLB wechseln",
  "loading": "Wird geladen...",
  "saveAndArchiveButton": "Speichern + Archivieren",
  "saveButton": "Speichern",
  "saveCreateTasksButton": "Speichern + Aufgaben anlegen",
  "title": "Änderungsantrag Analysieren & Bewerten"
}

{
  "buttons": {
    "addWidget": "Widget hinzufügen",
    "removeWidget": "Widget entfernen"
  },
  "title": "Dashboard",
  "widgets": {
    "ACTION_COUNTER": {
      "closed": "geschlossene",
      "title": "Aufgaben",
      "unhealthy": "überfällige"
    },
    "CALENDAR": {
      "title": "Kalender"
    },
    "EAB": {
      "title": "Problemlösungsboard"
    },
    "EVENT_COUNTER": {
      "archived": "archivierte",
      "open": "offene",
      "title": "Probleme"
    },
    "PSC": {
      "A3": "A3",
      "A5": "A3",
      "KAIZEN": "Kaizen",
      "jumpToPSC": "Zum A3 springen",
      "title": "Problemlösung"
    },
    "ProblemSolving": {
      "jumpToPSC": "Springe zum A3/A5/KAIZEN"
    },
    "TIMESTAMPCLOCK": {
      "title": "Zeiterfassung"
    }
  }
}

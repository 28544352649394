import { DateTime } from "luxon";
import { EventListEventFieldsFragment } from "@/graphql";
import { EventFilter } from "../FilterTypes";
import DLZFilterUI from "../ui/DLZFilterUI.vue";
import DLZFilterBadge from "../ui/badges/DLZFilterBadge.vue";

export default class DLZFilter implements EventFilter {
  constructor(
    public readonly definitionOfDLZInHours: number,
    public readonly isNegated?: boolean,
  ) {}

  static readonly Badge = DLZFilterBadge;

  static readonly UI = DLZFilterUI;

  isEventActive(event: EventListEventFieldsFragment) {
    const isActive = (() => {
      return this.isInDLZ(
        DateTime.fromISO(event.occurrenceDate),
        event.deletedAt == null ? DateTime.now() : DateTime.fromISO(event.deletedAt),
      );
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isInDLZ(start: DateTime, end: DateTime) {
    return end.diff(start, "hours").hours > this.definitionOfDLZInHours;
  }
}

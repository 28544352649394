{
  "BACKWARDS": "Rückwirkend",
  "CURRENT": "Aktueller Zeitraum",
  "FORWARDS": "Zukünftig",
  "businessYear": "Geschäftsjahr",
  "day": "Tag",
  "month": "Monat",
  "week": "Woche",
  "year": "Jahr"
}

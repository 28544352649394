{
  "buttons": {
    "createButton": "Neuen Standort erstellen"
  },
  "header": "Standorte",
  "list": {
    "name": "Standort",
    "nameMulti": "Standort | Standorte",
    "options": {
      "city": "Stadt",
      "color": "Farbe",
      "country": "Land",
      "id": "ID",
      "ipAddress": "IP Adressbereich",
      "name": "Name",
      "tz": "Zeitzone"
    }
  }
}

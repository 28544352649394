{
  "categoryLabel": "Technischer Platz:",
  "configModalTitle": "Datenpunkttyp und Technischer Platz bearbeiten",
  "daily": "Täglich",
  "dataTypeLabel": "Datentyp:",
  "datapointPlaceholder": "Datenpunkttyp auswählen",
  "datapointTyp": "Datentyp",
  "goal": "Ziel",
  "interval": "Intervall",
  "kpiPlaceholder": "Wähle KPI-Datenpunkt",
  "monthly": "Monatlich",
  "noCategory": "Kein Technischer Platz",
  "noDataType": "Kein Datentyp",
  "weekly": "Wöchentlich",
  "yearly": "Jährlich"
}

<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import DescriptionFilter from "../../filterImplementations/DescriptionFilter";
import FilterBadge from "./FilterBadge.vue";

export default defineComponent({
  name: "DescriptionFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<DescriptionFilter>,
      required: true,
    },
  },
  setup(props) {
    const text = computed(() => {
      return props.filter.searchText;
    });
    return {
      text,
    };
  },
});
</script>

<style lang="scss" scoped></style>

{
  "automatically": "Automatisch",
  "boardName": "Board",
  "category": "Technischer Platz",
  "confirmation": "Wollen Sie wirklich diese Kachel löschen?",
  "copyTile": "Kachel kopieren",
  "copyTileAndChangeBoard": "Kopieren und auf Board wechseln",
  "costCenter": "Kostenstelle",
  "createTile": "Kachel anlegen",
  "createTileAndClose": "Kachel anlegen und schliessen",
  "daily": "Täglich",
  "dailyPast": "Täglich (Vortag)",
  "dataSource": "Datenquelle",
  "dataSourcePlaceholder": "Die Datenquelle für die Kachel",
  "deleteTile": "Kachel löschen",
  "description": "Beschreibung",
  "descriptionPlaceholder": "Wird in der Kachel beim '?' angezeigt",
  "flags": "Flags",
  "ifRequired": "Bei Bedarf",
  "interval": "Intervall",
  "intervalPlaceholder": "Bitte Intervall auswählen",
  "monthly": "Monatlich",
  "name": "Name",
  "never": "Nie",
  "owner": "Verantwortlicher (Rolle)",
  "quarterly": "Quartalsweise",
  "saveTile": "Kachel speichern",
  "selectBoard": "Wohin soll die Kachel kopiert werden?",
  "shift": "Schicht",
  "technicalCategory": "Technische Kategorie",
  "technicalPlace": "Technischer Platz",
  "tileName": "Name der Kachel",
  "tileOwner": "Verantwortlicher der Kachel",
  "undefined": "Benutzerdefiniert",
  "weekly": "Wöchentlich",
  "yearly": "Jährlich"
}

{
  " hourLabel": "Stunde",
  " totalEstimatedEffortsLabel": "Geschätzter Gesamtaufwand",
  "approvedLabel": "Genehmigt",
  "benefitAddLabel": "Tippen Sie hier ein, um neuen Nutzen  hinzuzufügen!",
  "benefitLabel": "Nutzen:",
  "benefitPlaceholder": "Nutzen",
  "changeCostsLabel": "Kosten:",
  "changeCostsPlaceholder": "Wieviel kostet die Umsetzung der Änderung?",
  "deadlineLabel": "Frist:",
  "effectedServicesButton": "Hinzufügen",
  "effectedServicesLabel": "Betroffene Module:",
  "effectedServicesPlaceholder": "Welche Module können mit der neuen Änderung betroffen sein?",
  "hourLabel": "Stunde",
  "impactAddLabel": "Tippen Sie hier ein, um neue Auswirkung hinzuzufügen!",
  "impactLabel": "Auswirkungen:",
  "impactPlaceholder": "Auswirkung",
  "postponedLabel": "Aufgeschoben",
  "projectManagerLabel": "Projektmanager:",
  "projectManagerPlaceholder": "Wer leitet das Projekt?",
  "proposedActionAddLabel": "Tippen Sie hier ein, um neue vorgeschlagene Aktion hinzuzufügen!",
  "proposedActionLabel": "Vorgeschlagene Aktionen:",
  "proposedActionPlaceholder": "Vorgeschlagene Aktion",
  "rejectedLabel": "Abgelehnt",
  "riskAddLabel": "Tippen Sie hier ein, um neues Risiko hinzuzufügen!",
  "riskLabel": "Risiken:",
  "riskPlaceholder": "Risiko",
  "statusLabel": "Status:",
  "statusReasonLabel": "Statusgrund:",
  "statusReasonPlaceholder": "Warum ist Änderungsantrag {status}",
  "statusTextAPPROVED": "Genehmigt",
  "statusTextCLOSED": "Abgeschlossen",
  "statusTextIMPLEMENTED": "Umgesetzt",
  "statusTextIN_IMPLEMENTATION": "In der Bearbeitung",
  "statusTextOPENED": "Geöffnet",
  "statusTextPOSTPONED": "Aufgeschoben",
  "statusTextREJECTED": "Abgelehnt",
  "technicalResponsibleLabel": "Technisch verantwortliche Person:",
  "technicalResponsiblePlaceholder": "Welche Mitarbeiter sind für die Analyse des Änderungsantrags technisch verantwortlich?",
  "totalEstimatedEffortsLabel": "Geschätzter Gesamtaufwand:"
}

{
  "description": "Beschreibung",
  "descriptionSchedule": "Eine Beschreibung des Zeitplanes",
  "location": "Ort",
  "name": "Name",
  "nameSchedule": "Der Name des Zeitplanes",
  "newShift": " Neue Schicht",
  "newShiftPlan": "Neuen Schichtplan anlegen",
  "requiredMinOneRow": "Es wird mindestens eine Schicht benötigt um einen Schichtplan anzulegen",
  "short": "Kürzel",
  "title": "Titel"
}

<template>
  <div class="status-circle">
    <svg height="24" width="24" viewBox="-55 -52 110 110" xmlns="http://www.w3.org/2000/svg">
      <g stroke="rgb(168, 168, 168)" stroke-alignment="inside" stroke-width="5">
        <path d="M0 0 50 0A50 50 0 0 0-0-50Z" :fill="colorForPart(1)" />
        <path d="M0 0 0 50A50 50 0 0 0 50 0Z" :fill="colorForPart(2)" />
        <path d="M0 0 -50 0A50 50 0 0 0 0 50Z" :fill="colorForPart(3)" />
        <path d="M0 0-50 0A50 50 0 0 1-0-50Z" :fill="colorForPart(4)" />
        <circle v-if="lucasMode" fill="white" cx="0" cy="0" r="30" />
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TaskStatus } from "@/graphql";
import isModifierSet, { ModifierType } from "@/functions/userModifier";

export default defineComponent({
  name: "StatusCircleComponent",
  props: {
    status: {
      type: Number as PropType<TaskStatus>,
      required: true,
    },
  },
  setup(props) {
    const colorForPart = (part: number) => {
      if (props.status === TaskStatus.act) {
        return "forestgreen";
      }
      if (part <= props.status) {
        return "#333333";
      }
      return "white";
    };
    return {
      colorForPart,
      lucasMode: isModifierSet(ModifierType.lucasStatusMode),
    };
  },
});
</script>

<style lang="scss" scoped>
.status-circle {
  display: inline-block;
}
</style>

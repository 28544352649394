{
  "2buttonsTitle": "Aktion wählen",
  "FaultNotice": "Störmeldung anlegen",
  "bis": {
    "failure": "Ausfall",
    "noFailure": "Kein Ausfall",
    "priority": {
      "date": "Plan-Datum",
      "prio0": "Notfall sofort",
      "prio1": "24h gleicher Tag",
      "prio2": "2-3T dringend",
      "prio3": "4-10T Standard",
      "prio4": "11-20T geringes Risiko",
      "prio5": "3M kaum Risiko",
      "prio6": "Mittelfristig",
      "prio7": "Langsfristig"
    },
    "priorityLabel": "Priorität",
    "tp": "Technischer Platz",
    "tpCategory": "Technische Kategorie"
  },
  "bisDefaultDescription": "Störmeldung wurde an BIS gesendet.",
  "bisFaultMessage": "BIS - Störmeldung",
  "category": "Board",
  "createIssue": "Problem anlegen",
  "createTP": "Technischen Platz wählen",
  "createTask": "Aufgabe anlegen",
  "currentBoard": "Aktuelles Board",
  "deleted": "Archiviert",
  "description": "Beschreibung",
  "faultMessage": "IH-Mail - Störmeldung",
  "id": "ID",
  "infoArchivedIssues": "Archivierte Probleme können aktuell nicht verlinkt werden",
  "informBIS": "BIS informieren",
  "informIH": "Instandhaltung informieren",
  "linkEvent": "Problem verknüpfen",
  "linkIssue": "Ereignis verknüpfen",
  "maintenanceOrder": "IH-Auftrag anlegen",
  "newEvent": "Neues Problem anlegen",
  "newEventAt": "Neues Problem um",
  "noSpecialEvent": "Keine besonderen Probleme",
  "originalBoard": "Ursprungsboard",
  "overview": "Problem erfassen",
  "overviewAggregate": "Probleme anzeigen",
  "responsible": "Besitzer",
  "shiftClear": "Feld leeren",
  "shiftNoHappened": "Schicht unbesetzt",
  "shiftNoHappenedInTechnicalPlace": "Schicht unbesetzt (gesamtes Board)",
  "showEvents": "Probleme anzeigen",
  "toasts": {
    "createDatapoint": "Datenpunkt wurde erstellt",
    "createDatapointAndEvent": "Ereignis und Datenpunkt wurden erstellt",
    "createShiftStatus": "Schichtstatus wurde erstellt",
    "deleteDatapoint": "Datenpunkt wurde geleert",
    "deleteShiftStatus": "Schichtstatus wurde geleert"
  }
}

<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from "vue";

export default defineComponent({
  name: "LModalObserverInjector",
  props: {
    touched: {
      type: Boolean,
      default: false,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:dirty", "update:touched"],
  setup(props, { emit }) {
    const dirty = computed(() => props.dirty);
    const touched = computed(() => props.touched);
    watch(dirty, () => emit("update:dirty", dirty.value));
    watch(touched, () => emit("update:touched", touched.value));
  },
});
</script>

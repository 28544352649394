<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { SQDCBoardIssueConnectionTypes } from "@/graphql";
import BoardOriginFilter from "../../filterImplementations/BoardOriginFilter";
import FilterBadge from "./FilterBadge.vue";

export default defineComponent({
  name: "BoardOriginFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<BoardOriginFilter>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const text = computed(() => {
      const originString = SQDCBoardIssueConnectionTypes[props.filter.boardOrigin];
      return t(`components.eventAction.filter.badges.BoardOriginFilter.${originString}`);
    });
    return {
      text,
    };
  },
});
</script>

<style lang="scss" scoped></style>

{
  "User": "Benutzer hinzufügen",
  "addUser": "Benutzerzuweisung",
  "admin": "Adminrolle (Diese Rolle kann nur von Admins vergeben werden)",
  "badgeUserCount": "Benutzer",
  "deleteButton": "Rolle löschen",
  "deleteButtonTooltip": "Diese Rolle kann nicht gelöscht werden, da Sie mit Benutzern verknüpft ist",
  "deleteModalAbort": "Abbrechen",
  "deleteModalButton": "Rolle löschen",
  "deleteModalPhrase": "Soll die Rolle {name} unwiederruflich gelöscht werden?",
  "deleteModalTitle": "Rolle löschen",
  "depententOnSite": "Standortabhängige Rechte",
  "descriptionLabel": "Beschreibung",
  "hasRight": "Rolle",
  "labelCheckboxes": "Berechtigungen",
  "labelName": "Bezeichnung",
  "labelUsers": "Benutzer mit dieser Rolle",
  "module": {
    "accessRight": "Zugriffsrechte",
    "assignment": "Maschinenbelegung",
    "audit": "Audit",
    "calendar": "Kalender",
    "changeRequest": "Änderungsanträge",
    "customerManagement": "Kundenverwaltung",
    "datapoint": "Datenpunkt",
    "department": "Organisation",
    "departmentOrganization": "Abteilungsverwaltung",
    "document": "Dokumente",
    "flags": "Flags",
    "folder": "Ordnerverwaltung",
    "hlp": "Vorschlagswesen",
    "ih": "Instandhaltung",
    "instance": "Instanzverknüpfung",
    "kaizen": "Kaizen",
    "lisapp": "LISApp",
    "lisappAudit": "lisapp (Audit)",
    "lisappSQDC": "lisapp (SQDC)",
    "mm": "WMS",
    "mqtt": "IoT",
    "news": "News",
    "psc": "Problemlösung",
    "reporting": "Reporting",
    "role": "Rollen",
    "shiftSchedule": "Schichtplan",
    "siteOrganization": "Standortverwaltung",
    "sqdc": "SQDC",
    "systemSettings": "System Einstellungen",
    "target": "Zielwerte",
    "task": "Aufgaben",
    "timestampclock": "Zeiterfassung",
    "user": "Benutzerverwaltung"
  },
  "notDepententOnSite": "Standortunabhängige Rechte (Wenn ein Benutzer eine Rolle mit diesem Recht hat, gilt dieses Recht automatisch für alle Standorte)",
  "rights": {
    "IHFailureNotice": "Störmeldungen anlegen und bearbeiten",
    "IHMaintenance": "Wartungen",
    "IHMaintenanceOrderWrite": "IH-Aufträge anlegen",
    "IHOrder": "Aufträge",
    "admin": "Verwaltung",
    "audit": "Audit",
    "competenceMatrix": {
      "admin": "Qualifikationsmatrix | alle Abteilungen anzeigen",
      "write": "Qualifikationsmatrix schreiben"
    },
    "competences": {
      "write": "Stammkompetenzen schreiben"
    },
    "createAnonymUser": "Benutzer anonymisieren",
    "createAudit": "Neues Audit anlegen",
    "createAuditResult": "Audit durchführen",
    "delete": "Löschen",
    "event": {
      "edit": "Termine anlegen und bearbeiten"
    },
    "eventActionBoard": "Neues Problemlösungsboard",
    "eventActionBoardDefault": "Neues Problemlösungsboard als Default",
    "export": "Exportieren",
    "issueChange": "Ereignisse nachträglich ändern",
    "kaizenActive": {
      "read": "Aktive lesen",
      "write": "Aktive schreiben"
    },
    "kaizenInactive": {
      "read": "Inaktive lesen",
      "write": "Inaktive schreiben"
    },
    "mailDistribution": "E-Mail-Verteiler ändern",
    "ordering": "Bestellungen aufgeben",
    "pscActive": {
      "read": "Aktive lesen",
      "write": "Aktive schreiben"
    },
    "pscInactive": {
      "read": "Inaktive lesen",
      "write": "Inaktive schreiben"
    },
    "read": "Lesen",
    "readWrite": "Administration",
    "role": "Rollen zuweisen",
    "sqdc": "SQDC",
    "transferEvent": "Ereignisse weiterleiten",
    "type": "Bearbeiten",
    "vote": "In Umfragen abstimmen",
    "write": "Schreiben"
  },
  "roleList": "Rollen",
  "site": "Standort",
  "tooltipBadge": "Zu diesem Benutzer gehen",
  "userName": "Anzeigename",
  "user_info": "Derzeit haben {amount} Benutzer diese Rolle."
}

{
  "CUSTOM": "Benutzerdefiniert",
  "DAILY": "Tägliche Eingabe",
  "WEEKLY": "Wöchentliche Eingabe",
  "granularity": "Granularität",
  "modal": {
    "title": "Granularität bearbeiten"
  },
  "series": "Serienmuster"
}

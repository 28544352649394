import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { App } from "vue";
import router from "./router";
import { ENV } from "@/ENV";

export function generateTransactionId() {
  return Math.random().toString(36).slice(2);
}

/**
 * a runtime static transaction id.
 */
const runtimeStaticTransactionId = generateTransactionId();

export function transactionId() {
  return `${runtimeStaticTransactionId}_${generateTransactionId()}`;
}
export function initializeSentry(app: App<Element>) {
  if (import.meta.env.PROD && !import.meta.env.BASE_URL.includes("deploy-preview")) {
    Sentry.init({
      app,
      dsn: "https://9615c047ffea4ffb9ea099c71870ec3c@o1035142.ingest.sentry.io/6001913",
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "lisapp.de", /^\//],
        }),
      ],
      logErrors: true,
      environment: import.meta.env.MODE,
      enabled: import.meta.env.PROD,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      release: import.meta.env.__VERSION__,
      ignoreErrors: [
        // This error is not relevant, we'll ignore it
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        "ResizeObserver loop limit exceeded",
        // Remove the errors that occur as redacted in production.
        "REDACTED",
        // "Unable to fetch dynamic resource" is a very common issue that happens if there was a LISA update deployed in the mean time.
        "Unable to fetch dynamic resource",
      ],
    });
    Sentry.setTag("instance", ENV.VITE_APP_INSTANCE_KEY);
  }
}

{
  "EABTypes": {
    "AuditResult": "Audit",
    "ChangeRequest": "Änderungsantrag",
    "HLPIdea": "Idee",
    "IHFailureNotice": "Störmeldung",
    "IHMaintenance": "Wartung",
    "IHOrder": "Instandhaltungsauftrag",
    "Issue": "Problem",
    "PSC": "A3 / A5 / Kaizen",
    "Task": "Aufgabe"
  },
  "add": "Hinzufügen",
  "addDate": "Bitte Datum eingeben",
  "all": "Alle",
  "back": "Zurück",
  "cancel": "Abbrechen",
  "cancelMsgBox": "Wollen sie wirklich abbrechen?",
  "check": "Überprüfen",
  "close": "Schließen",
  "confirm": "Bitte bestätigen",
  "create": "Anlegen",
  "days": "Tage",
  "delete": "Löschen",
  "deleted": "gelöscht",
  "deletedUser": "Gelöschter Benutzer",
  "edit": "Bearbeiten",
  "error": "Fehler",
  "eventActionBoard": "Problemlösungsboard",
  "hours": "Stunden",
  "id": "ID",
  "imageNotFound": "Das Bild wurde nicht gefunden",
  "info": "Information",
  "information": "Information",
  "joinList": {
    "more": "weitere"
  },
  "loading": "Wird geladen",
  "missingEvent": "Das Event konnte nicht dargestellt werden. Bitte wenden Sie sich an einen Administrator.",
  "name": "Name",
  "new": "Neu",
  "newTemplate": "Neue Vorlage",
  "next": "Weiter",
  "no": "Nein",
  "noResult": "Keine Elemente gefunden.",
  "notAvailable": "Feature ist aktuell nicht verfügbar.",
  "password": "Passwort",
  "reactivate": "Reaktivieren",
  "reload": "Aktualisieren",
  "reset": "Zurücksetzen",
  "return": "Zurück",
  "rotate": "Drehen",
  "save": "Speichern",
  "saveChanges": "Änderungen speichern",
  "selectOption": "Bitte wählen",
  "send": "Senden",
  "site": "Standort",
  "success": "Erfolg!",
  "successful": "Erfolgreich!",
  "temporaryDisabled": "Diese Funktion ist temporär deaktiviert.",
  "transfer": "Weiterleiten",
  "update": "Ändern",
  "upload": "Upload",
  "url": "URL",
  "weekdays": [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag"
  ],
  "yes": "Ja"
}

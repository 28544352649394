import { ActionType, EventType } from "@/graphql";

export const eventIdPrefix: Record<EventType, string> = {
  [EventType.AuditResult]: "A",
  [EventType.IHFailureNotice]: "F",
  [EventType.IHMaintenance]: "M",
  [EventType.Issue]: "I",
  [EventType.HLPIdea]: "P",
  [EventType.ChangeRequest]: "C",
};

export const actionIdPrefix: Record<ActionType, string> = {
  [ActionType.IHOrder]: "O",
  [ActionType.PSC]: "A",
  [ActionType.Task]: "T",
};

export function idPrefix(type: string | undefined): string {
  if (type === undefined) {
    throw new Error("type is undefined");
  }
  return eventIdPrefix[type as EventType];
}

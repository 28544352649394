import { defaultDataIdFromObject, InMemoryCache, StoreObject } from "@apollo/client/core";
import { markRaw } from "vue";
import fragmentTypes from "./fragmentTypes.json";

export default markRaw(
  new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          meetings: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Subscription: {
        fields: {
          meetings: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Meeting: {
        keyFields: ["roomName"],
      },
      SystemSettings: {
        merge(existing, incoming) {
          // Merge the existing system settings with the new one
          return { ...existing, ...incoming };
        },
      },
      TCVacationListEntry: {
        keyFields: () => false,
      },
      MQTTDevice: {
        keyFields: (object: Readonly<StoreObject>) => {
          return defaultDataIdFromObject({ __typename: object.__typename, id: object.deviceId });
        },
      },
      SQDCBoard: {
        keyFields: (object: Readonly<StoreObject>) => {
          if ("BoardIssueConnection" in object || "FlagBoardConnection" in object) {
            return false;
          }
          return defaultDataIdFromObject(object);
        },
      },
      User: {
        keyFields: (object: Readonly<StoreObject>) => {
          if ("HLPIdeaUserConnection" in object) {
            return false;
          }
          return defaultDataIdFromObject(object);
        },
      },
      GroupedTarget: {
        keyFields: (object: Readonly<StoreObject>) => {
          // @ts-expect-error types not correct
          const costCenterId = object.costCenter?.id;
          // @ts-expect-error types not correct
          const typeId = object.type?.id;
          if (costCenterId == null || typeId == null) {
            return false;
          }
          return `GroupedTarget:${costCenterId}|${typeId}`;
        },
      },
      GroupedTargetValue: {
        keyFields: (object: Readonly<StoreObject>) => {
          if (object.id == null) {
            return false;
          }
          return `Target:${object.id}`;
        },
      },
      AssignmentConfig: {
        keyFields: (object: Readonly<StoreObject>) => {
          if (object.tileId == null) {
            return false;
          }
          return `AssignmentConfig:${object.tileId}`;
        },
      },
      DBWidget: {
        keyFields: (object: Readonly<StoreObject>) => {
          return `DBWidget:${object.type}`;
        },
      },
    },
  }),
);

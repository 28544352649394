{
  "buttons": {
    "addMaterial": "Material hinzufügen",
    "basket": "Warenkorb"
  },
  "bvModal": {
    "cancelTitle": "Weitere Materialien verbuchen",
    "okTitle": "Warenkorb speichern",
    "text": "Möchten Sie den Warenkorb speichern oder weiteres Material verbuchen?"
  },
  "mode": {
    "create": {
      "title": "Lagerbestände füllen"
    },
    "list": {
      "title": "Bestellliste"
    }
  },
  "step1": {
    "fields": {
      "material": {
        "label": "Material"
      },
      "quantity": {
        "label": "Anzahl"
      },
      "storageLocation": {
        "label": "Lagerplatz"
      },
      "warehouse": {
        "label": "Lager"
      }
    },
    "list": {
      "title": "Aktueller Bestand"
    },
    "table": {
      "errors": {
        "materialNotFound": "Das Material befindet sich aktuell nicht an diesem Lagerplatz.",
        "noMaterial": "Bitte Material auswählen",
        "noStorageLocation": "Bitte Lagerplatz auswählen"
      }
    }
  }
}

import { LocaleMessageObject, IntlDateTimeFormats, createI18n, Locale } from "vue-i18n";
import { DateTimeFormatOptions, Settings as LuxonSettings } from "luxon";
import { computed } from "vue";
import { merge, set } from "lodash";
import DateTimeFormat from "@/language/DateTimeFormats";
import VueI18nLanguageLoader, {
  languageNames,
  defaultLanguageMessages,
  defaultLanguage,
} from "@/language";
import store from "./store";
import { setHighchartsLocale } from "./Highcharts";

export const datetimeFormats: IntlDateTimeFormats = {};

const DTF = Intl.DateTimeFormat;
const NF = Intl.NumberFormat;
Intl.DateTimeFormat = new Proxy(DTF, {
  construct(target, argArray) {
    if (typeof argArray[0] === "string") {
      argArray[0] = argArray[0].replaceAll("_", "-");
    } else {
      argArray[0] = argArray[0]?.map((locale: string) => locale.replaceAll("_", "-")) ?? [];
    }
    return new DTF(...argArray);
  },
});

Intl.NumberFormat = new Proxy(NF, {
  construct(target, argArray) {
    if (typeof argArray[0] === "string") {
      argArray[0] = argArray[0].replaceAll("_", "-");
    }
    return new NF(...argArray);
  },
});

export const LISA_DATE_SHORT: DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

export const LISA_DATE_FULL: DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

Object.keys(languageNames).forEach((lang: string) => {
  datetimeFormats[lang] = DateTimeFormat;
});

defaultLanguageMessages[defaultLanguage].dynamic = {};

const translationUnavailableTranslation: Record<keyof typeof languageNames, string> = {
  de: "🌐 Keine Übersetzung gepflegt",
  en: "🌐 No translation maintained",
  en_GB: "🌐 No translation maintained",
  fr: "🌐 Aucune traduction n'est maintenue",
  es: "🌐 No se mantiene la traducción",
  pt: "🌐 Nenhuma tradução mantida",
  hu: "🌐 Nincs fenntartott fordítás",
  pl: "🌐 Nie zachowano tłumaczenia",
  it: "🌐 Nessuna traduzione trovata",
  uk_UA: "🌐 Перекладу не знайдено",
};

const i18n = createI18n({
  legacy: false,
  datetimeFormats,
  locale: defaultLanguage,
  warnHtmlInMessage: "off",
  warnHtmlMessage: false,
  allowComposition: true,
  fallbackLocale: ["en", "en_GB", "de"], // This fallback locale is meant for dynamic keys. We assume that there are no static keys that are empty in a specific language.
  missing: (lang, key) => {
    if (key.startsWith("dynamic.")) {
      if (key === "dynamic.undefined") {
        /* eslint-disable no-console */
        console.groupCollapsed(
          `A component failed setting a correct dynamic translation key. Language ${lang}, key: dynamic.undefined`,
        );
        console.trace(); // hidden in collapsed group
        console.groupEnd();
        /* eslint-enable no-console */
        return "";
      }
      return (
        translationUnavailableTranslation[lang as keyof typeof languageNames] ??
        translationUnavailableTranslation.en
      );
    }
    // A static key is found that is not translated. Create a warning.
    // eslint-disable-next-line no-console
    console.error(`An untranslated key (${key}) was found in language ${lang}`);

    if (import.meta.env.DEV) {
      return `["${key}" missing in ${lang}]`;
    }
    return "\xa0"; // <-- This is not a regular space, its a non-breaking space (https://en.wikipedia.org/wiki/Non-breaking_space)
  },
  messages: defaultLanguageMessages,
  silentTranslationWarn: / dynamic.*/,
});

export const loadedLanguages = [defaultLanguage]; // our default language that is preloaded

function setI18nLanguage(lang: string) {
  setHighchartsLocale(lang);
  i18n.global.locale.value = lang;
  LuxonSettings.defaultLocale = lang;
  document.querySelector("html")?.setAttribute("lang", lang);
  // Update internal PrimeVue locales

  if (
    window.$primevue &&
    window.$primevue?.config.locale &&
    i18n.global.messages.value[lang].primevue
  ) {
    const sourceLang = i18n.global.messages.value[lang].primevue;

    /**
     * vue-i18n's JSON schema is incompatible to the schema of primevue
     * so we have to convert it a bit before it gets written to prime vue
     * check the example!
     *
     * Examples:
     * i18n Objects "foo.bar.baz": "Some String"; --> primevue Objects foo: { bar: { baz: "Some String" } };
     */

    Object.keys(sourceLang).forEach((langKey) => {
      const key = langKey;
      const value = sourceLang[langKey];
      const builder = {} as any;
      /**
       * determine if the key should be an object
       */
      if (key.includes(".")) {
        const topLevelkey = key.split(".")[0];
        /**
         * it is an object
         * using lodash we can reconstruct the object in any depth and merge the existing (previous) objects together
         */
        set(builder, key, value);
        (window.$primevue!.config.locale as Record<string, any>)[topLevelkey] = merge(
          (window.$primevue!.config.locale as Record<string, any>)[topLevelkey],
          (window.$primevue!.config.locale as Record<string, any>)[topLevelkey],
          builder[topLevelkey],
        );
      } else {
        (window.$primevue!.config.locale as Record<string, any>)[key] = value;
      }
    });
  }
  return lang;
}

export function mergeLocaleMessage(locale: Locale, message: LocaleMessageObject) {
  i18n.global.mergeLocaleMessage(locale, message);
  // unfortunately we have to trigger the reactivity of the messages object here manually, for up to now unknown reasons.
  // // @ts-expect-error _vm is not public, therefore we expect an error here.
  // // eslint-disable-next-line no-underscore-dangle
  // Vue.set(window.$root.$i18n._vm.messages, "reactivityFix", Math.random());
}

export async function loadLanguageAsync(lang: string = defaultLanguage, dynamicLocale?: any) {
  store.dispatch("clientCache/setLastLocale", lang);
  // If the same language or if the language was already loaded
  if (i18n.global.locale.value === lang || loadedLanguages.includes(lang)) {
    if (dynamicLocale) {
      mergeLocaleMessage(lang, dynamicLocale);
    }
    return setI18nLanguage(lang);
  }
  // If the language hasn't been loaded yet
  // Check if locale exists, otherwise fall back
  const messagesLoaded = await VueI18nLanguageLoader[lang]();
  let messages;
  if (dynamicLocale != null) {
    messages = { ...dynamicLocale, ...messagesLoaded[lang] };
  } else {
    messages = messagesLoaded[lang];
  }

  // Load dynamic languages
  mergeLocaleMessage(lang, messages);

  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}

export async function insertLanguageLocal(locale: string, translationsToMerge: any) {
  const currentLocale = locale ?? i18n.global.locale.value;

  const existingLanguage = i18n.global.messages.value;

  const identifiers = Object.keys(translationsToMerge).map((el) => Number(el));

  identifiers.forEach((el) => {
    existingLanguage[currentLocale].dynamic[el] = translationsToMerge[el][currentLocale];
  });
  i18n.global.setLocaleMessage(currentLocale, existingLanguage[currentLocale]);
}

export default i18n;
export const languageKeys = Object.keys(languageNames);
export const locale = computed(() => {
  const currentLocale = (window.$i18n?.locale.value ?? "de").replace("_", "-");
  if (currentLocale === "uk-UA") {
    return "en";
  }
  return currentLocale;
});

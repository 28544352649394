{
  "active": "Ausgehende Verbindung blockieren",
  "alert": "Sie können mit STRG+V die Zugangsdaten von der eingehenden Verbindung in das Zielsystem einfügen, solange kein Eingabefeld ausgewählt ist.",
  "clientId": "Client ID",
  "clientIdTooltip": "Die Client ID (Systemkennung) muss im Zielsystem kopiert werden.",
  "jwtTooltip": "Der Sicherheitsschlüssel muss im Zielsystem untern \"Eingehende Verbindungen\" erstellt werden.",
  "ping": "Ausgehende Verbindung testen",
  "secret": "Sicherheitsschlüssel",
  "targetInstanceName": "Zielinstanz",
  "testConnection": "Verbindung testen"
}

<template>
  <div class="status-component">
    <div
      v-for="index in 4"
      :key="`status${index}`"
      class="pt-1 ml-custom mr-custom"
      :class="{
        'selected-status': multiStatus.includes(index),
        'ml-0': index === 1,
        'mr-0': index === 4,
      }"
      @click.stop="select(index)"
    >
      <StatusCircleComponent :status="index" />
      <p class="m-0">
        {{ $t(`components.TaskModal.pdcaCircle${index}`) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import StatusCircleComponent from "./StatusCircleComponent.vue";

export default defineComponent({
  name: "StatusSelectComponent",
  components: { StatusCircleComponent },
  props: {
    status: {
      required: true,
      type: [Number, Array] as PropType<number | number[]>,
    },
    multi: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:status"],
  setup(props, { emit }) {
    if (props.multi !== Array.isArray(props.status)) {
      throw new Error(
        "if using StatusSelectComponent with multi, please provide an Array and vice versa",
      );
    }
    const multiStatus = computed<number[]>({
      get: () => {
        if (Array.isArray(props.status)) {
          return props.status;
        }
        return [props.status];
      },
      set: (val) => {
        if (props.multi) {
          emit("update:status", val);
        } else {
          emit("update:status", val[0]);
        }
      },
    });

    return {
      multiStatus,
      select(index: number) {
        // Check if multi mode
        if (props.multi && Array.isArray(props.status)) {
          // If yes, check if the status array already includes the selected value
          if (props.status.includes(index)) {
            // emit the status array without the selected value
            emit(
              "update:status",
              props.status.filter((value) => value !== index),
            );
          } else {
            // add the selected value and sort the array
            emit("update:status", [...props.status, index].sort());
          }
        } else {
          // No multi mode. Just select the index
          emit("update:status", index);
        }
      },
    };
  },
});
</script>

<style lang="scss" src="@/components/event-action/actionStatusSelect.scss"></style>

{
  "AuditName": "Bezeichnung:",
  "addQuestion": "Frage hinzufügen",
  "addSection": "Abschnitt hinzufügen",
  "error": "Fehler:",
  "invalidSection": "Es muss mindestens ein Abschnitt angelegt werden",
  "question": "Frage {index}:",
  "questionInvalidText": "Es muss mindestens eine Frage pro Abschnitt angelegt werden",
  "questionNumber": "Fragen",
  "questionNumberOne": "Frage",
  "removeQuestion": "Wollen sie die Frage wirklich löschen?",
  "removeSection": "Wollen sie den Abschnitt wirklich verwerfen?",
  "section": "Abschnitt {index}:"
}

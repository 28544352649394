{
  "additionalInfo": "Ergänzende Informationen:",
  "additionalInfoPlaceholder": "Gibt es weitere Informationen?",
  "location": "Standort",
  "place": "Bereich/Arbeitsplatz",
  "placePlaceholder": "Wo ist das Problem entstanden?",
  "problem": "Problem/Untersuchungsbereich:",
  "problemPlaceholder": "Welcher Bereich soll untersucht werden? Was soll untersucht werden? Mit welchem Problem wird sich beschäftigt?",
  "product": "Erzeugnis/Bauteil",
  "productPlaceholder": "Was ist betroffen?",
  "qualGoal": "Qualitative Ziele",
  "qualGoalPlaceholder": "Welche qualitative Ziele werden verfolgt?",
  "quantGoal": "Quantitative Ziele",
  "quantGoalPlaceholder": "Welche quantitative Ziele werden verfolgt?",
  "skizze": "Skizze/Foto",
  "title": "Fakten"
}

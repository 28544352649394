{
  "buttons": {
    "createButton": "Layout anlegen",
    "publish": "Veröffentlichen",
    "published": "Veröffentlicht"
  },
  "dynamic": {
    "addCalculation": {
      "buttons": {
        "add": "Berechnung hinzufügen"
      },
      "calcTypeName": "Berechnung",
      "calcTypes": {
        "addition": "Addition",
        "common": "Durchschnitt",
        "cummulate": "Kommulation",
        "dayline": "60-Werte Linie",
        "division": "Division",
        "empty": "Bitte Berechnung auswählen",
        "subtraction": "Subtraktion"
      },
      "chartTypeColumn": "Säule",
      "chartTypeEmpty": "Bitte Typ auswälen",
      "chartTypeLabel": "Charttyp wählen",
      "chartTypeLine": "Linie",
      "chartTypegroupedColumn": "grupp. Säule",
      "colorLabel": "Farbe:",
      "dataTypeLabel": "Datentyp hinzufügen",
      "dataTypePlaceholder": "Datentyp auswählen",
      "fields": {
        "calculation": "Berechnung",
        "id": "ID",
        "name": "Name"
      },
      "formulaLabel": "Formel",
      "formulaNoFormula": "Keine Formel zum anzeigen vorhanden",
      "formulaTypeCount": "Typenanzahl",
      "formulaTypeLabel": "Typ",
      "nameLabel": "Anzeigename:",
      "yAxisEmpty": "Bitte Achse auswählen",
      "yAxisLabel": "yAchse",
      "yAxisPrimaryLabel": "Primärachse",
      "yAxisSecondaryLabel": "Sekundärachse"
    },
    "addDatatype": {
      "buttons": {
        "add": "Datenpunkttyp hinzufügen"
      },
      "calcTypeName": "Datentyp",
      "calcTypes": {
        "addition": "Addition",
        "common": "Durchschnitt",
        "cummulate": "Kommulation",
        "dayline": "60-Werte Linie",
        "division": "Division",
        "empty": "Bitte Berechnung auswählen",
        "subtraction": "Subtraktion"
      },
      "chartTypeColumn": "Säule",
      "chartTypeEmpty": "Bitte Typ auswälen",
      "chartTypeLabel": "Charttyp wählen",
      "chartTypeLine": "Linie",
      "chartTypegroupedColumn": "grupp. Säule",
      "colorLabel": "Farbe:",
      "dataTypeLabel": "Datenpunkttyp hinzufügen",
      "dataTypePlaceholder": "Datentyp auswählen",
      "fields": {
        "id": "ID",
        "name": "Name",
        "type": "Typ"
      },
      "formulaLabel": "Formel",
      "formulaNoFormula": "Keine Formel zum anzeigen vorhanden",
      "formulaTypeCount": "Typenanzahl",
      "formulaTypeLabel": "Typ",
      "nameLabel": "Anzeigename:",
      "yAxisEmpty": "Bitte Achse auswählen",
      "yAxisLabel": "yAchse",
      "yAxisPrimaryLabel": "Primärachse",
      "yAxisSecondaryLabel": "Sekundärachse"
    },
    "designMenu": {
      "common": "Allgemein",
      "preValues": "Vorjahreswerte",
      "xAxis": "x-Achse",
      "yAxis1": "Primär y-Achse",
      "yAxis2": "Sekundär y-Achse"
    },
    "general": {
      "nameLabel": "Chart bezeichnung:"
    },
    "preValues": {
      "checkBoxLabel": "Vorjahreswerte:",
      "colorLabel": "Farbe:",
      "days": "Vortageswert",
      "months": "Vormonatsswert",
      "typeLabel": "Datensammeltyp",
      "years": "Vorjahreswert"
    },
    "settings": {
      "tabs": {
        "calc": "Berechnungen",
        "type": "Datentypen"
      }
    },
    "xAxis": {
      "businessYearLabel": "Geschäftsjahr verwenden:",
      "columns": "Spalten",
      "days": "Tage",
      "months": "Monate",
      "nameLabel": "Achsenbezeichnung:",
      "steps": "X Achse Interval",
      "years": "Jahre"
    },
    "yAxis": {
      "checkboxUseLabel": "Sekundärachse verwenden:",
      "nameLabel": "Achsenbezeichnung:",
      "usageDependencyText": "Einer oder Mehrere Typen/Berechnungen werden auf der  Sekundärachse gerendert"
    }
  },
  "header": {
    "title": "Chart-Vorlagen"
  },
  "modals": {
    "createModal": {
      "layoutManagerTitle": "ChartLayout Manager",
      "title": "Layout anlegen"
    }
  },
  "table": {
    "fields": {
      "id": "ID",
      "name": "Name",
      "type": "Typ"
    },
    "keys": {
      "dynamic": "Dynamisches Chart",
      "pps": "Target PPS",
      "target": "Target"
    },
    "name": "Layouts"
  },
  "tabs": {
    "drafts": "Entwürfe",
    "published": "Veröffentlicht"
  }
}

{
  "AnalysePhase": "Analysephase",
  "AnalyzingPhaseLabel": "Analysephase",
  "ApprovedLabel": "Genehmigt",
  "CheckPhase": "Prüfunsphase",
  "CheckingPhaseLabel": "Überprüfungsphase",
  "ClosedLabel": "Abgeschlossen",
  "EditedLabel": "Bearbeitet",
  "ImplementedLabel": "Umgesetzt",
  "InImplementationLabel": "In der Bearbeitung",
  "OpenedLabel": "Geöffnet",
  "PostponedLabel": "Aufgeschoben",
  "RejectedLabel": "Abgelehnt",
  "ReturnedLabel": "Zurückgeschickt",
  "SubmittedLabel": "Eingereicht",
  "analyzePhase": "Überprüfungsphase",
  "applicantLabel": "Antragsteller",
  "applicantPlaceholder": "Wer stellt den Änderungsantrag?",
  "causeAddLabel": "Tippen Sie hier ein, um neue Ursache hinzuzufügen!",
  "changeCauses": "Änderungsgründe",
  "changeDescription": "Beschreibung",
  "changeDescriptionPlaceholder": "Geben Sie eine Beschreibung ein!",
  "createsAtLabel": "Wann",
  "creatorLabel": "Ersteller",
  "creatorPlaceholder": "Wer meldet den Fehler?",
  "errorName": "Titel",
  "flagsLabel": "Flags",
  "flagsPlaceholder": "Wählen Sie Flags aus!",
  "placeholder": "Grund",
  "priority": "Priorität",
  "priorityHIGH": "Hoch",
  "priorityLOW": "Niedrig",
  "priorityMEDIUM": "Mittel",
  "priorityPlaceholder": "Wie dringend ist der Änderungsantrag?",
  "priorityVERY_HIGH": "Sehr hoch",
  "projectName": "Projektname",
  "projectNamePlaceholder": "Geben Sie den Namen des Projektes ein!",
  "status": "Status",
  "whyReturnedLabel": "Bemerkung",
  "whyReturnedPlaceholder": "Warum wird der Änderungsantrag zurückgeschickt?"
}

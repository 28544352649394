{
  "endTime": {
    "label": "Gültig bis"
  },
  "header": "Teamplanung",
  "headerCreate": "Teamplanung anlegen",
  "missingSite": "Es muss zuerste ein Standort ausgewählt werden.",
  "name": {
    "label": "Name",
    "placeholder": "Anzeigename für die Übersicht"
  },
  "noDate": "Es wurde kein Schichtplan selektiert oder der Schichtplan besitzt keinen freien Zuordnungszeitraum.",
  "options": {
    "id": "ID",
    "preferredName": "Anzeigename"
  },
  "schedule": {
    "label": "Schichtplan",
    "placeholder": "Bitte Schichplan auswählen"
  },
  "site": {
    "label": "Standort",
    "placeholder": "Bitte Standort auswählen"
  },
  "startTime": {
    "label": "Gültig ab"
  },
  "teamNr": {
    "label": "Teamnummer",
    "placeholder": "Welches Team soll neu erstellt werden?"
  },
  "tz": {
    "label": "Zeitzone",
    "placeholder": "Bitte Zeitzone auswählen"
  },
  "user": {
    "label": "Schichtplan",
    "placeholder": "Teammitglied hinzufügen"
  }
}

{
  "button": {
    "rejectRequest": "Auftrag abbrechen",
    "resolveRequest": "Abschliessen"
  },
  "title": "Transportaufträge",
  "toast": {
    "reject": {
      "errorText": "Der Transport wurde nicht abgebrochen",
      "text": "Der Transport wurde abgebrochen und die Anfrage reaktiviert."
    },
    "resolve": {
      "errorText": "Der Transport wurde nicht bestätigt.",
      "text": "Der Transport wurde bestätigt."
    },
    "warning": {
      "wsTitle": "Es wurden neue Aufträge erstellt, diese konnten aber nicht abgerufen werden. Bitte die Seite neu laden!"
    }
  }
}

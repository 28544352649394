{
  "byeUser": "Auf Wiedersehen {user}",
  "formTitle": "Einloggen",
  "input": {
    "loginSubmit": {
      "title": "Einloggen"
    },
    "password": {
      "label": "Passwort",
      "placeholder": "Passwort"
    },
    "username": {
      "label": "Benutzername",
      "placeholder": "Benutzername"
    }
  },
  "link": {
    "forgotPassword": "Passwort vergessen?",
    "imprint": "Impressum",
    "language": "Sprache",
    "privacyStatement": "Datenschutz"
  },
  "messages": {
    "loginFailed": "Einloggen fehlgeschlagen"
  },
  "stampIn": "Sie wurden erfolgreich eingestempelt",
  "stampOut": "Sie wurden erfolgreich ausgestempelt",
  "stampTime": "Zeitpunkt:",
  "stampTimeModalTitle": "Erfolg",
  "title": "LISA",
  "welcomeUser": "Hallo {name}"
}
{
  "label": {
    "storageLocation": "Lagerplatz",
    "warehouse": "Lager"
  },
  "modal": {
    "title": "Wareneingang buchen"
  },
  "modalEditor": {
    "title": "Wareneingang überprüfen"
  },
  "tableFields": {
    "date": "Datum",
    "index": "Nr.",
    "material": "Material",
    "quantity": "Menge"
  }
}

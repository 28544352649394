{
  "applicant": "Antragsteller",
  "cancelButton": "Abbrechen",
  "frameBreadcrumbs": "Überprüfung",
  "jumpToAnalyzeButton": "Zur Analyse wechseln",
  "manager": "Manager",
  "noCompletelyButton": "Nicht Vollständig",
  "returnButton": "Zurückschicken",
  "saveButton": "Änderungen speichern",
  "saveChangesButton": "Änderungen Speichern",
  "status": "Status:",
  "title": "Änderungsantrag überprüfen durch"
}

{
  "additionalInfos": "Zusatzinformation",
  "costPlace": "Kostenstelle",
  "create": "Anlegen",
  "createNewTechnicalPlace": "Technischen Platz anlegen",
  "description": "Beschreibung",
  "eNewTechnicalPlace": "Neuen technischen Platz anlegen",
  "identifier": "Technischer Platz",
  "parent": "Elternknoten auswählen",
  "technicalPlace": "Technischer Platz",
  "technicalPlaces": "Technische Plätze",
  "title": "Title",
  "updateTechnicalPlace": "Technischen Platz ändern"
}

{
  "category": "Kategorie",
  "description": "Istzustand:",
  "export": "Export",
  "exported": "Exportiert",
  "filter": "Filter",
  "header": "Ideenexport",
  "idea": "Idee | Ideen",
  "ideaShould": "Sollzustand:",
  "issueId": "Problem ID",
  "notExported": "Nicht Exportiert",
  "site": "Standort",
  "title": "Titel",
  "users": "Teilhaber"
}

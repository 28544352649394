{
  "planGroup": "Planungsgruppe",
  "rootTP": "Oberster Technischer Platz",
  "site": "Standort",
  "teamLeader": "BIS-Verantwortlicher",
  "folder": {
    "title": "Ordner auswählen"
  },
  "folderLabel": "Störmeldungsordner"
}

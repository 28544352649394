{
  "dataSource": "Datenquelle",
  "interval": "Intervall",
  "intervalType": {
    "daily": "Täglich",
    "dailyPast": "Täglich (Vortag)",
    "ifRequired": "Bei Bedarf",
    "monthly": "monatlich",
    "never": "Nie",
    "quarterly": "Quartalsweise",
    "shift": "Schicht",
    "weekly": "Wöchentlich",
    "yearly": "Jährlich"
  },
  "responsible": "Verantwortlicher"
}

<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    <i class="fas fa-flag mr-1" />
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import apolloCache from "@/graphql/apolloCache";
import FlagsFilter from "../../filterImplementations/FlagsFilter";
import FilterBadge from "./FilterBadge.vue";
import joinList from "./joinList";

export default defineComponent({
  name: "FlagsFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<FlagsFilter>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const text = computed(() => {
      const filterNames = props.filter.flags.map((flagId) => {
        const { name } = apolloCache.readFragment<{ name: string }>({
          id: `Flag:${flagId}`,
          fragment: gql`
            fragment Name on Flag {
              name
            }
          `,
        }) ?? { name: "Unknown" };
        return name;
      });

      return filterNames.length === 0
        ? t("components.event-action.filter.ui.badges.FlagsFilterBadge.withoutFlags")
        : joinList(filterNames);
    });
    return {
      text,
    };
  },
});
</script>

<style lang="scss" scoped></style>

import Keycloak from "keycloak-js";
import { InjectionKey } from "vue";
import { ENV } from "@/ENV";

if (ENV.VITE_APP_KEYCLOAK_URL.length === 0 || ENV.VITE_APP_INSTANCE_KEY.length === 0) {
  throw Error("ENV not set for VITE_APP_KEYCLOAK_URL or VITE_APP_INSTANCE_KEY");
}

const keycloakConfig = {
  url: ENV.VITE_APP_KEYCLOAK_URL,
  realmName: ENV.VITE_APP_INSTANCE_KEY,
  clientId: ENV.VITE_APP_INSTANCE_KEY === "e2e" ? "lisa-e2e" : "lisa-frontend",
};

export default keycloakConfig;

export const KeycloakInjectionKey = Symbol("KeycloakInjectionKey") as InjectionKey<Keycloak>;

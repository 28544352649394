import { logErrorMessages } from "@vue/apollo-util";
import { onError } from "@apollo/client/link/error";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/vue";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { ApolloClient, ApolloLink, split } from "@apollo/client/core";
import { sha256 } from "crypto-hash";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";
import cache from "./apolloCache";
import { transactionId } from "@/sentry";
import { keycloak } from "@/keycloak/Keycloak";
import store from "@/store";

const httpLinkConfig = {
  uri: `${window.location.origin}/graphql`,
  headers: {
    "X-Version": import.meta.env.__VERSION__,
  },
};

const batchHttpLink = new BatchHttpLink(httpLinkConfig);

const nonBatchedHttpLink = createUploadLink(httpLinkConfig) as unknown as ApolloLink;

const transactionIdMiddleware = new ApolloLink((operation, forward) => {
  const id = transactionId();
  // add adds a random Id to the header
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-Transaction-ID": id,
    },
  }));

  // generate unique transactionId and set as Sentry tag
  Sentry.configureScope((scope) => {
    scope.setTag("transaction_id", id);
  });
  return forward(operation);
}).concat(nonBatchedHttpLink);

const batchableQueries = [
  "GetSQDCRainbowTileStatus",
  "GetBoardHierarchy",
  "LoadKPIBoard",
  "SearchSite",
  "GetDocumentsInFolder",
];

const httpLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "query" &&
      batchableQueries.includes(definition.name?.value ?? "")
    );
  },
  batchHttpLink,
  transactionIdMiddleware,
);

const persistedHttpLink = createPersistedQueryLink({
  sha256,
  disable(errors) {
    const { graphQLErrors } = errors;
    if (
      graphQLErrors &&
      graphQLErrors.some((error) => {
        const { message } = error;
        return message === "PersistedQueryNotSupported";
      })
    ) {
      return true;
    }
    return false;
  },
}).concat(httpLink);

const authedHttpLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${keycloak?.token}`,
    },
  }));

  return forward(operation);
}).concat(persistedHttpLink);

const errorLink = onError((error) => {
  const { networkError, graphQLErrors, forward, operation } = error;
  logErrorMessages(error);
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (
        message === "missingSession" ||
        message === "expiredSession" ||
        message === "invalidSession"
      ) {
        if (store.state.login.user) {
          store.dispatch("login/logout");
          window.$toast?.({
            severity: "error",
            summary: window.$i18n?.t("api.toast.error.title").toString(),
            detail: window.$i18n?.t("api.toast.error.expiredSession").toString(),
          });
        }
      }
      let errorMessage;
      if (window.$i18n?.te(`api.toast.error.${message}`)) {
        errorMessage = window.$i18n?.t(`api.toast.error.${message}`);
      } else {
        errorMessage = window.$i18n?.t("api.toast.error.common");
      }
      window.$toast?.({
        severity: "error",
        summary: window.$i18n?.t("api.toast.error.title").toString(),
        detail: errorMessage?.toString(),
      });
    });
  }
  if (networkError) {
    window.$toast?.({
      severity: "error",
      detail: window.$i18n?.t("api.toast.error.networkError").toString(),
      summary: window.$i18n?.t("api.toast.error.title").toString(),
    });
  }
  forward(operation);
});

const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";

// Create the subscription websocket link
export const wsLink = new GraphQLWsLink(
  createClient({
    url: `${wsProtocol}//${window.location.host}/graphql`,
    shouldRetry: () => true,
    retryAttempts: 5,
    connectionParams: async () => {
      return {
        authorization: `Bearer ${keycloak.token}`,
      };
    },
  }),
);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
export function getApolloClientLink() {
  return split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    authedHttpLink,
  );
}

export const apolloClient = new ApolloClient({
  name: "lisapp-frontend",
  version: import.meta.env.__VERSION__,
  link: errorLink.concat(getApolloClientLink()),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  },
});

export default {
  apolloClient,
};

{
  "tableName": "Name",
  "tableAccessRights": "Anzahl der Rechte",
  "tableUsers": "Anzahl der Benutzer",
  "title": "Rollen",
  "noUsers": "Kein Benutzer hat diese Rolle",
  "moreUsers": "weitere Benutzer",
  "options": {
    "id": "ID"
  }
}

import More from "highcharts/highcharts-more";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import BrokenAxis from "highcharts/modules/broken-axis";
import DarkUnicaTheme from "highcharts/themes/dark-unica";
import AvocadoTheme from "highcharts/themes/avocado";
import Highcharts from "highcharts";
import Gantt from "highcharts/modules/gantt";

import sankey from "highcharts/modules/sankey";
import noData from "highcharts/modules/no-data-to-display";
import organization from "highcharts/modules/organization";
import pareto from "highcharts/modules/pareto";
import HighchartsVue from "highcharts-vue";

import store from "./store";
import i18n, { languageKeys } from "./i18n";

function getSeparator(locale: string, separatorType: "group" | "decimal") {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  const result = Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separatorType)?.value;
  return result ?? (separatorType === "decimal" ? "," : ".");
}

export function pointBreakColumn(this: Highcharts.Axis, e: Highcharts.AxisPointBreakEventObject) {
  const { point } = e;
  const { brk } = e;
  const { shapeArgs } = point;
  // @ts-expect-error not typed correctly
  const { x } = shapeArgs;
  // @ts-expect-error not typed correctly
  const y = this.translate(brk.from, 0, 1, 0, 1);
  // @ts-expect-error not typed correctly
  const w = shapeArgs.width;
  const key = ["brk", brk.from, brk.to];
  const path = ["M", x, y, "L", x + w * 0.25, y + 4, "L", x + w * 0.75, y - 4, "L", x + w, y];

  // @ts-expect-error not typed correctly
  if (!point[key]) {
    // @ts-expect-error not typed correctly
    point[key] = this.chart.renderer
      .path(path)
      .attr({
        "stroke-width": 2,
        // @ts-expect-error not typed correctly
        stroke: point.series.options.borderColor,
      })
      // @ts-expect-error not typed correctly
      .add(point.graphic.parentGroup);
  } else {
    // @ts-expect-error not typed correctly
    point[key].attr({
      d: path,
    });
  }
}

export function setTheme() {
  if ((store.state as any)?.login.user?.darkMode) {
    DarkUnicaTheme(Highcharts);
  } else {
    AvocadoTheme(Highcharts);
  }
}

export function setHighchartsLocale(localeProp?: (typeof languageKeys)[number]) {
  const locale = localeProp ?? i18n.global.locale.value;
  let months;
  let shortMonths;
  let weekdays;
  let shortWeekdays;

  try {
    months = JSON.parse(window.$i18n?.t("highcharts.months", locale) ?? "[]");
    shortMonths = JSON.parse(window.$i18n?.t("highcharts.shortMonths", locale) ?? "[]");
    weekdays = JSON.parse(window.$i18n?.t("highcharts.weekdays", locale) ?? "[]");
    shortWeekdays = JSON.parse(window.$i18n?.t("highcharts.shortWeekdays", locale) ?? "[]");
  } catch (err) {
    months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    shortMonths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    shortWeekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  }

  const additions: Record<string, string> = {};
  additions.thousandsSep = getSeparator(locale.replaceAll("_", "-"), "group");
  additions.decimalPoint = getSeparator(locale.replaceAll("_", "-"), "decimal");

  Highcharts.setOptions({
    lang: {
      contextButtonTitle: window.$i18n?.t("highcharts.contextButtonTitle", locale) ?? "",
      downloadCSV: window.$i18n?.t("highcharts.downloadCSV", locale) ?? "",
      downloadJPEG: window.$i18n?.t("highcharts.downloadJPEG", locale) ?? "",
      downloadPDF: window.$i18n?.t("highcharts.downloadPDF", locale) ?? "",
      downloadPNG: window.$i18n?.t("highcharts.downloadPNG", locale) ?? "",
      downloadSVG: window.$i18n?.t("highcharts.downloadSVG", locale) ?? "",
      // @ts-expect-error Highcharts at its best!
      drillUpText: window.$i18n?.t("highcharts.drillUpText", locale) ?? "",
      exitFullscreen: window.$i18n?.t("highcharts.exitFullscreen", locale) ?? "",
      months,
      printChart: window.$i18n?.t("highcharts.printChart", locale) ?? "",
      resetZoom: window.$i18n?.t("highcharts.resetZoom", locale) ?? "",
      resetZoomTitle: window.$i18n?.t("highcharts.resetZoomTitle", locale) ?? "",
      noData: window.$i18n?.t("highcharts.noData", locale) ?? "",
      shortMonths,
      weekdays,
      shortWeekdays,
      ...additions,
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        turboThreshold: 0,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            minHeight: 600,
            minWidth: 800,
          },
          chartOptions: {
            title: {
              y: 12,
            },
          },
        },
        {
          condition: {
            maxHeight: 600,
            maxWidth: 800,
          },
          chartOptions: {
            title: {
              y: 23,
              style: {
                fontSize: "1em",
              },
            },
            legend: {
              enabled: false,
            },
            navigation: {
              buttonOptions: {
                enabled: false,
              },
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false,
                },
              },
              series: {
                dataLabels: {
                  enabled: false,
                },
              },
              column: {
                dataLabels: {
                  enabled: false,
                },
              },
            },
            xAxis: [
              {
                title: {
                  text: undefined,
                },
              },
              {
                title: {
                  text: undefined,
                },
                offset: 0,
              },
            ],
            yAxis: [
              {
                title: {
                  text: undefined,
                },
              },
              {
                title: {
                  text: undefined,
                },
              },
              {
                title: {
                  text: undefined,
                },
              },
              {
                title: {
                  text: undefined,
                },
              },
            ],
          },
        },
      ],
    },
  });
}

More(Highcharts);
BrokenAxis(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
sankey(Highcharts);
pareto(Highcharts);
noData(Highcharts);
organization(Highcharts);
Gantt(Highcharts);
Highcharts.dateFormats.W = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getUTCDay() === 0 ? 7 : date.getUTCDay();

  date.setDate(date.getUTCDate() + 4 - day);
  const dayNumber = Math.floor(
    (date.getTime() - Number(new Date(date.getUTCFullYear(), 0, 1, -6))) / 86400000,
  );

  return (1 + Math.floor(dayNumber / 7)).toString();
};

Highcharts.setOptions({
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          "printChart",
          "separator",
          // currently broken on highcharts
          // "downloadPNG",
          // "downloadJPEG",
          // "downloadPDF",
          "downloadSVG",
          // need to be replaced on charts with custom method
          // "separator",
          // "downloadCSV",
        ],
      },
    },
  },
});

export default HighchartsVue;

{
  "cancel": "Abbrechen",
  "deleteDepartment": "Abteilung löschen",
  "deleteError": "Diese Abteilung kann nicht gelöscht werden, da noch Benutzer diesem Standort zugewiesen sind oder es die obertste Abteilung des Standortes ist.",
  "deleteMsg": "Beim Löschen der Abteilung werden folgende Abteilungen mit gelöscht:{departments}. Soll die Abteilung {name} gelöscht werden?",
  "department": "Standort",
  "departmentLeader": "Abteilungsleiter",
  "infoText": "Dies ist die Standortleitung für den Standort {name}",
  "ment": {
    "createNewDepartment": "Neue Abteilung anlegen"
  },
  "name": "Name",
  "parentDepartment": "Übergeordnete Abteilung",
  "selectParent": "Elternknoten auswählen",
  "selectTeamleader": "Teamleader auswählen",
  "submit": "Bitte bestätigen"
}

{
  "author": "Autor",
  "begin": "Anfang",
  "content": "Inhalt",
  "createNews": "News anlegen",
  "global": "Global",
  "id": "Nr.",
  "news": "News",
  "priority": "Priorität",
  "sqdc": "SQDC",
  "title": "Titel",
  "validity": "Gültigkeit"
}

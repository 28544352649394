import {
  ActionListActionFieldsFragment,
  EventListEventFieldsFragment,
  HasDescription,
} from "@/graphql";
import { EventFilter, ActionFilter } from "../FilterTypes";
import DescriptionFilterUI from "../ui/DescriptionFilterUI.vue";
import DescriptionFilterBadge from "../ui/badges/DescriptionFilterBadge.vue";

function hasDescription(descriptionCandidate: any): descriptionCandidate is HasDescription {
  return "description" in descriptionCandidate;
}

export default class DescriptionFilter implements EventFilter, ActionFilter {
  constructor(public readonly searchText: string, public readonly isNegated?: boolean) {}

  static readonly Badge = DescriptionFilterBadge;

  static readonly UI = DescriptionFilterUI;

  isEventActive(event: EventListEventFieldsFragment) {
    const isActive = (() => {
      return this.filter(event);
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isActionActive(action: ActionListActionFieldsFragment) {
    const isActive = (() => {
      return this.filter(action);
    })();
    return this.isNegated ? !isActive : isActive;
  }

  filter(item: EventListEventFieldsFragment | ActionListActionFieldsFragment) {
    if (hasDescription(item)) {
      return item.description?.toLowerCase().includes(this.searchText.toLowerCase()) ?? false;
    }
    return false;
  }
}

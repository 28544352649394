{
  "badConfiguration": "Ich bin nicht richtig konfiguriert",
  "dataSource": "Datenquelle",
  "interval": "Intervall",
  "noDescription": "Es wurde noch keine Beschreibung eingetragen",
  "noResponsible": "Kein Verantwortlicher angegeben.",
  "noShiftTitle": "Unbekannt",
  "noTileName": "Kachel ohne Namen",
  "resetHistory": "Auf Heute zurücksetzen",
  "responsible": "Verantwortlicher",
  "stackedTilesHeader": "Gestapelte Kacheln:"
}

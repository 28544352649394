import axios from "axios";
import loginstore from "@/store";
import definition from "./apiDefinition";
import { transactionId } from "@/sentry";
import { keycloak } from "@/keycloak/Keycloak";

const baseURL = `${window.location.protocol}//${window.location.host}/api/`;

const apiWrapper = axios.create({
  baseURL,
});

const api: Record<string, any> = {};

Object.entries(definition).forEach(([name, component]) => {
  const endpoints = Object.entries(component.endpoints).reduce(
    (apiObject, [endpointName, endpointConfig]: [string, any]) => {
      apiObject[endpointName] = async (...args: any[]) => {
        let id;
        let payload;
        let routeParams;
        if (typeof args[0] === "object") {
          [payload, routeParams] = args;
        } else {
          [id, payload, routeParams] = args;
        }
        if (endpointConfig.route.includes(":id") && id == null) {
          // eslint-disable-next-line no-console
          console.warn(`${name}.${endpointName} contains id but no id provided.`);
        }
        let url = `${component.path}/${endpointConfig.route.replace(":id", id)}`;
        Object.entries(routeParams || {}).forEach(([key, values]) => {
          url = url.replace(`:${key}`, values as any);
        });
        try {
          const options: any = {
            method: endpointConfig.method,
            url,
            data: payload,
            withCredentials: true,
            headers: {
              "X-Version": import.meta.env.__VERSION__,
              "X-Transaction-ID": transactionId(),
              Authorization: `Bearer ${keycloak?.token}`,
            },
          };
          if (endpointConfig.forceBlob === true) {
            options.responseType = "blob";
          }

          const response = await apiWrapper(options);
          if (response?.data?.message) {
            window.$toast?.({
              severity: "success",
              detail: (window.$i18n?.te(`api.toast.success.${response.data.message}`)
                ? window.$i18n?.t(`api.toast.success.${response.data.message}`)
                : window.$i18n?.t("api.toast.success.common")) as string,
              summary: window.$i18n?.t("api.toast.success.title") as string,
            });
          }
          return response.data;
        } catch (error: any) {
          if (error.message === "Network Error") {
            window.$toast?.({
              severity: "error",
              summary: window.$i18n?.t("api.toast.error.title"),
              detail: window.$i18n?.t("api.toast.error.networkError") as string,
            });
            return null;
          }

          if (axios.isAxiosError(error)) {
            if (
              error.response &&
              error.response.status === 403 &&
              (error.response.data.message === "missingSession" ||
                error.response.data.message === "expiredSession" ||
                error.response.data.message === "invalidSession")
            ) {
              if (loginstore.state.login.user) {
                await loginstore.dispatch("login/logout");
                window.$toast?.({
                  severity: "error",
                  detail: window.$i18n?.t("api.toast.error.expiredSession") as string,
                  summary: window.$i18n?.t("api.toast.error.title"),
                });
              }
              return null;
            }

            // eslint-disable-next-line no-console
            console.error("Axios request failed:", error);
            if (error.response?.data?.message && endpointConfig.ignoreError !== true) {
              let errorMessage;
              if (window.$i18n?.te(`api.toast.error.${error.response.data.message}`)) {
                errorMessage = [window.$i18n?.t(`api.toast.error.${error.response.data.message}`)];
              } else {
                errorMessage = [window.$i18n?.t("api.toast.error.common")];
              }

              if (Array.isArray(error?.response?.data?.errors) && error.response.status === 400) {
                const visible = error?.response?.data?.errors.filter((el: any) => {
                  if (el.location === "body") {
                    return window.$i18n?.te(`api.toast.error.validator.${el.param}`);
                  }
                  return false;
                }); // collecting all errors that didn't pass validator!
                errorMessage = [
                  ...errorMessage,
                  ...visible.map(
                    (el: any, index: number) =>
                      `${index + 1}. ${window.$i18n?.t(`api.toast.error.validator.${el.param}`)}`,
                  ),
                ];
              }
              const message = errorMessage;

              window.$toast?.({
                severity: "error",
                summary: window.$i18n?.t("api.toast.error.title") as string,
                detail: message[0],
              });
            } else if (endpointConfig.ignoreError !== true) {
              window.$toast?.({
                severity: "error",
                detail: window.$i18n?.t("api.toast.error.common") as string,
                summary: window.$i18n?.t("api.toast.error.title") as string,
              });
            }

            // If there is a version mismatch, don't throw the error.
            if (error.response?.status === 409 && error.response.data === "reloadRequired") {
              return null;
            }
          }
          throw error;
        }
      };
      return apiObject;
    },
    {} as Record<string, any>,
  );
  api[name] = endpoints;
});

export default api;

export const ApiDefinition = Object.freeze(definition);

export function downloadLinkForUUID(uuid: string) {
  return `${baseURL}documents/downloadFile/${uuid}?token=${keycloak.token}`;
}

import { ActionListActionFieldsFragment, EventListEventFieldsFragment } from "@/graphql";
import { EventFilter, ActionFilter, UserId } from "../FilterTypes";
import CreatorFilterUI from "../ui/CreatorFilterUI.vue";
import CreatorFilterBadge from "../ui/badges/CreatorFilterBadge.vue";

export default class CreatorFilter implements EventFilter, ActionFilter {
  constructor(public readonly creators: UserId[], public readonly isNegated?: boolean) {}

  static readonly Badge = CreatorFilterBadge;

  static readonly UI = CreatorFilterUI;

  isEventActive(event: EventListEventFieldsFragment) {
    const isActive = (() => {
      return this.creators.includes(event.owner?.id ?? -1);
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isActionActive(action: ActionListActionFieldsFragment) {
    const isActive = (() => {
      return this.creators.includes(action.assignee?.id ?? -1);
    })();
    return this.isNegated ? !isActive : isActive;
  }
}

{
  "description": "Beschreibung",
  "how": "Wie",
  "howQuestionNeg": "Wie zeigt sich dieses Problem nicht?",
  "howQuestionPos": "Wie zeigt sich dieses Problem?",
  "problemIs": "Das Problem ist",
  "problemIsDescription": "(n.i.O. Produkt/Prozess)",
  "problemIsNot": "Das Problem ist nicht",
  "problemIsNotDescription": "(Vergleich mit einem i.O. Produkt/Prozess)",
  "what": "Was",
  "whatQuestionNeg": "Was ist das Problem nicht?",
  "whatQuestionPos": "Was ist das Problem?",
  "when": "Wann",
  "whenQuestionNeg": "Wann tritt dieses Problem nicht auf?",
  "whenQuestionPos": "Wann tritt dieses Problem auf?",
  "where": "Wo",
  "whereQuestionNeg": "Wo tritt dieses Problem nicht auf?",
  "whereQuestionPos": "Wo tritt dieses Problem auf?",
  "who": "Wer",
  "whoQuestionNeg": "Wer ist von diesem Problem nicht betroffen?",
  "whoQuestionPos": "Wer ist von diesem Problem betroffen?",
  "why": "Warum",
  "whyQuestionNeg": "Warum ist es kein Problem?",
  "whyQuestionPos": "Warum ist es ein Problem?"
}

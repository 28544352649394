{
  "ADDITION": "Addition",
  "ALL": "Alle",
  "AVERAGE": "Mittelwert",
  "BUSINESS_YEAR": "Geschäftsjahr | Geschäftsjahre",
  "CENTURY": "Jahrhundert | Jahrhunderte",
  "COUNT": "Anzahl",
  "CUMULATION": "Kumulierung",
  "DAY": "Tag | Tage",
  "DECADE": "Jahrzehnt | Jahrzehnte",
  "DIVISION": "Division",
  "HOUR": "Stunde | Stunden",
  "MAXIMUM": "Maximum",
  "MILLENNIUM": "Jahrtausend | Jahrtausende",
  "MINIMUM": "Minimum",
  "MINUTE": "Minute | Minuten",
  "MONTH": "Monat | Monate",
  "MOVINGAVERAGE": "gltd. Durchschnitt",
  "MULTIPLICATION": "Multiplikation",
  "ROW": "Eintrag | Einträge",
  "SECOND": "Sekunde | Sekunden",
  "SHIFT": "Tag/Schicht",
  "SUBTRACTION": "Subtraktion",
  "WEEK": "Woche | Wochen",
  "YEAR": "Jahr | Jahre",
  "noAggregation": "Keine Aggregation möglich"
}

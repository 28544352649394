{
  "automaticConsumption": {
    "label": "Automatischer Konsum"
  },
  "createButton": {
  },
  "errorNoWarehouse": "Bitte Lager auswählen",
  "header": "Lagerplatz",
  "identifier": {
    "label": "Lagerplatz Nr."
  },
  "infotexts": {
    "overrideAC": "Überschrieben von:"
  },
  "name": {
    "label": "Bezeichnung"
  },
  "table": {
    "fields": {
      "identifier": "Lagerplatz Nr.",
      "materials": "Materialien",
      "name": "Bezeichnung",
      "warehouse": "Lager"
    }
  },
  "toStorage": "Zum Lager",
  "warehouseId": {
    "label": "Lager"
  }
}

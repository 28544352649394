{
  "automaticConsumption": {
    "label": "Automatischer Verbrauch in allen Lagerplätzen"
  },
  "createButton": {
  },
  "grids": {
    "storageLocation": {
      "title": "Lagerplätze"
    }
  },
  "header": "Lager",
  "identifier": {
    "label": "Lagernummer"
  },
  "infotexts": {
    "infoAutomaticConsumption": "Diese Einstellung überschreibt die gleiche in den dazugehörigen Lagerplätzen",
    "infoSupplyWarehouse": "Wenn diese Option aktiviert ist, werden Materialien bei Bestellungen aus diesem Lager entnommen."
  },
  "name": {
    "label": "Name"
  },
  "siteId": {
    "label": "Standort"
  },
  "supplyWarehouse": {
    "label": "Versorgungslager"
  },
  "table": {
    "fields": {
      "identifier": "Lager Nr.",
      "name": "Bezeichnung",
      "storageLocations": "Lagerplätze",
      "supplyWarehouse": "Versorgungslager"
    },
    "header": "Lager",
    "headerMultiOrZero": "Lager | Lager"
  }
}

<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }">
    <i class="fas fa-clock mr-1" />
    {{ text }}
  </FilterBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { LISA_DATE_SHORT } from "@/i18n";
import DateFilter from "../../filterImplementations/DateFilter";
import FilterBadge from "./FilterBadge.vue";

export default defineComponent({
  name: "DateFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<DateFilter>,
      required: true,
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const text = computed(() => {
      return props.filter.date
        .map((d) => {
          if (d == null) {
            return "∞";
          }
          return d.toLocaleString(LISA_DATE_SHORT, { locale: locale.value });
        })
        .join(" ﹣ ");
    });
    return {
      text,
    };
  },
});
</script>

<style lang="scss" scoped></style>

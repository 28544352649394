{
  "ChangeValueDisplayMethod": "Die Datenanzeigeart kann im Tab Layout unter der Primären Y-Achse in dem Eingabefeld Datenanzeigeart geändert werden.",
  "aggregation": "Aggregation",
  "calculation": "Berechnung",
  "data": "Daten",
  "dataManipulationInterval": "Datenumrechnung in Datenreihe überschreiben",
  "dataManipulationIntervalTooltip": "Beim Interpolieren werden die Daten dann in diesem Interval angezeigt",
  "interpolate": "Werte aus Datenpunkte interpolieren",
  "interpolateTooltip": "Diese Funktion interpretiert die Werte aus einem Datenpunkt als Intervall in Sekunden und überträgt die Werte ggf. auf nachfolgende Zeiteinheiten. Außerdem wird die \"Einheit\" des Datenpunktes überschrieben mit der Zeiteinheit der Granularität.",
  "isRange": "Bereichswert",
  "isRangeHint": "Soll die Datenreihe als Zielbereichswert betrachet werden.",
  "isRangeTooltip": "Es ist aktuell nicht Möglich einen \"außerhalb\"-Bereich eines Datenpunktes darzustellen, dieser wird als \"innerhalb\"-Bereich dargestellt.",
  "movingWindow": "Gleitendes Fenster (Kommulation / Gleitender Durchschnitt)",
  "unit": "Einheit",
  "valueDisplayMethod": "Datenanzeigeart"
}

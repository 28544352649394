{
  "buttons": {
    "createButton": "Ordner hinzufügen",
    "createDocument": "Dokument anlegen",
    "deleteButtonTooltip": "Dieses Abonnement löschen",
    "folderCloseButton": "Ordnerverwaltung schliessen",
    "goToFolder": "Zum Ordner navigieren"
  },
  "confirmationModal": {
    "title": "Abonnement '{name}' löschen"
  },
  "headLine": "Abonnements:",
  "header": {
    "displayedDocuments": "Angezeigte Dokumente",
    "noDocuments": "Dieser Ordner enthält keine Dokumente"
  },
  "noDocuments": "Keine Dokumente vorhanden"
}

export default {
  endpoints: {
    getOne: {
      route: "/:id",
      method: "GET",
    },
    rotatePage: {
      route: "/rotatePage/:id",
      method: "put",
    },
    reorder: {
      route: "/reorder/:id",
      method: "put",
    },
  },
};

{
  "request": {
    "buttons": {
      "create": "Anlegen"
    },
    "quantity": "Anzahl",
    "sourceTablefields": {
      "available": "Verfügbar",
      "materialNr": "Material",
      "quantity": "Bestand",
      "reserved": "Reserviert",
      "storageLocation": "Lagerplatz"
    },
    "storageLocation": "Lagerplatz",
    "title": "Transportauftrag anlegen",
    "toast": {
      "error": {
        "text": "Ein Fehler ist aufgetreten. Es wurde kein Transport erstellt!"
      },
      "success": {
        "text": "Der Auftrag wurde gespeichert und ein Transportauftrag wurde veranlasst!"
      },
      "warning": {
        "text": "Der Auftrag wurde gespeichert. Es wurde jedoch kein Transportauftrag angelegt da nicht genügend Ressourcen vorhanden sind!"
      }
    },
    "warehouse": "Lager"
  },
  "type": {
    "ORDER": "Auftrag",
    "REQUEST": "Anfrage"
  }
}

{
  "additionalPlots": "Zusätzliche Plots",
  "calculation": "Datenreihen",
  "closeConfirm": "Sind Sie sicher, dass Sie die Seite verlassen wollen? Dabei geben alle Änderungen verloren.",
  "common": "Allgemein",
  "connection": "Datenreihen verbinden",
  "convert": "Layouttype in Benutzerdefiniert ändern",
  "convertToCustom": "Sind Sie sicher, dass Sie diese Vorlage in eine benutzerdefinierte Vorlage umwandeln wollen? Es ist nicht möglich die Vorlage wieder zurückzusetzen. Die Änderung wird erst beim Speichern des Layouts permanent. Es werden alle aktuellen Einstellungen in die benutzerdefinierte Vorlage übernommen.",
  "dataInput": "Dateneingabe",
  "dataTable": "Datentabelle",
  "general": "Layout",
  "header": "Vorlage",
  "previousData": "Vorhergegangene Werte",
  "summary": {
    "fields": {
      "cummulation": {
        "label": "Kummulierung des Datenpunkts über den konfigurierten Zeitraum der Datenreihen"
      },
      "datapoint": {
        "label": "Datenpunkt:"
      },
      "enable": {
        "label": "Zusammenfassung aktivieren"
      },
      "target": {
        "label": "Zielwert:"
      },
      "title": {
        "label": "Überschrift:"
      }
    },
    "hintText": "{zero} Die Konfiguration der Zielwerte ist in den Einstellugen der entsprechenden Kachel einfacher, da dort Parameter wie Datenpunkttyp, technischer Platz und Kategorie schon vorausgewählt sind.",
    "infotext": "Die Zielwerte der Kacheln können entweder im entsprechenden {name} oder der\n{name1} gepflegt werden.",
    "replacements": {
      "boardManagement": "Board-Aufbau",
      "hintTitle": "Tipp:",
      "targets": "Zielwerttabelle"
    },
    "tabTitle": "Zusammenfassung"
  }
}

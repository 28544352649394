{
  "addCalculation": "Berechnung hinzufügen",
  "addDatapoint": "Datenpunkt hinzufügen",
  "addSeries": "Datenreihe hinzufügen",
  "addTarget": "Zielwert hinzufügen",
  "aggregation": "Aggregation",
  "axis": "Y-Achse",
  "axisTooltipText": "Es ist nicht möglich, Säulen auf verschiedenen Achsen darzustellen. Alle Säulen müssen entweder auf der Primären oder Sekundärenachse dargestellt werden.",
  "axisTooltipTextCategory": "Wenn eine zweite Achse benutzt wird, wird ein Chart als Rahmen des ersten agezeigt.",
  "axisTooltipTitle": "Säuleneinschränkung",
  "axisTooltipTitleCategory": "Darstellung der zweiten Achse",
  "calculation": "Berechnung",
  "chartType": "Diagrammtyp",
  "columnOverlay": "Hintergrund",
  "datapointSeriesMissing": "Es muss mindestens eine Datenpunktreihe hinzugefügt werden.",
  "datapointTargets": "Zielwerte",
  "datapoints": "Datenpunkte",
  "isRange": "Ist Bereichswert",
  "missingVisibleSeries": "Es muss mindestens eine Datenreihe sichtbar sein.",
  "noVisibleSeries": "Das Chart besitzt keine sichtbaren Datenreihen",
  "rangeWarning": "Bereichswert",
  "seriesDeleteError": "Die Datenreihe konnte nicht entfernt werden, da eine andere Datenreihe eine Abhängigkeit besitzt.",
  "sortTooltipText": "Die Sortierung erfolgt per Drag & Drop und ist nicht verfügbar für Berechnungsdatenreihen.",
  "sortTooltipTitle": "Sortierung",
  "targetTooltipText": "Bei Zielwerten können keine Operatoren dargestellt werden. Das Bedeutet, dass ein Zielwert > 1 genauso dargestellt wird wie ein Zielwert < 1. Außerdem wird bei einer Berechnung mit einem Zielwert der Operator ebenfalls nicht berücksichtigt. Bei Zielwerten, die einen Bereich darstellen, wie zwischen oder außerhalb, wird immer die Obergrenze als fester Wert betrachtet.",
  "targetTooltipTitle": "Zielwerteinschränkungen",
  "visible": "Sichtbar",
  "visiblePreValue": "Sichtbar",
  "visibleToolTipText": "Soll die Datenreihe im Chart im Vordergrund angezeigt werden oder dient Sie nur zur Berechnung oder als Überlagerung.",
  "visibleToolTipTextPreValue": "Sollen für diese Datenreihe vorhergegangene Werte angezeigt werden."
}

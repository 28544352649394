{
  "approve": "Bestätigen",
  "date": "Monat",
  "decline": "Ablehnen",
  "header": "Stundenzettel",
  "overtime": "Überstunden",
  "preferredName": "Benutzer",
  "withdraw": "Zurückziehen",
  "showAll": "Alle anzeigen",
  "status": "Status"
}

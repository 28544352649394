import { DateTime } from "luxon";
import { ActionListActionFieldsFragment, EventListEventFieldsFragment } from "@/graphql";
import { EventFilter, ActionFilter } from "../FilterTypes";
import DateFilterUI from "../ui/DateFilterUI.vue";
import DateFilterBadge from "../ui/badges/DateFilterBadge.vue";

export default class DateFilter implements EventFilter, ActionFilter {
  constructor(
    public readonly date: [DateTime | null, DateTime | null],
    public readonly isNegated?: boolean,
  ) {}

  static readonly Badge = DateFilterBadge;

  static readonly UI = DateFilterUI;

  isEventActive(event: EventListEventFieldsFragment) {
    const isActive = (() => {
      const occurrenceDate = DateTime.fromISO(event.occurrenceDate);
      return this.filter(occurrenceDate);
    })();
    return this.isNegated ? !isActive : isActive;
  }

  isActionActive(action: ActionListActionFieldsFragment) {
    const isActive = (() => {
      if (action.deadline) {
        const deadline = DateTime.fromISO(action.deadline);
        return this.filter(deadline);
      }
      return false;
    })();
    return this.isNegated ? !isActive : isActive;
  }

  filter(date: DateTime) {
    const [minDate, maxDate] = this.date;
    let result = true;
    if (minDate != null) {
      result &&= date >= minDate;
    }
    if (maxDate != null) {
      result &&= date < maxDate;
    }
    return result;
  }

  serialize() {
    return {
      date: this.date.map((dt) => dt?.toISO() ?? "null"),
    };
  }

  static deserialize(values: { date: string[] }) {
    return new DateFilter(
      values.date.map((isoString) =>
        isoString === "null" ? null : DateTime.fromISO(isoString),
      ) as [DateTime | null, DateTime | null],
    );
  }
}

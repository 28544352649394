{
  "addVacation": "Fehltag/Zeitkonto hinzufügen",
  "break": "Pause",
  "calculationError": "Error: Admin kontaktieren",
  "comment": "Bemerkung",
  "createStamp": "Stempel hinzufügen",
  "createStampTooltip": "Es ist nicht mögliche einen Stempel in einem eingereichtem Monat einzutragen",
  "day": "Datum",
  "diff": "Δ-Differenz",
  "edit": {
    "comment": "Bitte Änderungsgrund eingeben",
    "commentIn": "Einstempel Kommentar",
    "commentOut": "Ausstempel Kommentar",
    "editUser": "Geändert durch",
    "from": "Einstempeln",
    "to": "Ausstempeln",
    "user": "Benutzer"
  },
  "end": "Ende",
  "export": "CSV-Export",
  "goToAll": "Tagesansicht",
  "headerDaily": "Tagesübersicht",
  "headerMonthly": "Monatsübersicht",
  "headline": {
    "create": "Neuen Stempel eintragen",
    "edit": "Stempelung ändern"
  },
  "holiday": "Feiertag",
  "in": "Kommen",
  "info": {
    "hours": "Stundenübersicht des aktuellen Monats",
    "hoursInfoLive": "Der Wert ist live, da der Stundenzettel für diesen Monat noch nicht eingereicht oder akzeptiert wurde",
    "hoursInfoStatic": "Der Wert ist statisch, da der Stundenzettel für diesen Monat bereits eingereicht und akzeptiert wurde",
    "live": "Live",
    "name": "Name",
    "noVacationDays": "Nicht eingetragen",
    "overtime": "Überstundenkonto",
    "overtimeInfo": "Der Wert kann sich durch eingereichte Stundenzettel ändern und wird niemals statisch",
    "site": "Standort",
    "static": "Statisch",
    "time": "Zeitraum",
    "to": "bis",
    "vacationDays": "Urlaubstage des Jahres",
    "vacationDaysInfo": "Die Urlaubstage beziehen sich immer von Anfang des Jahres an bis Ende des Monats. Genehmigt (Angefragt) / erlaubt Urlaubstage pro Jahr"
  },
  "noEntry": "Kein Eintrag",
  "out": "Gehen",
  "print": "Drucken",
  "save": "Einreichen",
  "schedule": "Schichtplan",
  "showOvertime": "Überstunden anzeigen",
  "showTotal": "Gesamte Arbeitszeit anzeigen",
  "start": "Beginn",
  "submitStatus0": "Ausstehend",
  "submitStatus1": "Angenommen",
  "submitStatus2": "Abgelehnt",
  "submitTooltip": "Nach dem Einreichen, muss ein Admin dieses bestätigen. Nach der Bestätigung können keine Urlaubstage oder andere Fehltage mehr für diesen Monat verbucht werden. Auch der Admin kann keine Stempelzeiten mehr ändern.",
  "submitTooltipFuture": "Ein Stundenzettel kann erst am 2. des Folgemonats eingereicht werden.",
  "tillToday": "Bis Heute",
  "total": "Dauer",
  "user": "Benutzer",
  "userShiftOverview": "Schichtplanverwaltung",
  "vacation": "Urlaubstag",
  "workingTime": "Soll"
}

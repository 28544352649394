{
  "deleteConfirmation": "Ishikawa-Diagramm löschen",
  "environment": "Umfeld",
  "human": "Mensch",
  "maschine": "Maschine",
  "material": "Material",
  "measurement": "Messung",
  "method": "Methode",
  "problem": "Problem",
  "textAreaInvalid": "Mindestens ein Zeichen",
  "textAreaValid ": "Noch {count} Zeichen"
}

{
  "buttons": {
    "saveUploads": "Uploads speichern"
  },
  "form": {
    "inputDescription": {
      "label": "Dokumentbeschreibung",
      "placeholder": "Beschreibung für das Dokument eingeben"
    },
    "inputName": {
      "label": "Titel",
      "nameCheckFail": "Der Name ist bereits vergeben",
      "placeholder": "Titel für das Dokument eingeben",
      "validationText": "noch {value} Zeichen"
    },
    "inputPath": {
      "changeButton": "ändern",
      "label": "Pfad"
    },
    "inputTime": {
      "label": "Erstellt am"
    },
    "inputType": {
      "label": "Dokumenttyp",
      "options": {
        "novideo": "Einfacher Dateidownload ( Dokument kann Videos enthalten )",
        "video": "Erweiterter PDF Download ( Dokument kann keine Videos enthalten )"
      },
      "tooltip": "Kann nicht mehr geändert werden"
    },
    "inputUser": {
      "label": "Erstellt von"
    },
    "upload": {
      "label": "Dateien hinzufügen"
    }
  },
  "modals": {
    "treeModal": {
      "cancelButton": "Abbrechen",
      "saveButton": "Speichern"
    }
  },
  "newDocument": {
    "emptyPath": "Kein Ordner Ausgewählt!"
  }
}

{
  "TCVacationStates": {
    "APPROVED": "Angenommen",
    "DECLINED": "Abgelehnt",
    "PENDING": "Ausstehend"
  },
  "TCVacationTypes": {
    "OTHER": "Anderer",
    "SICK": "Krankheitstag",
    "TIME_ACCOUNT": "Zeitkonto",
    "VACATION": "Urlaubstag"
  },
  "createVacation": "Urlaubsantrag anlegen",
  "createVacationAdmin": "Fehltag eintragen",
  "date": "Datum",
  "dateEnd": "Enddatum",
  "dateStart": "Startdatum",
  "description": "Beschreibung",
  "header": "Urlaubsanträge",
  "headerAdmin": "Urlaubsanträge/Fehltage",
  "status": "Status",
  "submit": "Einreichen",
  "title": "Title",
  "typ": "Typ",
  "type": "Typ",
  "user": "Benutzer"
}

{
  "8Character": "Mindestens 8 Zeichen",
  "Impressum": "Impressum",
  "InformationPrivacy": "Datenschutz",
  "createNewPassword": "Neues Passwort anlegen",
  "createPassword": "Passwort speichern",
  "largeCharacter": "Mindestens ein Großzeichen",
  "missingParameter": "Der angebene Link ist ungültig. Bitte fordern Sie einen neuen an oder überprüfen Sie den aktuellen.",
  "newPassword": "Neues Passwort",
  "oldPassword": "Altes Passwort",
  "passwordForget": "Neuen Rücksetzlink beantragen",
  "passwordNotCompliant": "Das Password entspricht nicht der Passwortrichtlinie.",
  "passwordNotMatch": "Passwörter stimmen nicht überein",
  "passwordSurpassedLifetimeMessage": "Ihr Passwort ist abgelaufen. Bitte setzen Sie ein neues Passwort.",
  "passwortDirective": "Passwortrichtlinie",
  "recoveryCode": "Schlüssel",
  "repeatPassword": "Passwort wiederholen",
  "reusedOldPassword": "Sie haben dieses Passwort schon einmal verwendet.",
  "smallCharacter": "Mindestens ein Kleinzeichen",
  "specialCharacter": "Mindestens ein Sonderzeichen",
  "userName": "Benutzername"
}

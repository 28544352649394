{
  "errors": {
    "noMaterials": "Dieser Lagerplatz enthält keine Materialien",
    "noWarehouseAndStorageLocation": "Bitte Lager und Lagerplatz wählen"
  },
  "fields": {
    "available": "Verfügbar",
    "material": "Material",
    "quantity": "Bestand",
    "reserved": "Reserviert",
    "selected": "Auswahl"
  },
  "form": {
    "quantity": {
      "label": "Anzahl der zu verbrauchenden Materialien"
    },
    "successButton": "Material verbrauchen"
  },
  "storageLocation": "Lagerplatz",
  "title": "Material verbrauchen",
  "toasts": {
    "error": {
      "text": "Dem Lagerplatz \"{storageLocation}\" wurden keine Materialien entnommen. Bitte versuchen Sie es erneut."
    },
    "success": {
      "text": "Dem Lagerplatz '{storageLocation}' wurden erfolgreich {quantity} Materialien ({materialNr}) entnommen."
    }
  },
  "warehouse": "Lager"
}

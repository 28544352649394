import { InjectionKey } from "vue";

export enum InputFrameMode {
  default,
  containerless,
  noFooterPadding,
  modal,
}

export const InputFrameInjectionKey = Symbol("InputFrameInjectionKey") as InjectionKey<{
  disable: boolean;
}>;

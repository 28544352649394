{
  "AttentionToastTile": "Achtung",
  "CheckboxReasonDescription": "Setzen Sie einen Haken, wenn es sich um eine Kernursache handelt!",
  "EditReasonDescription": "Hier können Sie die Ursache änderen, löschen oder 5W-Fragen hinzufügen",
  "delete": "Verwerfen",
  "maxReasonsNotic": "Bitte maximal sieben Ursachen angeben!",
  "possibleReason": "Mögliche Ursache",
  "requiredReasonField": "Bitte füllen Sie das bestehende Feld aus, bevor Sie ein weiteres hinzufügen",
  "save": "Speichern"
}

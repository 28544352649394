import moment, { Moment } from "moment-timezone";
import { computed, ComputedRef, inject, InjectionKey, provide } from "vue";

export default (value: number | Date | Moment, key?: string, tz?: string) => {
  let localValue = value;
  if (value != null && tz != null && moment.tz.zone(tz) != null) {
    const targetTZ = moment(localValue).tz(tz);
    localValue = moment(localValue)
      .add(targetTZ.utcOffset(), "m")
      .subtract(moment(value).utcOffset(), "m");
  }
  if (window.$i18n != null && key != null) {
    return window.$i18n.d(localValue as number | Date, key);
  }
  return "";
};

export const TimeZoneInjectionKey = Symbol("TimeZoneInjectionKey") as InjectionKey<
  ComputedRef<string>
>;

export function useTimeZoneProvide(value: ComputedRef<string | undefined | null>) {
  return provide(
    TimeZoneInjectionKey,
    computed(() => value.value ?? inject(TimeZoneInjectionKey)!.value),
  );
}

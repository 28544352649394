{
  "inputs": {
    "category": {
      "label": "Kategorie",
      "placeholder": "Kategorie auswählen"
    },
    "combinedMinMax": {
      "label": "Wert",
      "placeholder": "Wert"
    },
    "costCenter": {
      "label": "Kostenstelle",
      "placeholder": " Kostenstelle auswählen"
    },
    "datatype": {
      "label": "Datenpunkttyp",
      "placeholder": "Datenpunkttyp auswählen"
    },
    "granularity": {
      "info": "Ein Zielwerte-Chart in LISA kann die Zeitgranularitäten nur in aufsteigender (Tag > Monat > Jahr), nicht jedoch in absteigender, Form berücksichtigen. Nur die Tag/Schicht-Granularität wird ausschließlich in Chart mit dergleichen Einstellung berücksichtigt."
    },
    "max": {
      "label": "maximaler Wert",
      "placeholder": "maximaler Wert"
    },
    "min": {
      "label": "minimaler Wert",
      "placeholder": "minimaler Wert"
    },
    "name": {
      "label": "Bezeichnung",
      "placeholder": "Anzeigename im System"
    },
    "operator": {
      "between": "zwischen",
      "equal": "gleich",
      "greaterEqual": "größer gleich",
      "greaterThen": "größer",
      "label": "Operator",
      "lowerEqual": "kleiner gleich",
      "lowerThen": "kleiner",
      "outOf": "ausserhalb",
      "placeholder": "Operator auswählen"
    },
    "site": {
      "label": "Standort",
      "placeholder": "Standort"
    },
    "timestamp": {
      "info": "Ziele in der Vergangenheit beeinflussen nur den Zeitraum vom gewählten Datum bis zum nächsten gültigen Ziel",
      "label": "Gültig ab"
    }
  },
  "titles": {
    "editMode": "Ziel {name} bearbeiten",
    "newMode": "Neues Ziel anlegen"
  }
}

{
  "actions": {
    "BLINK": "Blinken",
    "IGNORE": "Ignorieren",
    "INVALID": "Unbekannt",
    "OFF": "Aus",
    "ON": "AN"
  },
  "deleteAllRules": "Wollen Sie wirklich alle Regeln löschen?",
  "fallbackRule": "Fallback Datenpunkttyp",
  "fallbackRuleTooltip": "Alle Events die keine passende Regel haben werden hier als Datenpunkt gepeichert.",
  "header": "Regelliste für {name}",
  "input": "Eingang {number}",
  "quantity": "Anzahl",
  "rule": "Regel"
}

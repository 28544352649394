<template>
  <SidebarMenu
    :relative="true"
    :menu="menu"
    :showChild="collapsed"
    :collapsed="true"
    :showOneChild="true"
    :theme="theme"
    widthCollapsed="43px"
    style="z-index: 16"
    @toggle-collapse="switchCollapse"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { SidebarMenu } from "vue-sidebar-menu";
import { useStore } from "vuex";

export default defineComponent({
  name: "MenuMaster",
  components: {
    SidebarMenu,
  },
  model: {
    prop: "isCollapsed",
    event: "change",
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const collapsed = ref(true);
    const activeMainEntry = ref(null);
    const activeSubEntry = ref(null);
    const activeSubSubEntry = ref(null);
    const forceHide = ref(false);
    const menuOpen = ref(true);

    const store = useStore();
    const { t } = useI18n();

    const theme = computed((): undefined | string => {
      return store.getters["login/showDarkTheme"] ? undefined : "white-theme";
    });

    const menu = computed((): any => {
      return (store.state.login.user?.menu ?? []).map((main: any) => {
        return {
          ...main,
          title: t(`sidebar.${main.title}.title`),
          href: main.href ?? `/redirect/${main.title}`,
          child: main.child?.map((level1: any) => {
            return {
              ...level1,
              title: t(`sidebar.${main.title}.children.${level1.title}.title`),
              child: level1.child?.map((level2: any) => {
                return {
                  ...level2,
                  title: t(
                    `sidebar.${main.title}.children.${level1.title}.children.${level2.title}.title`,
                  ),
                };
              }),
            };
          }),
        };
      });
    });

    function switchCollapse(collapsedProp: boolean) {
      collapsed.value = collapsedProp;
    }

    return {
      collapsed,
      activeMainEntry,
      activeSubEntry,
      activeSubSubEntry,
      forceHide,
      menuOpen,
      theme,
      menu,
      switchCollapse,
    };
  },
});
</script>

<style lang="scss">
.sidebar {
  height: 100%;
}

.v-sidebar-menu.vsm_white-theme {
  background: #fafafa !important;
  border-color: #e9ecef;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  border-right-width: 0.5px;
}

.vsm_collapsed,
.vsm_expanded {
  .vsm--item {
    .vsm--dropdown {
      .vsm--item {
        .vsm--link_level-2 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          border-bottom: 1px solid lightgrey;

          .vsm--title {
            font-size: 12px;
          }
        }

        .vsm--dropdown {
          padding-top: 0 !important;

          .vsm--link_level-3 {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .vsm--title {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .vsm--link_level-2 {
    .vsm--arrow_open {
      display: none;
    }
  }
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  color: #707070 !important;
  height: 30px;
  width: 30px;
}

.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  color: white !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open.vsm--link_level-1 {
  color: white !important;
}

.v-sidebar-menu .vsm--link {
  font-size: 14px !important;
  padding: 6px !important;
}

.v-sidebar-menu .vsm--link.vsm--link_level-3 {
  padding-left: 25px !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--icon {
  font-size: 24px !important;
  background: transparent !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon,
.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link_level-1:hover .vsm--icon {
  color: white !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  box-shadow: 3px 0 0 0 var(--primary-color) inset !important;
}

.vsm--link {
  transition: none !important;
}

.slide-animation-enter-active,
.slide-animation-leave-active,
.expand-enter-active,
.expand-leave-active,
.fade-animation-enter-active,
.fade-animation-leave-active {
  transition: none !important;
}
</style>

{
  "createIssue": "Problem anlegen",
  "futureTarget": "Zielwert liegt in der Zukunft",
  "noShiftTitle": "Nichts",
  "quantity": "Anzahl",
  "shiftEarly": "Frühschicht",
  "shiftLate": "Spätschicht",
  "shiftNight": "Nachtschicht",
  "targetNotReached": "Zielwert nicht erreicht",
  "targetReached": "Ziel",
  "title": "Feedbackfragen vom",
  "pageHeader": "Seite {count}",
  "noShiftColumn": "Tageswert",
  "withoutShiftHeader": "(ohne Schichtbezug)"
}

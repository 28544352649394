{
  "forms": {
    "decimalPlaces": {
      "label": "Dezimalstellen",
      "options": {
        "1": "##.#0",
        "2": "##.##0",
        "3": "##.##0",
        "automatic": "Automatisch",
        "none": "Keine"
      }
    },
    "maximum": {
      "label": "Maximaler Wert",
      "placeholder": "Maximaler Wert",
      "reset": "Reset"
    },
    "meshColor": {
      "button": "Automatisch",
      "inputLabel": "Farbe:",
      "label": "Gitter Farbe"
    },
    "minimum": {
      "label": "Minimaler Wert",
      "placeholder": "Minimaler Wert",
      "reset": "Reset"
    },
    "name": {
      "label": "Bezeichnung",
      "placeholder": "Bezeichnung"
    },
    "scaleType": "Logarithmische Skala",
    "suffix": {
      "label": "Suffix",
      "placeholder": "Suffix [%]"
    },
    "tickInterval": {
      "label": "Scalenschritte",
      "placeholder": "Automatisch",
      "reset": "Reset"
    }
  }
}

{
  "Reporting": {
    "tableFields": {
      "createdFlags": "neue Ereignisse ({name})"
    }
  },
  "contextButtonTitle": "Diagramm-Kontextmenü",
  "downloadCSV": "Download CSV",
  "downloadJPEG": "Download JPEG Bild",
  "downloadPDF": "Download PDF",
  "downloadPNG": "Download PNG Bild",
  "downloadSVG": "Download SVG Vektorbild",
  "drillUpText": "◁ Zurück zu {'{{'}series.name{'}}'}",
  "exitFullscreen": "Vollbild beenden",
  "months": "[\"Januar\", \"Februrar\", \"März\", \"April\", \"Mai\", \"Juni\", \"Juli\", \"August\", \"September\", \"Oktober\", \"November\", \"Dezember\"]",
  "noData": "Keine Daten zum Anzeigen vorhanden",
  "printChart": "Diagramm drucken",
  "resetZoom": "Zoom zurücksetzen",
  "resetZoomTitle": "Zoom auf Level 1:1 zurücksetzen",
  "shortMonths": "[\"Jan\", \"Feb\", \"März\", \"Apr\", \"Mai\", \"Jun\", \"Jul\", \"Aug\", \"Sept\", \"Okt\", \"Nov\", \"Dez\"]",
  "shortWeekdays": "[\"So\", \"Mo\", \"Di\", \"Mi\", \"Do\", \"Fr\", \"Sa\"]",
  "weekdays": "[\"Sonntag\", \"Montag\", \"Dienstag\", \"Mittwoch\", \"Donnerstag\", \"Freitag\", \"Samstag\"]"
}

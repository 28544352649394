import { ComputedRef, InjectionKey, Ref } from "vue";
import type {
  EventListEventFieldsFragment,
  ArchiveRange,
  IFlagConnection,
  GetEABShorthandsForBoardFragmentFragment,
  EventType,
  EventListEventBoardFieldsOnlyBoardFragment,
  BoardIHConfigFragment,
  SQDCTileEventTypes,
  GetWorkflowsForBoardFragmentFragment,
  IdeaConfigOnEABFragment,
} from "@/graphql";
import isModifierSet, { ModifierType } from "@/functions/userModifier";
import EventActionButtonListRef from "@/components/event-action/event/action-buttons/EventActionButtonList.vue";

export const TechnicalPlaceAndCategoryInjectionKey = Symbol(
  "TechnicalPlaceAndCategoryInjectionKey",
) as InjectionKey<
  ComputedRef<
    | undefined
    | {
        technicalPlaceId?: number;
        technicalCategoryId?: number;
      }
  >
>;

export const EventsInjectionKey = Symbol("EventsInjectionKey") as InjectionKey<
  Readonly<Ref<Readonly<EventListEventFieldsFragment[]>>>
>;

export const FilteredEventsInjectionKey = Symbol("FilteredEventsInjectionKey") as InjectionKey<
  ComputedRef<{
    activeEvents: EventListEventFieldsFragment[];
    numberOf: {
      events: number;
      actions: number;
    };
  }>
>;

export const BoardIdInjectionKey = Symbol("BoardIdInjectionKey") as InjectionKey<
  Ref<number | null>
>;

export const AddEventButtonVisibleInjectionKey = Symbol(
  "AddEventButtonVisibleInjectionKey",
) as InjectionKey<Ref<boolean>>;

export const BoardInjectionKey = Symbol("BoardInjectionKey") as InjectionKey<
  ComputedRef<null | EventListEventBoardFieldsOnlyBoardFragment>
>;
export const IHConfigInjectionKey = Symbol("IHConfigInjectionKey") as InjectionKey<
  ComputedRef<undefined | BoardIHConfigFragment>
>;

export const TechnicalPlaceForEventModalInjectionKey = Symbol(
  "TechnicalPlaceForEventModalInjectionKey",
) as InjectionKey<ComputedRef<{ id: number; parentId: number }>>;

export const ShowAlwaysBoardOriginInjectionKey = Symbol(
  "ShowAlwaysBoardOriginInjectionKey",
) as InjectionKey<Ref<boolean>>;

export const SiteInjectionKey = Symbol("SiteInjectionKey") as InjectionKey<
  Readonly<
    Ref<
      Readonly<{
        __typename?: "Site" | undefined;
        id: number;
        name: string;
        tz: string;
      } | null>
    >
  >
>;

export const CommentUserIdInjectionKey = Symbol("CommentUserIdInjectionKey") as InjectionKey<
  Ref<number | undefined>
>;

export const CommonTaskAdditionalComment = Symbol("CommonTaskAdditionalComment") as InjectionKey<
  Ref<string | null>
>;

export const EventActionButtonListKey = Symbol("EventActionButtonListKey") as InjectionKey<
  Ref<InstanceType<typeof EventActionButtonListRef> | null>
>;

export const ArchiveRangeKey = Symbol("ArchiveRangeKey") as InjectionKey<
  Ref<ArchiveRange | undefined> | undefined
>;

export const CustomFilterActive = Symbol("CustomFilterActive") as InjectionKey<Ref<boolean>>;

export const DefaultFlagInjectionKey = Symbol("DefaultFlagInjectionKey") as InjectionKey<
  Ref<IFlagConnection[]>
>;

export const DefaultDateInjectionKey = Symbol("DefaultDateInjectionKey") as InjectionKey<
  ComputedRef<{ isoString: string; editable: boolean }>
>;

export const ReloadBoardInjectionKey = Symbol("ReloadBoardInjectionKey") as InjectionKey<
  () => void
>;

export const TaskModalCommentIsRequiredInjectionKey = Symbol(
  "TaskModalCommentIsRequiredInjectionKey",
) as InjectionKey<Ref<boolean>>;

export const EABShorthandInjectionKey = Symbol("EABShorthandInjectionKey") as InjectionKey<
  Readonly<Ref<Readonly<GetEABShorthandsForBoardFragmentFragment[]>>>
>;

export const EABWorkflowsInjectionKey = Symbol("EABWorkflowsInjectionKey") as InjectionKey<
  Readonly<Ref<Readonly<GetWorkflowsForBoardFragmentFragment[]>>>
>;

export enum RecentlyChangedAlternativeEnum {
  NORMAL,
  GREEN_WAVES,
  ID_HIGHLIGHT,
}

export function getRecentlyChangedAlternative(): RecentlyChangedAlternativeEnum {
  if (isModifierSet(ModifierType.idHighlightWaves)) {
    return RecentlyChangedAlternativeEnum.GREEN_WAVES;
  }
  if (isModifierSet(ModifierType.idHighlightBadge)) {
    return RecentlyChangedAlternativeEnum.ID_HIGHLIGHT;
  }
  return RecentlyChangedAlternativeEnum.NORMAL;
}

export const EventTypesInjectionKey = Symbol("EventTypesInjectionKey") as InjectionKey<
  Ref<EventType[]>
>;

export const SQDCTileEventTypesInjectionKey = Symbol(
  "SQDCTileEventTypesInjectionKey",
) as InjectionKey<Ref<SQDCTileEventTypes[]>>;

export const BoardIsLoadingInjectionKey = Symbol("BoardIsLoadingInjectionKey") as InjectionKey<
  Ref<boolean>
>;

export const IdeaConfigInjectionKey = Symbol("IdeaConfigInjectionKey") as InjectionKey<
  ComputedRef<IdeaConfigOnEABFragment | undefined>
>;

{
  "MMaterialImage": {
    "noImage": "Dieses Material hat noch kein Bild",
    "uploadLabel": "Bild ändern"
  },
  "buyerId": {
    "label": "Käufer"
  },
  "commodityCodeId": {
    "label": "Warengruppe"
  },
  "descriptionId": {
    "label": "Beschreibung"
  },
  "header": "Material",
  "height": {
    "label": "Höhe"
  },
  "inventoryEditor": {
    "availableForSite": {
      "label": "Material in diesem Standort verfügbar machen"
    },
    "reportingStock": {
      "label": "Meldebestand"
    },
    "safetyStock": {
      "label": "Sicherheitsbestand"
    },
    "site": {
      "label": "Standort"
    }
  },
  "leadTime": {
    "DAY": "Tage",
    "MONTH": "Monate",
    "WEEK": "Wochen",
    "YEAR": "Jahre",
    "label": "Vorlaufzeit"
  },
  "length": {
    "label": "Länge"
  },
  "lotSize": {
    "label": "Losgröße"
  },
  "materialDataLabel": "Materialdaten",
  "materialNr": {
    "label": "Materialnummer"
  },
  "moq": {
    "label": "Mindestbestellmenge"
  },
  "multiple": "Vielfaches",
  "price": {
    "label": "Preis"
  },
  "props": {
    "commodityCodeId": {
      "description": "Warengruppe zur weiteren Kategorisierung des Materials"
    },
    "descriptionId": {
      "description": "Beschreibung des Materials"
    },
    "materialNr": {
      "description": "Einzigartige Nummer zur einfachen identifizierung."
    },
    "uomId": {
      "description": "Einheit in der die Quantität des Materials angegeben wird"
    }
  },
  "purchaseTypeId": {
    "label": "Typ"
  },
  "storageLocationId": {
    "label": "Lagerplatz"
  },
  "tabs": {
    "attributes": "Attribute",
    "image": "Bild",
    "inventory": "Inventar",
    "purchasing": "Beschaffung"
  },
  "tooltips": {
    "lotSize": {
      "text": "Interne Transportmenge",
      "title": "Beschreibung"
    },
    "moq": {
      "text": "Bestellmenge beim Lieferanten"
    }
  },
  "uomId": {
    "label": "Einheit"
  },
  "vendorId": {
    "label": "Lieferant"
  },
  "weight": {
    "label": "Gewicht"
  },
  "width": {
    "label": "Breite"
  }
}

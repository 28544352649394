<template>
  <FilterBadge v-bind="{ ...$props, ...$attrs }"> {{ text }} </FilterBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import IDFilter from "../../filterImplementations/IDFilter";
import FilterBadge from "./FilterBadge.vue";

export default defineComponent({
  name: "IDFilterBadge",
  components: {
    FilterBadge,
  },
  props: {
    filter: {
      type: Object as PropType<IDFilter>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const text = computed(() => {
      const typeText = props.filter.type
        ? t(`global.EABTypes.${props.filter.type}`)
        : t(`global.all`);
      return `${typeText}${props.filter.ids.length > 0 ? " | " : ""}${props.filter.ids.join(", ")}`;
    });
    return { text };
  },
});
</script>

<style lang="scss" scoped></style>

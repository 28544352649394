{
  "forms": {
    "inputName": {
      "label": "Anzeigename",
      "placeholder": "Namen eingeben"
    },
    "inputSelect": {
      "label": "Verknüpfung",
      "options": {
        "sum": "Summe",
        "product": "Produkt"
      }
    },
    "inputColor": {
      "label": "Farbe:"
    }
  }
}

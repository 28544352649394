{
  "BoardDisabled": "Kein Zugriff erlaubt.",
  "confirmationModal": {
    "cancelText": "Abbrechen",
    "okText": "Verwerfen",
    "text": "Sie haben Änderungen vorgenommen, wollen Sie diese ohne zu Speichern verwerfen?",
    "title": "Bitte bestätigen"
  },
  "createBoard": "Board anlegen",
  "header": "Navigation",
  "monitorView": "Monitor-View umschalten",
  "organization": "Organisation",
  "site": "Standort"
}

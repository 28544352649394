export default {
  endpoints: {
    getAll: {
      route: "overview",
      method: "POST",
    },
    get: {
      route: ":id",
      method: "GET",
    },
    getAllPSCTypes: {
      route: "types/get",
      method: "GET",
    },
    updateSketch: {
      route: "sketch",
      method: "PUT",
    },
    create: {
      route: "",
      method: "POST",
    },
    update: {
      route: "",
      method: "PUT",
    },
    getTasksForUser: {
      route: "tasksForUser",
      method: "GET",
    },
  },
};

<template>
  <div>
    <ValidationProvider
      v-slot="{ valid }"
      :modelValue="dateRange[0]"
      :rules="{ required: dateRange[1] == null }"
      immediate
    >
      <DatePickerComponent
        v-model="dateRange[0]"
        :type="DatePickerType.DAY"
        :state="valid"
        :tz="tz"
        :placeholder="$t('components.event-action.filter.ui.DateFilterUI.from').toString()"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ valid }"
      :modelValue="dateRange[1]"
      :rules="{ required: dateRange[0] == null }"
      immediate
    >
      <DatePickerComponent
        v-model="dateRange[1]"
        :type="DatePickerType.DAY"
        class="mt-1"
        :state="valid"
        :tz="tz"
        :placeholder="$t('components.event-action.filter.ui.DateFilterUI.until').toString()"
      />
    </ValidationProvider>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, ref } from "vue";
import { DateTime } from "luxon";
import DatePickerComponent from "@/components/shared/DatePickerComponent.vue";
import DateFilter from "../filterImplementations/DateFilter";
import { BoardInjectionKey } from "@/views/event-action/EventActionBoardInjectionKeys";
import ValidationProvider from "@/modules/veevalidate/components/ValidationProvider.vue";
import { DatePickerType } from "@/components/shared/DatePickerComponentHelper";

export default defineComponent({
  name: "DateFilterUI",
  components: {
    DatePickerComponent,
    ValidationProvider,
  },
  setup(props) {
    const dateRange = ref<[Date | null, Date | null]>([null, null]);

    const board = inject(
      BoardInjectionKey,
      computed(() => null),
    );

    function getFilter() {
      if (dateRange.value.some((date) => date != null)) {
        return new DateFilter(
          dateRange.value.map((date, index) => {
            if (date == null) {
              return null;
            }
            if (index === 0) {
              return DateTime.fromJSDate(date);
            }
            if (index === 1) {
              let dateTime = DateTime.fromJSDate(date);
              if (board?.value != null) {
                dateTime = dateTime.setZone(board.value.site?.tz);
              }
              return dateTime.endOf("day");
            }
            throw Error("unexpected number of dates in date filter");
          }) as [null | DateTime, null | DateTime],
        );
      }
      return null;
    }

    function applyFilter(filter: DateFilter) {
      dateRange.value = filter.date.map((d) => d?.toJSDate() ?? null) as [Date | null, Date | null];
      if (filter.date[0]?.toMillis() === 0) {
        dateRange.value[0] = new Date();
      }
    }

    return {
      dateRange,
      DatePickerType,
      tz: computed(() => board?.value?.site?.tz ?? DateTime.local().zoneName),
      getFilter,
      applyFilter,
    };
  },
});
</script>

<style lang="scss" scoped></style>

{
  "avatar": "Profilbild (optional)",
  "changeDisplayName": "Anzeigename anpassen",
  "clearAvatar": "Profilbild zurücksetzen",
  "displayName": "Anzeigename",
  "displayNameInfo": "Ein Benutzer kann einen angepassten Anzeigenamen besitzen. Falls dieser nicht angegeben wird, so setzt sich der Anzeigename des Benutzer aus Vor- und Nachnamen zusammen.",
  "email": "E-Mail-Adresse",
  "firstName": "Vorname",
  "language": "Systemsprache",
  "lastName": "Nachname",
  "noright": "Sie sind nicht berechtigt, die persöhnlichen Informationen des Benutzers zu bearbeiten.",
  "telefon": "Telefonnummer (optional)",
  "username": "Benutzername",
  "inputCheckYes": "Ja",
  "inputCheckNo": "Nein",
  "inputDisabledTrue": "Gesperrt",
  "inputDisabledFalse": "Zugelassen",
  "userActive": "Benutzer sperren"
}

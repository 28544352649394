{
  "buttons": {
    "createTarget": "Zielwert anlegen"
  },
  "forms": {
    "deviation": {
      "label": "Abweichung in % (+/-):"
    },
    "inputCategory": {
      "label": "Kategorie auswählen",
      "placeholder": "Kategorie auswählen"
    },
    "inputLayout": {
      "label": "Layout auswählen",
      "placeholder": "Layout auswählen"
    },
    "target": {
      "label1": "Zielwert",
      "label2": "Zielwert Parameter"
    }
  },
  "table": {
    "custom": {
      "id": {
        "dataType": "Ist-Wert",
        "target": "Vorgabewert"
      },
      "validity": {
        "since": "Seit {date}"
      }
    },
    "fields": {
      "category": "Kategorie",
      "id": "Bezeichnung",
      "name": "Datentyp",
      "pointName": "Anzeigename"
    }
  },
  "texts": {
    "noTarget": "Kein Zielwert"
  },
  "types": {
    "dynamic": "Dynamisches Chart",
    "pps": "Target PPS"
  }
}

{
  "Postcode": "Postleitzahl",
  "city": "Stadt",
  "color": "Standortfarbe",
  "colorUndefined": "Farbe auswählen",
  "colorUsed": "Die Farbe wird bereits verwendet",
  "combinationExists": "Die Kombination aus Name, Stadt, Land und Farbe existiert bereits!",
  "confirm": {
    "cancelTitle": "Abbrechen",
    "okTitle": "Löschen",
    "question": "Soll der Standort '{name}' wirklich gelöscht werden? Es werden auch alle zugehörigen Boards, PSCs und Kacheln gelöscht.",
    "title": "Bitte bestätigen"
  },
  "costCenter": "Kostenstelle",
  "country": "Land",
  "createSite": "Neuen Standort anlegen",
  "deleteError": "Dieser Standort kann nicht gelöscht werden, da noch Benutzer oder Abteilungen verbunden sind.",
  "deleteSite": "Standort löschen",
  "ip": "IP Adressbereich",
  "ipRestriction": "IP Adressbereich des Standorts. Benutzer mit der Einschränkung, dass sie nur lokal auf das Lisa System zugreifen können müssen eine IP Adresse aus folgendem Bereich aufweisen. Die Angabe des IP Adressbereichs ist in CIDR Notation.",
  "name": "Name",
  "showDepartment": "Abteilungen ansehen",
  "site": "Standort",
  "siteContainment": "An diesem Standort sind {user} Benutzer und {department} Abteilungen angemeldet.",
  "street": "Straße",
  "technicalPlace": "Technischer Platz",
  "timeZoneLabel": "Zeitzone",
  "toast": {
    "createError": {
      "text": "Der Standort wurde nicht erstellt! Bitte prüfen Sie ihre Eingaben. Falls der Fehler weiterhin autritt, wenden Sie sich an einen Administrator.",
      "title": "Fehler"
    },
    "createSuccess": {
      "text": "Der Standort wurde erstellt!",
      "title": "Erfolgreich"
    },
    "deleteError": {
      "text": "Der Standort wurde nicht gelöscht! Falls der Fehler weiterhin autritt, wenden Sie sich an einen Administrator.",
      "title": "Fehler"
    },
    "deleteSuccess": {
      "text": "Der Standort wurde gelöscht!",
      "title": "Erfolgreich"
    },
    "updateError": {
      "text": "Der Standort wurde nicht gespeichert! Falls der Fehler weiterhin autritt, wenden Sie sich an einen Administrator.",
      "title": "Fehler"
    },
    "updateSuccess": {
      "text": "Der Standort wurde gespeichert!",
      "title": "Erfolgreich"
    }
  }
}
